import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Switch } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// components
import Button from "../../../components/buttons/buttons";
import RestaurantOrders from "./RestaurantOrders";
import AllFoods from "./AllFoods";
// requests
import { getARestaurantAction } from "../../../redux/restaurant/restaurantActions";
import { getAUserAction } from "../../../redux/users/userActions";
import {
  approveRestaurantByMgtAction,
  unapproveRestaurantByMgtAction,
} from "../../../redux/restaurant/restaurantActions";
import {
  approveRestaurantBySelfAction,
  unapproveRestaurantBySelfAction,
} from "../../../redux/restaurant/restaurantActions";
// import { filterRestaurantOrderAction } from "../../../redux/restaurantOrder/restaurantOrderActions";
import filterRestaurantType from "../../../redux/restaurantOrder/restaurantOrderTypes";
import { getRestaurantStatistics } from "../../../redux/statistics/statisticsAction";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SingleRestaurant() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);
  let lastFourMonth = new Date();
  lastFourMonth.setDate(lastFourMonth.getDate() - 120);

  const [enabledApprove, setEnabledApprove] = useState();
  const [enabledPublish, setEnabledPublish] = useState();
  const [active_tab, setActiveTab] = useState("orders");

  const { restaurant } = useSelector((state) => state.restaurant);

  const { singleUser } = useSelector((state) => state.user);
  const { restaurantStats } = useSelector((state) => state.statistics);

  const [payLoad] = useState({
    end_date: new Date(),
    start_date: lastFourMonth,
    restaurant_id: restaurant?.id,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getARestaurantAction(id));
    dispatch({
      type: filterRestaurantType["FILTER_RESTAURANT_ORDERS"],
      payLoad: { data: {} },
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (restaurant?.id) {
      setEnabledApprove(restaurant?.management_approved);
      setEnabledPublish(restaurant?.business_approved);
      dispatch(getAUserAction(restaurant?.user_id));
      dispatch(
        getRestaurantStatistics({ ...payLoad, restaurant_id: restaurant?.id })
      );
    }
    // eslint-disable-next-line
  }, [restaurant?.id]);

  const approveByRestaurant = () => {
    dispatch(approveRestaurantBySelfAction({ id: restaurant?.id }));
  };
  const unapproveByRestaurant = () => {
    dispatch(unapproveRestaurantBySelfAction({ id: restaurant?.id }));
  };

  const handleApprove = () => {
    dispatch(approveRestaurantByMgtAction({ id: restaurant?.id }));
  };
  const handleUnapprove = () => {
    dispatch(unapproveRestaurantByMgtAction({ id: restaurant?.id }));
  };

  const toggle = (tab) => {
    if (active_tab !== tab?.href) {
      setActiveTab(tab?.href);
    }
  };

  return (
    <div data-test="singleRestaurant-page">
      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between gap-6 items-center">
          <h3 className="text-lg font-medium">{restaurant?.restaurant_name}</h3>
          <Button.Primary
            type={"button"}
            title={isMobile() ? "Edit" : "Edit Restaurant"}
            onClick={() =>
              navigate(`/admin/restaurants/edit/${restaurant?.id}`)
            }
          />
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Restaurant ID</label>
            <p className="text-[#151515] dark:text-white text-sm">{restaurant?.id}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Email</label>
            <p className="text-[#151515] dark:text-white text-sm break-words">
              {restaurant?.restaurant_email}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Phone Number</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {restaurant?.restaurant_phone}
            </p>
          </div>
          {/* Publish/Unpublish switch */}
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              {restaurant?.business_approved ? "Unpublish" : "Publish"}
            </label>
            <Switch.Group as="div" className="flex">
              <Switch
                checked={enabledPublish}
                onChange={(e) => {
                  setEnabledPublish(e);
                  restaurant?.business_approved
                    ? unapproveByRestaurant()
                    : approveByRestaurant();
                }}
                className={classNames(
                  enabledPublish ? "bg-primary" : "bg-gray-200",
                  "relative inline-flex mt-1 h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabledPublish ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white dark:bg-[#121212] shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
          {/* Approve/unapprove switch */}
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              {restaurant?.management_approved ? "Unapprove" : "Approve"}
            </label>
            <Switch.Group as="div" className="flex">
              <Switch
                checked={enabledApprove}
                onChange={(e) => {
                  setEnabledApprove(e);
                  restaurant?.management_approved
                    ? handleUnapprove()
                    : handleApprove();
                }}
                className={classNames(
                  enabledApprove ? "bg-primary" : "bg-gray-200",
                  "relative inline-flex mt-1 h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabledApprove ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white dark:bg-[#121212] shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Created On</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {moment(restaurant?.created_at).format("DD, MMM YYYY, HH:MM A")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Status</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {restaurant?.management_approved ? "Approved" : "Unapproved"}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Country</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {restaurant?.restaurant_country}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">State</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {restaurant?.restaurant_state}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">City</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {restaurant?.restaurant_city}
            </p>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Restaurant Owner Details</h3>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Name</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {singleUser?.profile?.user_name}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Email</label>
            <p className="text-[#151515] dark:text-white text-sm break-words">
              {singleUser?.profile?.user_email}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Phone Number</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {singleUser?.profile?.phone ?? "Not available"}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Address</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {singleUser?.profile?.address ?? "Not available"}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Approve/Unapprove
            </label>
            <p className="text-[#151515] dark:text-white text-sm">{singleUser?.name}</p>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Orders History</h3>
          <p>
            Period: <span className="text-primary">*Four (4) Months Ago*</span>
          </p>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-6 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Total Orders </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {restaurantStats?.stats?.orders?.total_orders}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Delivered Orders
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {restaurantStats?.stats?.orders?.delivered_orders}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Shipped Orders
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {restaurantStats?.stats?.orders?.shipped_orders}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Unshipped Orders
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {restaurantStats?.stats?.orders?.unshipped_orders}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Closed Orders</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {restaurantStats?.stats?.orders?.closed_orders}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Orders Paid</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {restaurantStats?.stats?.orders?.order_paid}
            </p>
          </div>
        </div>
      </div>

      {/* Restaurant Orders and Foods tab section */}
      <div className="border-b border-gray-200 mt-5">
        <nav
          className="-mb-px flex space-x-8 overflow-x-auto"
          aria-label="Tabs"
        >
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => {
                toggle(tab);
              }}
              className={classNames(
                tab.href === active_tab
                  ? "border-primary text-primary font-bold"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={tab.href === active_tab ? "page" : undefined}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
      {active_tab === "orders" && <RestaurantOrders />}
      {active_tab === "foods" && <AllFoods />}
    </div>
  );
}

const tabs = [
  { name: "Orders", href: "orders" },
  { name: "Foods", href: "foods" },
];

import axios from "../plugins/axios.plugin";

class PushNotificationService {
  baseServicePath = "/api/push-notification-token";

  createPushNotification(payLoad) {
    return axios.post(`${this.baseServicePath}/store`, payLoad);
  }
}

export const pushNotificationService = new PushNotificationService();

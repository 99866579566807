import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import AllFeaturedProducts from "./all";
import ActiveFeaturedProducts from "./active";
import InactiveFeaturedProducts from "./inactive";
import CompletedFeaturedProducts from "./completed";

const tabs = [
  // { name: "All", href: "all" },
  { name: "Active", href: "active" },
  { name: "Inactive", href: "inactive" },
  { name: "Completed", href: "completed" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Feature() {
  const navigate = useNavigate();

  const DEFAULT_ACTIVE_TAB = "active";
  const { active_tab } = useParams();

  useEffect(() => {
    if (!active_tab) {
      navigate(`/admin/feature/${DEFAULT_ACTIVE_TAB}/1`);
    }
  });

  const toggle = (tab) => {
    if (active_tab !== tab) {
      navigate(`/admin/feature/${tab.href}/1`);
    }
  };

  return (
    <div className="business-page">
      {/* <div className="mb-10">
        <h1 className="text-3xl font-semibold text-gray-900">Featured Items</h1>
      </div> */}
      <div className="sm:block">
        <div className="border-b border-gray-200 dark:border-none">
          <nav
            className="-mb-px flex space-x-8 overflow-x-scroll sm:overflow-hidden"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => {
                  toggle(tab);
                }}
                className={classNames(
                  tab.href === active_tab
                    ? "border-primary text-primary font-bold"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab.href === active_tab ? "page" : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div>
        {/* {active_tab === "all" && (
          <AllFeaturedProducts
            input={input}
            featured={featured}
            setFeatured={setFeatured}
          />
        )} */}
        {active_tab === "active" && <ActiveFeaturedProducts />}
        {active_tab === "inactive" && <InactiveFeaturedProducts />}
        {active_tab === "completed" && <CompletedFeaturedProducts />}
      </div>
    </div>
  );
}

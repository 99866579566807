import React, { useMemo, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/buttons/buttons";
import Table from "../../../components/tables/tableCols1";
import TableSkeleton from "../../../components/skeleton/table";
import TableFilter from "../../../components/tables/tableFilter";
import Empty from "../../../assets/images/emptyState/promo-code.svg";
import { Pagination } from "../../../components/pagination/pagination";
import {
  filterPromoCodes,
  searchPromoCodes,
} from "../../../redux/promo/promoAction";
import promoType from "../../../redux/promo/promoTypes";
import { writeFileWithXLSX } from "../../../components/exports/xlsx";
import FilterPromoDropDown from "../../../components/dropDown/filter/promoFilter";
import { AllFiltersModal } from "../../../components/modals/allFiltersModal";

export default function Souvenir() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page } = useParams();
  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);

  const { allPromoCodes, filtering, searching } = useSelector(
    (state) => state.promo
  );
  const [isFilterOpen, setFilterState] = useState(false);
  const [payLoad] = useState({
    type: "souvenir",
    end_date: new Date(),
  });

  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });

  useEffect(() => {
    dispatch(filterPromoCodes(payLoad, page));
    // eslint-disable-next-line
  }, [page]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((coupon, index) => {
        let show = {
          id: index + 1,
          slug: coupon?.slug,
          title: coupon?.title,
          discount: coupon?.amount,
          created: (
            <span>
              {moment(coupon?.created_at).format("MMM DD, YYYY/ HH:MMA")}
            </span>
          ),
          expires: <span>{moment(coupon?.expiry).format("MMM DD, YYYY")}</span>,
          coupon: coupon,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allPromoCodes?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPromoCodes]);

  function createTableNoPage(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((coupon, index) => {
        let show = {
          slug: coupon?.slug,
          title: coupon?.title,
          discount: coupon?.amount,
          created: coupon?.created_at,
          expires: coupon?.expiry,
        };
        outputArray.push(show);
        return true;
      });
      return outputArray;
    }
  }

  function goToSinglePage(params) {
    let coupon_id = params?.show?.coupon?.id;
    navigate(`/admin/promo/${coupon_id}`, {
      state: { coupon: params?.show?.coupon },
    });
  }

  function createCoupon() {
    navigate(`/admin/create-promo`);
  }

  const downloadCSV = async () => {
    const response = await dispatch(
      filterPromoCodes({
        ...payLoad,
        pagination: 0,
      })
    );
    if (response) {
      const newData = createTableNoPage(response?.data);
      writeFileWithXLSX(newData, {
        filename: "Souvenir_promo_codes.xlsx",
        download: true,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allPromoCodes?.data);
      setData(dataSet);
      dispatch({
        type: promoType["SEARCH_PROMO"],
        payLoad: { data: [] },
      });
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchPromoCodes(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        columns: [
          {
            Header: "S/N",
            accessor: "show.id",
          },
          {
            Header: "Title",
            accessor: "show.title",
          },
          {
            Header: "Promo Code",
            accessor: "show.slug",
          },
          {
            Header: "Discount",
            accessor: "show.discount",
          },
          {
            Header: "Start Date",
            accessor: "show.created",
          },
          {
            Header: "Expires",
            accessor: "show.expires",
          },
        ],
      },
    ],
    []
  );

  return (
    <div data-test="all-promo">
      <TableFilter
        title={"Promo Code"}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        results={allPromoCodes?.to}
        totalResults={allPromoCodes?.total}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterPromoDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
        searchPlaceholder={"Title, Cap & Status"}
      >
        <Button.Primary title={"+ Create Promo"} onClick={createCoupon} />
        <Button.Secondary
          title={"Download CSV"}
          onClick={() => downloadCSV()}
          disabled={!data}
        />
      </TableFilter>
      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white dark:bg-[#121212] rounded-md py-4 px-3">
        {filtering || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        ) : (
          <div className="text-center">
            {" "}
            <div className="flex justify-center my-12 ">
              <img src={Empty} alt="coupon" />
            </div>
            <p className="font-bold text-xl my-3">Promo Code</p>
            <p className="text-sm text-gray-500">
              You have not created any promo yet.
            </p>
          </div>
        )}

        <Pagination data={allPromoCodes} route={`admin/promo/all`} />
      </div>
    </div>
  );
}

import React, { useMemo, useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../../../components/cards/admin/cards";
import Table from "../../../../components/tables/tableCols1";
import TableSkeleton from "../../../../components/skeleton/table";
import Button from "../../../../components/buttons/buttons";
import { Beat } from "../../../../plugins/spinners.plugin";
import { Pagination } from "../../../../components/pagination/pagination";
import EmptyState from "../../../../assets/images/emptyState/food-package.svg";
import FilterMerchandisePackageDropDown from "../../../../components/dropDown/filter/filterMerchandisePackage";
import { writeFileWithXLSX } from "../../../../components/exports/xlsx";
import {
  filterPackageAction,
  searchPackageAction,
  getAllPackagesNoPagination,
} from "../../../../redux/packages/packagesActions";
import { AllFiltersModal } from "../../../../components/modals/allFiltersModal";
import packageType from "../../../../redux/packages/packagesTypes";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function MerchandisePackage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page } = useParams();
  const [data, setData] = useState([]);
  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);

  const { allPackages, downloading } = useSelector((state) => state.packages);
  const [isFilterOpen, setFilterState] = useState(false);
  const { searching, filtering } = useSelector((state) => state.packages);
  const [input, setInput] = useState({
    search: "",
  });
  const [payLoad] = useState({
    start_date: lastMonth,
    end_date: new Date(),
    package_type: "merchandise",
  });
  const storePackages = allPackages?.data?.filter(
    (item) => item?.package_type === "merchandise"
  );

  useEffect(() => {
    dispatch(filterPackageAction(payLoad, page));
    // eslint-disable-next-line
  }, [page]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((pack, index) => {
        let show = {
          id: index + 1,
          name: pack?.package_name,
          options: pack?.package_minimum_selection,
          ave_amount: (
            <span>NGN {numberWithCommas(pack?.package_average_amount)}</span>
          ),
          status: (
            <span>
              {pack.management_approved ? (
                <span className="text-[#00D220] font-bold">Approved</span>
              ) : (
                <span className="text-primary font-bold">Unapproved</span>
              )}
            </span>
          ),
          packageData: pack,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  function createTableDataDownload(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((pack, index) => {
        let show = {
          id: index + 1,
          name: pack?.package_name,
          options: pack?.package_minimum_selection,
          ave_amount: pack?.package_average_amount,
          status: pack.management_approved ? "Approved" : "Unapproved",
        };
        outputArray.push(show);
        return true;
      });
      return outputArray;
    }
  }

  useLayoutEffect(() => {
    const dataSet = createTableData(storePackages);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPackages]);

  function goToSinglePage(params) {
    let id = params?.show?.packageData?.id;
    navigate(`/admin/merchandise-packages/package/${id}`);
  }
  function goToAddPage() {
    navigate(`/admin/merchandise-packages/add`);
  }

  const handleDownload = async () => {
    const response = await dispatch(
      getAllPackagesNoPagination({
        ...payLoad,
        pagination: 0,
      })
    );
    if (response) {
      const newData = createTableDataDownload(response?.data);
      writeFileWithXLSX(newData, {
        filename: "Merchandise_packages.xlsx",
        download: true,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allPackages?.data);
      setData(dataSet);
    }
    dispatch({
      type: packageType["SEARCH_PACKAGE"],
      payLoad: { data: [] },
    });
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchPackageAction(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.id",
          },
          {
            Header: "Package Name",
            accessor: "show.name",
          },

          {
            Header: "Options",
            accessor: "show.options",
          },

          {
            Header: "Avg. Price",
            accessor: "show.ave_amount",
          },

          {
            Header: "Status",
            accessor: "show.status",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="packages-page">
      <Card.TableFilter
        title={"Merchandise Packages"}
        results={allPackages?.to}
        totalResults={allPackages?.total}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        searchPlaceholder={"Store, Title, or Subscriber "}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterMerchandisePackageDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
      >
        <Button.Primary
          title={"+ Create Merchandise Package"}
          onClick={goToAddPage}
        />
        <Button.Secondary
          title={downloading ? <Beat color={"#CE2600"} /> : "Download CSV"}
          disabled={data?.length < 1}
          type="button"
          onClick={handleDownload}
        />
      </Card.TableFilter>

      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white dark:bg-[#121212] rounded-md py-4">
        {searching || filtering ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        ) : (
          <div className="py-36 text-center">
            <div className="flex justify-center">
              <img src={EmptyState} className="w-auto" alt="no data" />
            </div>
            <h3 className="mt-5 text-xl font-bold">Merchandise Packages</h3>
            <p className="mt-2 text-sm text-[#9CA3AF]">
              You have not added any merchandise package yet.
            </p>
          </div>
        )}
        <Pagination
          data={allPackages}
          route={"admin/merchandise-packages/packages"}
        />
      </div>
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Beat } from "../../../../plugins/spinners.plugin";
import Button from "../../../../components/buttons/buttons";
import Input from "../../../../components/inputs/inputs";
import { groupBy } from "../../../../helpers/groupBy";
import { XIcon } from "@heroicons/react/outline";
import AddFoodVariationModal from "../../../../components/modals/admin/attributes/addFoodVariationModal";
import EditFoodAttribute from "../../../../components/modals/admin/attributes/editFoodAttribute";
import RemoveFoodAttributeModal from "../../../../components/modals/admin/attributes/removeFoodAttributeModal";
import { cleanInput } from "../../../../helpers/cleanInput";
import SelectableInput from "../../../../components/inputs/SelectableInput";
import { getAFoodAction } from "../../../../redux/food/foodActions";
import { updateAFoodAction } from "../../../../redux/food/foodActions";
import { filterAttributeAction } from "../../../../redux/attribute/attributeActions";
import { filterProductCategory } from "../../../../redux/productCatergory/productCategoryAction";
import { filterProductTag } from "../../../../redux/productTags/productTagActions";

export default function EditFoodAdmin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const hiddenFileInput = useRef(null);

  let { food } = location.state;

  const { allCategories } = useSelector((state) => state.productCatergory);
  const { allTags } = useSelector((state) => state.productTag);
  const { singleFood, updating } = useSelector((state) => state.food);

  const [isOpen, setIsOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [displayArr, setDisplayArr] = useState({});
  const [selected, setSelected] = useState([]);
  const [selectedArr, setSelectedArr] = useState([]);
  const [allAttributeArr, setAllAttributeArr] = useState([]);
  const [images, setImages] = useState();
  const [imageUrls, setImageUrls] = useState([]);
  const [image64, setImage64] = useState([]);
  const [data, setData] = useState({
    id: singleFood?.id,
    stock: food?.stock,
    food_amount: food?.food_amount,
    food_discount: food?.food_discount,
    food_name: food?.food_name,
    food_description: food?.food_description,
    food_preparation_time: food?.food_preparation_time,
    food_categories: food?.food_categories,
    food_tags: food?.food_tags,
    food_attributes: food?.food_attributes,
    url_photos: [],
    format: food?.format,
  });
  const {
    id,
    stock,
    food_amount,
    food_discount,
    food_categories,
    food_tags,
    format,
  } = data;

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (food?.id) {
      dispatch(getAFoodAction(food?.id));
      dispatch(filterAttributeAction({ business_id: food?.business_id }));
    }
  }, [dispatch, food]);

  useEffect(() => {
    dispatch(filterProductTag({ type: "restaurant" }, 1));
    dispatch(filterProductCategory({ type: "restaurant" }, 1));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let items = allCategories?.data.filter(
      (item) => item.type === "restaurant"
    );
    setCategoryList(items);
  }, [allCategories]);

  useEffect(() => {
    let items = allTags?.data?.filter((item) => item.type === "restaurant");
    setTagList(items);
  }, [allTags]);

  useEffect(() => {
    setAllAttributeArr(singleFood?.attributes);
    singleFood?.attributes.forEach((element) => {
      if (!selectedArr.includes(element.id)) {
        setSelectedArr([...selectedArr, element.id]);
      }
      const in_selected = selected.some((obj) => obj.id === element.id);
      if (!in_selected) {
        setSelected([...selected, element]);
      }
    });
    // eslint-disable-next-line
  }, [singleFood]);

  function getFoodWithCategories() {
    let titleArr = [];
    let newArray = [];
    for (let index = 0; index < selected?.length; index++) {
      if (!titleArr.includes(selected[index].title)) {
        titleArr.push(selected[index].title);
      }
    }
    for (let index = 0; index < titleArr?.length; index++) {
      let temp = {
        title: titleArr[index],
        attributes: selected.filter((food) => titleArr[index] === food.title),
      };
      newArray.push(temp);
    }
    return newArray;
  }

  useEffect(() => {
    const foodAttr = getFoodWithCategories();
    setAllAttributeArr([...allAttributeArr, ...foodAttr]);
    let group = groupBy(foodAttr, "title");
    setDisplayArr({ ...group });
    // eslint-disable-next-line
  }, [selected]);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setData({ ...data, [name]: value });
  };

  //handling selectable input tags and categories
  const handleToggleTags = (value) => {
    let former_tags = [];
    if (data?.food_tags) {
      former_tags = [...data?.food_tags];
      if (former_tags.includes(value)) {
        let arr = [...former_tags];
        const filtered = arr.filter((item) => item !== value);
        setData({ ...data, food_tags: filtered });
      } else {
        let new_tags = [...former_tags, value.name];
        setData({ ...data, food_tags: new_tags });
      }
    } else {
      let new_tags = [...former_tags, value.name];
      setData({ ...data, food_tags: new_tags });
    }
  };

  const handleToggleCategories = (value) => {
    let former_categories = [];
    if (data?.food_categories) {
      former_categories = [...data?.food_categories];
      if (former_categories.includes(value)) {
        let arr = [...former_categories];
        const filtered = arr.filter((item) => item !== value);
        setData({ ...data, food_categories: filtered });
      } else {
        let new_categories = [...former_categories, value.name];
        setData({ ...data, food_categories: new_categories });
      }
    } else {
      let new_categories = [...former_categories, value.name];
      setData({ ...data, food_categories: new_categories });
    }
  };

  //handling removing and adding images to existing images
  useEffect(() => {
    if (singleFood?.food_pictures?.length > 0) {
      setData({ ...data, url_photos: singleFood?.food_pictures });
    }
    // eslint-disable-next-line
  }, [singleFood?.food_pictures]);

  function onImageChange(e) {
    setImages([...e.target.files]);
  }
  const handleFileClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  const removeFile = (index) => {
    let newArr = images;
    newArr.splice(index, 1);
    setImages([...newArr]);
  };

  const removeUrlPhoto = (index) => {
    let newArr = [...data?.url_photos];
    newArr.splice(index, 1);
    setData({ ...data, url_photos: newArr });
  };

  useEffect(() => {
    const newImageUrls = [];
    const newImage64 = [];
    images?.forEach((image) => {
      newImageUrls.push(URL.createObjectURL(image));
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        newImage64.push(reader.result);
      };
    });
    setImageUrls(newImageUrls);
    setImage64(newImage64);
  }, [images]);

  console.log("selectedArr", selectedArr);
  const handleUpdateData = async () => {
    setSubmitted(true);
    const copiedObject = JSON.parse(JSON.stringify(data));
    const mergedObject = Object.assign({}, copiedObject, {
      base64_photos: image64?.length > 0 ? image64 : [],
      food_attributes: selectedArr?.length > 0 ? selectedArr : [],
    });
    cleanInput(mergedObject);

    if (food_categories?.length > 0 && food_tags?.length > 0) {
      try {
        const response = await dispatch(
          updateAFoodAction({
            ...mergedObject,
            id: id,
            stock: stock,
            food_amount: food_amount,
            food_discount: food_discount,
            food_attributes: selectedArr?.length > 0 ? selectedArr : null,
          })
        );
        if (response) {
          navigate(`/admin/food/${singleFood.id}`);
        }
      } catch (error) {}
    }
  };

  return (
    <>
      <AddFoodVariationModal
        show={isOpen}
        onClose={closeModal}
        selected={selected}
        setSelected={setSelected}
        selectedArr={selectedArr}
        setSelectedArr={setSelectedArr}
        food_business_id={singleFood?.business_id}
      />

      <div data-test="editFood-page">
        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Food Details</h3>
          </div>
          <form className="mt-6">
            <Input.Label
              title={"Restaurant name"}
              type="text"
              name="restaurant_id"
              id="restaurant_id"
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              placeholder="Search restaurant"
              value={singleFood?.restaurant?.restaurant_name}
              disabled
            />

            <Input.Label
              title={"Food name"}
              type="text"
              name="food_name"
              id="food_name"
              defaultValue={singleFood?.food_name}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              placeholder="Enter food name"
              onChange={handleChange}
            />
            <Input.Label
              title={
                "Short Description * (Hint: Input product Highlights/Features in bullets. Not more than 200 Characters)"
              }
              type="text"
              name="food_description"
              id="food_description"
              defaultValue={food?.food_description}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              placeholder="Enter food description"
              onChange={handleChange}
            />
            <div className="grid grid-cols-2 gap-8">
              <Input.Number
                title={"Price"}
                type="number"
                name="food_amount"
                id="food_amount"
                defaultValue={food?.food_amount}
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="Enter price"
                onChange={handleChange}
              />

              <Input.Number
                title={"Discounted Price"}
                type="number"
                name="food_discount"
                id="food_discount"
                defaultValue={food?.food_discount}
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="0"
                onChange={handleChange}
              />
            </div>
            <div className="grid grid-cols-2 gap-8">
              <Input.TimePicker
                title={"How many minutes does it take to prepare?"}
                name="food_preparation_time"
                id="food_preparation_time"
                defaultValue={food?.food_preparation_time}
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                onChange={handleChange}
              />
              <Input.Label
                title={"Stock"}
                type="number"
                name="stock"
                id="stock"
                defaultValue={food?.stock}
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="Enter stock quantity"
                onChange={handleChange}
              />
            </div>
            <Input.Select
              title={"Product Type"}
              htmlFor={"format"}
              name={"format"}
              value={format}
              onChange={handleChange}
            >
              <option>Select Product Type</option>
              <option value={"physical"}>Physical Product</option>
              <option value={"digital"}>Digital Product</option>
            </Input.Select>
          </form>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mt-8">
          <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Food Category</h3>
            </div>
            <div className="mt-6">
              <label
                htmlFor="food_categories"
                className="text-sm font-medium text-[#151515] mb-2 block"
              >
                Food Categories
              </label>
              <SelectableInput
                options={categoryList}
                label="food_categories"
                name={"+ Add Category"}
                type="category"
                previousSelected={food_categories}
                selectOption={handleToggleCategories}
              />
              {submitted && food_categories?.length < 1 && (
                <div className="text-xs text-red-500">
                  Food category is required
                </div>
              )}
            </div>
          </div>

          <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Food Tags</h3>
            </div>
            <div className="mt-6">
              <label
                htmlFor="food_tags"
                className="text-sm font-medium text-[#151515] mb-2 block"
              >
                Food Tags
              </label>
              <SelectableInput
                options={tagList}
                label="food_tags"
                name={"+ Add Tag"}
                type="tag"
                previousSelected={food_tags}
                selectOption={handleToggleTags}
              />
              {submitted && food_tags?.length < 1 && (
                <div className="text-xs text-red-500">
                  Food tags is required
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <div>
              <h3 className="text-lg font-medium">
                Food Images (200px by 200px)
              </h3>
              <p className="text-sm">
                Accepts .jpg, .png and .jpeg image formats
              </p>
            </div>

            <button className="text-primary" onClick={handleFileClick}>
              + Upload Image
            </button>
            <input
              type="file"
              className="hidden"
              accept="image/*"
              multiple
              onChange={onImageChange}
              ref={hiddenFileInput}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-6 gap-8 mt-8">
            {data?.url_photos?.length > 0 &&
              data?.url_photos?.map((image, index) => (
                <figure className="w-full h-[160px] relative" key={index}>
                  <img
                    src={image}
                    className="object-cover w-full h-full"
                    alt="food"
                  />
                  <button
                    className="h-8 w-8 bg-[#FAE9E6] rounded-full absolute right-2 top-2"
                    onClick={() => removeUrlPhoto(index)}
                  >
                    <XIcon className="h-5 w-5 text-primary mx-auto" />
                  </button>
                </figure>
              ))}

            {imageUrls?.length > 0 &&
              imageUrls?.map((image, index) => (
                <figure className="w-full h-[160px] relative" key={index}>
                  <img
                    src={image}
                    className="object-cover w-full h-full"
                    alt="food"
                  />
                  <button
                    className="h-8 w-8 bg-[#FAE9E6] rounded-full absolute right-2 top-2"
                    onClick={() => removeFile(index)}
                  >
                    <XIcon className="h-5 w-5 text-primary mx-auto" />
                  </button>
                </figure>
              ))}
          </div>
        </div>

        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Food Attributes</h3>
            <button className="text-primary" onClick={openModal}>
              + Add attribute
            </button>
          </div>

          {selected?.length > 0 ? (
            Object.keys(displayArr).map((single, i) => (
              <div key={i} className="mt-4">
                <p className="text-md my-6">
                  Title : <span className="font-medium">{single}</span>
                </p>
                <div className="grid grid-cols-3 gap-24 px-4">
                  <p className="py-2 text-md">Name</p>
                  <p className="py-2 text-md">Value</p>
                  <p className="py-2 text-md">Action</p>
                </div>
                {displayArr[single]?.map((attribute, i) => (
                  <div key={i}>
                    {attribute?.attributes?.map((attribute, i) => (
                      <div key={i} className="grid grid-cols-3 gap-24 px-4">
                        <p className="py-2 font-medium">{attribute.name}</p>
                        <p className="py-2 font-medium">
                          NGN {attribute.amount}
                        </p>
                        <div>
                          <div className="flex justify-between max-w-[26px] py-2">
                            <EditFoodAttribute
                              myAttributes={attribute}
                              id={food?.id}
                            />
                            <p className="mx-4">|</p>
                            <RemoveFoodAttributeModal
                              id={attribute?.id}
                              myAttributes={attribute}
                              selected={selected}
                              setSelected={setSelected}
                              selectedArr={selectedArr}
                              setSelectedArr={setSelectedArr}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))
          ) : (
            <div className="text-center mx-auto max-w-2xl py-6">
              <h4 className="text-2xl font-medium mb-2">
                You have no product attributes yet
              </h4>
              <p className="max-w-[473px] mx-auto text-lg mb-4">
                When you create new food attributes, it will be displayed here
              </p>
              <button
                className="text-primary text-lg"
                type="button"
                onClick={openModal}
              >
                + Add attribute
              </button>
            </div>
          )}
        </div>
        <div className="my-6 text-center">
          <Button.Primary
            disabled={updating}
            title={updating ? <Beat color={"#ffffff"} /> : "Update Food"}
            onClick={handleUpdateData}
          />
        </div>
      </div>
    </>
  );
}

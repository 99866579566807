import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LeftInput from "./editComponents/LeftInput";
import RightInput from "./editComponents/RightInput";
import { getSingleStoreOrder } from "../../../redux/storeOrders/storeOrderAction";

export default function EditStoreOrder() {
  const dispatch = useDispatch();
  const {id} = useParams();

  const [submitted, setSubmitted] = useState(false);
  const { singleStoreOrder } = useSelector((state) => state.storeOrder);
  

  let [recipient_phone, setRecipientPhone] = useState("");
  
  const [data, setData] = useState({
    id:'',
    business_id: "",
    store_id: "",
    recipient_name: "",
    recipient_email: "",
    recipient_phone: "",
    recipient_address: "",
    recipient_city: "",
    recipient_state: "",
    recipient_country: "",
    order_type: "standard",
    order_paid: "",
    order_payment_method: "",
    order_items: [],
    order_amount: "",
    order_discount: 0,
    order_sub_amount:0,
    recipient_service_area_id: "",
  });

  useEffect(() => {
    if (id) {
      dispatch(getSingleStoreOrder(id));
    }
    // eslint-disable-next-line
  }, [id]);


  useEffect(() => {
    if (singleStoreOrder) {
      setData({
        ...data,
        id: singleStoreOrder?.id,
        business_id: singleStoreOrder?.business_id,
        store_id: singleStoreOrder?.store_id,
        recipient_name: singleStoreOrder?.recipient_name,
        recipient_email: singleStoreOrder?.recipient_email,
        recipient_phone: singleStoreOrder?.recipient_phone,
        recipient_address: singleStoreOrder?.recipient_address,
        recipient_city: singleStoreOrder?.recipient_city,
        recipient_state: singleStoreOrder?.recipient_state,
        recipient_country: singleStoreOrder?.recipient_country,
        order_type: singleStoreOrder?.order_type,
        order_paid: singleStoreOrder?.order_paid,
        order_payment_method: singleStoreOrder?.order_payment_method,
        order_items: singleStoreOrder?.order_items,
        order_amount: singleStoreOrder?.order_amount,
        order_discount: singleStoreOrder?.order_discount,
        order_sub_amount: singleStoreOrder?.order_sub_amount,
        recipient_service_area_id: singleStoreOrder?.recipient_service_area_id,
      });
      setRecipientPhone(singleStoreOrder?.recipient_phone);
    }
    // eslint-disable-next-line
  }, [singleStoreOrder]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setData({ ...data, [name]: value });
  };
  return (
    <div data-test="createOrder-page">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] sm:h-[786px] rounded-md py-5 px-8">
          <LeftInput
            handleChange={handleChange}
            data={data}
            setData={setData}
            submitted={submitted}
            recipient_phone={recipient_phone}
            setRecipientPhone={setRecipientPhone}
          />
        </div>

        <RightInput
          data={data}
          setData={setData}
          submitted={submitted}
          setSubmitted={setSubmitted}
          handleChange={handleChange}
          recipient_phone={recipient_phone}
        />
      </div>
    </div>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Table from "../../../../components/tables/tableCols2";
import TableSkeleton from "../../../../components/skeleton/table";
import { showCart } from "./../../../../redux/cart/cartAction";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function SingleCart() {
  const dispatch = useDispatch();
  const { cart, loading } = useSelector((state) => state.cart);
  const { id } = useParams();

  const [packageData, setPackageData] = useState([]);

  useEffect(() => {
    if (id) {
      dispatch(showCart(id));
    }
  }, [dispatch, id]);

  function getProductAmount(amount, quantity) {
    return (
      <span className="font-bold">
        NGN {numberWithCommas(Number(amount) * Number(quantity))}
      </span>
    );
  }

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap?.map((cart, index) => {
        let single = {
          name: cart?.food_name || cart?.merchandise_name,
          quantity: cart?.quantity,
          price: (
            <span>
              NGN{" "}
              {numberWithCommas(cart?.food_amount) ||
                numberWithCommas(cart?.merchandise_amount)}
            </span>
          ),
          amount: getProductAmount(cart?.food_amount, cart?.quantity),
        };
        outputArray.push({ single });
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const package_data = createTableData(cart?.packages);
    setPackageData(package_data);
    // eslint-disable-next-line
  }, [cart]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "Item Name",
            accessor: "single.name",
          },
          {
            Header: "Item Quantity",
            accessor: "single.quantity",
          },
          {
            Header: "Unit Price",
            accessor: "single.price",
          },
          {
            Header: "Item Amount",
            accessor: "single.amount",
          },
        ],
      },
    ],
    []
  );

  return (
    <div>
      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212]  rounded-md py-5 px-8 mb-12">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium capitalize">Deliver to</h3>
        </div>
        {cart?.address?.length > 0 ? (
          cart?.address?.map((address, i) => (
            <div
              key={i}
              className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-8 mt-6"
            >
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Address</label>
                <p className="text-[#151515] dark:text-white text-sm">{address?.address}</p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Location</label>
                <p className="text-[#151515] dark:text-white text-sm">{address?.city}</p>
              </div>
              {address?.address_selected && (
                <div className="hidden sm:block bg-[#FFCFD0] w-fit px-2 py-1 rounded-md h-6 ml-4">
                  <p className="text-primary text-[12px] font-medium">
                    Default
                  </p>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="py-4 text-center">
            <p>No address available</p>
          </div>
        )}
      </div>

      {/* Products/packages section */}
      <div className="grid md:grid-cols-2 gap-4">
        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212]  rounded-md py-5 px-8 mb-12">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium capitalize">Product Summary</h3>
          </div>

          {loading ? (
            <TableSkeleton />
          ) : cart?.products?.length > 0 ? (
            cart?.products?.map((product, i) => (
              <div key={i}>
                <div className="flex justify-between items-start mb-5">
                  <div className="flex">
                    <span className="w-6 mr-3 sm:mr-10">
                      {product.quantity}x
                    </span>
                    <div>
                      <p className="mb-1">
                        {product?.food_name || product?.merchandise_name}
                      </p>
                      {product?.product_attributes?.length > 0 &&
                        product?.product_attributes?.map((attr, i) => (
                          <p key={i} className="text-sm text-[#676565]">
                            {attr.quantity}x {attr?.name} - NGN {attr.amount}
                          </p>
                        ))}
                    </div>
                  </div>
                  <p>NGN {product?.food_amount || product?.merchandise_amount}</p>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center">
              <div className="flex justify-center my-12 ">
                {/* <img src={Empty} alt="orders" /> */}
              </div>
              <p className="font-bold text-xl my-3">Cart</p>
              <p className="text-sm text-gray-500">
                You do not have any products yet
              </p>
            </div>
          )}
        </div>

        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212]  rounded-md py-5 px-8 mb-12">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium capitalize">Package Summary</h3>
          </div>
          {loading ? (
            <TableSkeleton />
          ) : packageData?.length > 0 ? (
            <Table
              columns={columns}
              data={packageData?.length > 0 ? packageData : []}
              onClick={() => {}}
              className="w-full"
              rowClass="hover:shadow-md cursor-pointer"
            />
          ) : (
            <div className="text-center">
              <div className="flex justify-center my-12 ">
                {/* <img src={Empty} alt="orders" /> */}
              </div>
              <p className="font-bold text-xl my-3">Cart</p>
              <p className="text-sm text-gray-500">
                You do not have any packages yet
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212]  rounded-md py-5 px-8 mb-12">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium capitalize">Payment Summary</h3>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Delivery Fee</label>
            <p className="text-[#151515] dark:text-white text-sm capitalize">
              ₦ {numberWithCommas(cart?.delivery_fee)}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Service Fee</label>
            <p className="text-[#151515] dark:text-white text-sm">
              ₦ {numberWithCommas(cart?.service_fee)}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Insurance Fee</label>
            <p className="text-[#151515] dark:text-white text-sm">
              ₦ {numberWithCommas(cart?.insurance_fee)}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Discount</label>
            <p className="text-[#151515] dark:text-white text-sm">
              ₦ {numberWithCommas(cart?.discount)}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Total</label>
            <p className="text-[#151515] dark:text-white text-sm">
              ₦ {numberWithCommas(cart?.amount)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

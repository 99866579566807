import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Button from "../../../components/buttons/buttons";
import Input from "../../../components/inputs/inputs";
import { cleanInput } from "../../../helpers/cleanInput";
import SearchableDropdown from "../../../helpers/searchableDropDown";
import { Beat } from "../../../plugins/spinners.plugin";
import { createPromoCode } from "../../../redux/promo/promoAction";

export default function CreatePromoCode() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.promo);
  const { allStores } = useSelector((state) => state.store);
  const { allRestaurants } = useSelector((state) => state.restaurant);

  const storeData = allStores?.data;
  const restaurantData = allRestaurants?.data;

  let today = new Date().toISOString().split("T")[0];
  document.getElementsByName("expiry")[0]?.setAttribute("min", today);

  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState({
    slug: "",
    amount: "",
    quantity: "1",
    title: "",
    expiry: "",
    type: "",
  });
  const { slug, amount, quantity, expiry, title, type } = data;

  const selectRestaurantOption = (option) => {
    const value = option?.id;
    setData({ ...data, restaurant_id: value });
  };

  const selectStoreOption = (option) => {
    const value = option?.id;
    setData({ ...data, store_id: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setData({ ...data, [name]: value });
  };
  const handleSubmit = async () => {
    cleanInput(data);
    setSubmitted(true);
    if (slug && type && amount && quantity && title) {
      const response = await dispatch(createPromoCode({ ...data }));
      if (response.status === "success") {
        navigate("/admin/promo/all/1");
      }
    }
  };

  return (
    <>
      <div className=" ml-52">
        <div className=" mx-auto bg-white dark:bg-[#121212] sm:w-[630px] w-full mt-10 sm:ml-10 sm:mt-0 sm:border px-6 py-3 sm:border-gray-200 dark:border-none">
          <div className="border-b-2 py-4 mb-6">
            <p className="text-base font-medium text-gray-800 dark:text-white flex justify-center ">
              Create Promo
            </p>
          </div>
          <form>
            <div className="sm:px-10">
              <div>
                <Input.Label
                  title={"Promo Code"}
                  htmlFor={"slug"}
                  name={"slug"}
                  placeholder={"Enter promo code"}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {submitted && !slug && (
                  <div className="text-primary text-xs ">Slug is required</div>
                )}
              </div>

              <div>
                <Input.Label
                  title={"Promo Title"}
                  htmlFor={"title"}
                  name={"title"}
                  placeholder={"Enter promo title"}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {submitted && !title && (
                  <div className="text-primary text-xs ">Title is required</div>
                )}
              </div>

              <Input.Select
                title={"Promo Type"}
                htmlFor={"type"}
                name={"type"}
                value={type}
                onChange={handleChange}
                placeholder={"Enter City"}
              >
                <option>Select Type</option>
                <option value={"discount"}>Discount</option>
                <option value={"souvenir"}>Souvenir</option>
              </Input.Select>

              <div className="mt-4">
                <Input.DatePicker
                  title={"Expiry date"}
                  htmlFor={"expiry"}
                  name={"expiry"}
                  selected={expiry}
                  onChange={(event) => {
                    setData({
                      ...data,
                      expiry: moment(event.target.value).format("YYYY-MM-DD"),
                    });
                  }}
                />
              </div>

              <div>
                <Input.Label
                  title={"Discount"}
                  htmlFor={"amount"}
                  name={"amount"}
                  placeholder={"Enter discount amount"}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {submitted && !title && (
                  <div className="text-primary text-xs ">
                    Discount is required
                  </div>
                )}
              </div>

              <div className="mt-4">
                <label
                  htmlFor="store_id"
                  className="text-sm font-medium text-[#151515] dark:text-white"
                >
                  Attach Restaurant(optional)
                </label>
                <SearchableDropdown
                  options={restaurantData}
                  label="restaurant_name"
                  name={"restaurant_id"}
                  placeholder={"Select a restaurant"}
                  selectOption={selectRestaurantOption}
                />
              </div>

              <div className="mt-4">
                <label
                  htmlFor="store_id"
                  className="text-sm font-medium text-[#151515] dark:text-white"
                >
                  Attach Store(optional)
                </label>
                <SearchableDropdown
                  options={storeData}
                  label="store_name"
                  name={"store_id"}
                  placeholder={"Select a store"}
                  selectOption={selectStoreOption}
                />
              </div>

              <div className="mt-4 mb-12">
                <Input.Number
                  title={"Promo Quantity"}
                  htmlFor="quantity"
                  name="quantity"
                  placeholder={"Enter number of promo slot to be generated"}
                  defaultValue={quantity}
                  onChange={handleChange}
                  min={1}
                />
                {submitted && (!quantity || quantity < 1) && (
                  <div className="text-primary text-xs ">
                    Quantity is required and quantity cannot be less than 1
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
        <div>
          <Button.Primary
            disabled={loading}
            title={loading ? <Beat /> : "Create Promo Code"}
            className={"w-[190px] sm:mt-10 sm:ml-72 ml-36 "}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
}

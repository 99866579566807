import React, { useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/buttons/buttons";
import Table from "../../../../components/tables/tableCols1";
import TableSkeleton from "../../../../components/skeleton/table";
import TableFilter from "../../../../components/tables/tableFilter";
import { getOutletFromCookies } from "../../../../utils/Auth";
import { Pagination } from "../../../../components/pagination/pagination";
import FoodImage from "../../../../assets/images/emptyState/restaurant-list.jpg";
import Merchandise from "../../../../assets/images/icons/merchandiseEmpty.svg";
import {
  filterMerchandises,
  filterMerchandisesNoPage,
  searchAllMerchandises,
} from "../../../../redux/merchandise/merchandiseActions";
import { writeFileWithXLSX } from "../../../../components/exports/xlsx";
import { AllFiltersModal } from "../../../../components/modals/allFiltersModal";
import FilterMerchandise from "../../../../components/dropDown/filter/merchandiseFilter";
import merchandiseType from "../../../../redux/merchandise/merchandiseTypes";

export default function InActiveMerchandise() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const outlet = getOutletFromCookies();
  // let lastMonth = new Date();
  // lastMonth.setDate(lastMonth.getDate() - 30);
  const { allMerchandises, filtering, searching, filterNoPage } = useSelector(
    (state) => state.merchandise
  );

  const [isFilterOpen, setFilterState] = useState(false);
  const [payLoad] = useState({
    store_id: outlet.id,
    visibility: "private",
    // start_date: lastMonth,
    end_date: new Date(),
  });

  const [data, setData] = useState([]);
  let { page } = useParams();
  const [input, setInput] = useState({
    search: "",
  });

  useEffect(() => {
    dispatch(filterMerchandises(payLoad, page));
    dispatch(filterMerchandisesNoPage(payLoad));
    // eslint-disable-next-line
  }, [page]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap?.map((merchandise, index) => {
        let single = {
          num: <span className="text-base font-semibold">{index + 1}</span>,
          name: merchandise?.merchandise_name,
          image: (
            <img
              src={
                merchandise?.merchandise_pictures?.length > 0
                  ? merchandise?.merchandise_pictures?.[0]
                  : FoodImage
              }
              className="w-[64px] h-[54px]"
              alt="food"
            />
          ),
          price: merchandise?.merchandise_amount,
          status:
            merchandise?.merchandise_operating_status === "available" ? (
              <span className="text-green-400">Available</span>
            ) : (
              <span className="text-red-600">Unavailable</span>
            ),
          category: merchandise?.merchandise_categories,
          quantity: merchandise?.stock,
          merchandise: merchandise,
        };

        outputArray.push({ single });
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allMerchandises?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMerchandises]);

  function createTableNoPage(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap?.map((merchandise, index) => {
        let single = {
          num: <span className="text-base font-semibold">{index + 1}</span>,
          name: merchandise?.merchandise_name,
          price: merchandise?.merchandise_amount,
          status:
            merchandise?.merchandise_operating_status === "available"
              ? "available"
              : "unavailable",
          category: merchandise?.merchandise_categories?.[0],
          quantity: merchandise?.stock,
        };

        outputArray.push(single);
        return true;
      });
      return outputArray;
    }
  }

  const downloadCSV = () => {
    if (filterNoPage) {
      const newData = createTableNoPage(filterNoPage);
      writeFileWithXLSX(newData, {
        filename: "Approved Merchandise.xlsx",
        download: true,
      });
    }
  };

  function goToCreate() {
    navigate(`/admin/${outlet.store_name}/merchandise/create`);
  }

  function goToSinglePage(params) {
    let merchandise_id = params?.single?.merchandise?.id;
    navigate(`/admin/${outlet.store_name}/merchandise/${merchandise_id}`, {
      state: { merchandise: params?.single?.merchandise },
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allMerchandises?.data);
      setData(dataSet);
      dispatch({
        type: merchandiseType["SEARCH_MERCHANDISE"],
        payLoad: { data: [] },
      });
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchAllMerchandises(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "SN",
            accessor: "single.num",
          },
          {
            Header: "Image",
            accessor: "single.image",
          },
          {
            Header: "Name",
            accessor: "single.name",
          },
          {
            Header: "Merch. Price",
            accessor: "single.price",
          },
          {
            Header: "Category",
            accessor: "single.category",
          },
          {
            Header: "Quantity",
            accessor: "single.quantity",
          },
          {
            Header: "Status",
            accessor: "single.status",
          },
        ],
      },
    ],
    []
  );
  return (
    <>
      <TableFilter
        title={"Merchandise"}
        results={allMerchandises?.to}
        totalResults={allMerchandises?.total}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        page={page}
        payLoad={payLoad}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterMerchandise
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
        searchPlaceholder={" Merchandise Name"}
      >
        <Button.Primary title={"+ Create Merchandise"} onClick={goToCreate} />
        <Button.Secondary
          title={"Download CSV"}
          onClick={() => downloadCSV()}
          disabled={!data}
        />
      </TableFilter>

      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white dark:bg-[#121212] rounded-md py-4">
        {filtering || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        ) : (
          <div className="text-center">
            {" "}
            <div className="flex justify-center my-12 ">
              <img src={Merchandise} alt="merchandise" />
            </div>
            <p className="font-bold text-xl my-3">Merchandise</p>
            <p className="text-sm text-gray-500">
              You have no inactive Merchandise.
            </p>
          </div>
        )}

        <Pagination
          data={allMerchandises}
          route={`admin/${outlet?.store_name}/merchandise/inactive`}
        />
      </div>
    </>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { XIcon } from "@heroicons/react/outline";
import { Beat } from "../../../plugins/spinners.plugin";
import Button from "../../../components/buttons/buttons";
import Input from "../../../components/inputs/inputs";
import { cleanInput } from "../../../helpers/cleanInput";
import SelectableInput from "../../../components/inputs/SelectableInput";
import Location from "../../../components/includes/location";
import { getSingleStore } from "../../../redux/stores/storeActions";
import { getAllAttributesAction } from "../../../redux/attribute/attributeActions";
import { filterProductCategory } from "../../../redux/productCatergory/productCategoryAction";
import { filterProductTag } from "../../../redux/productTags/productTagActions";
import { getToken, getTeamFromCookies } from "../../../utils/Auth";
import successHandler from "../../../handlers/successHandler";
import errorHandler from "../../../handlers/errorHandler";

export default function EditStoreAdmin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const hiddenFileInput = useRef(null);
  const hiddenBannerInput = useRef(null);
  let base_url = process.env.REACT_APP_API_URL;
  const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);

  const { allCategories } = useSelector((state) => state.productCatergory);
  const { allTags } = useSelector((state) => state.productTag);
  const { singleStore } = useSelector((state) => state.store);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [images, setImages] = useState();
  const [imageUrls, setImageUrls] = useState([]);
  const [image64, setImage64] = useState([]);
  const [fileArray, setFileArray] = useState([]);
  const [bannersUrl, setBannersUrl] = useState([]);
  const [data, setData] = useState({
    id: singleStore?.id,
    store_name: singleStore?.store_name,
    store_email: singleStore?.store_email,
    store_address: singleStore?.store_address,
    store_phone: singleStore?.store_phone,
    store_categories: singleStore?.store_categories,
    store_tags: singleStore?.store_tags,
    store_country: singleStore?.store_country,
    store_city: singleStore?.store_city,
    store_state: singleStore?.store_state,
    store_zip: singleStore?.store_zip,
    store_banners: singleStore?.store_banners,
    store_pictures: singleStore?.store_pictures,
  });
  const {
    store_name,
    store_email,
    store_address,
    store_phone,
    store_country,
    store_state,
    store_city,
    store_categories,
    store_tags,
    store_zip,
    store_banners,
    store_pictures,
  } = data;

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getSingleStore(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getAllAttributesAction());
    dispatch(filterProductTag({ type: "store" }, 1));
    dispatch(filterProductCategory({ type: "store" }, 1));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let items = allCategories?.data.filter((item) => item.type === "store");
    setCategoryList(items);
  }, [allCategories]);

  useEffect(() => {
    let items = allTags?.data?.filter((item) => item.type === "store");
    setTagList(items);
  }, [allTags]);

  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }

  const handleBannerClick = (event) => {
    event.preventDefault();
    hiddenBannerInput.current.click();
  };
  const uploadMultipleFiles = (e) => {
    setFileArray([...e.target.files]);
  };

  useEffect(() => {
    if (fileArray.length < 1) return;
    const newImageUrls = [];
    fileArray.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
    setBannersUrl(newImageUrls);
  }, [fileArray]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setData({ ...data, [name]: value });
  };

  const handleStorePhone = (e) => {
    let store_phone = e?.slice(1);
    setData({ ...data, store_phone: store_phone });
  };

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  //this is for selecting Store tags
  const handleToggleTags = (value) => {
    let former_tags = [];
    if (data?.store_tags) {
      former_tags = [...data?.store_tags];
      if (former_tags.includes(value)) {
        let arr = [...former_tags];
        const filtered = arr.filter((item) => item !== value);
        setData({ ...data, store_tags: filtered });
      } else {
        let new_tags = [...former_tags, value.name];
        setData({ ...data, store_tags: new_tags });
      }
    } else {
      let new_tags = [...former_tags, value.name];
      setData({ ...data, store_tags: new_tags });
    }
  };

  //this is for selecting Store categories
  const handleToggleCategories = (value) => {
    let former_categories = [];
    if (data?.store_categories) {
      former_categories = [...data?.store_categories];
      if (former_categories.includes(value)) {
        let arr = [...former_categories];
        const filtered = arr.filter((item) => item !== value);
        setData({ ...data, store_categories: filtered });
      } else {
        let new_categories = [...former_categories, value.name];
        setData({ ...data, store_categories: new_categories });
      }
    } else {
      let new_categories = [...former_categories, value.name];
      setData({ ...data, store_categories: new_categories });
    }
  };

  //handling removing and adding images to existing images
  function onImageChange(e) {
    setImages([...e.target.files]);
  }
  const handleFileClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  const removeFile = (index) => {
    let newArr = images;
    newArr.splice(index, 1);
    setImages([...newArr]);
  };

  useEffect(() => {
    const newImageUrls = [];
    const newImage64 = [];
    images?.forEach((image) => {
      newImageUrls.push(URL.createObjectURL(image));
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        newImage64.push(reader.result);
      };
    });
    setImageUrls(newImageUrls);
    setImage64(newImage64);
  }, [images]);

  const handleUpdateData = async () => {
    setSubmitted(true);
    const copiedObject = JSON.parse(JSON.stringify(data));
    const mergedObject = Object.assign({}, copiedObject, {
      base64_photos: image64?.length > 0 ? image64 : [],
      banners: fileArray ? fileArray : [],
    });
    cleanInput(mergedObject);

    if (store_categories?.length > 0 && store_tags?.length > 0) {
      setLoading(true);
      axios
        .post(`${base_url}/api/store/update`, mergedObject, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data;`,
            Authorization: `Bearer ${getToken()}`,
            Team: `${getTeamFromCookies()}`,
          },
        })
        .then((response) => {
          setLoading(false);
          successHandler(response, true);
          navigate(`/admin/stores/${singleStore?.id}`);
        })
        .catch((error) => {
          //handle error
          setLoading(false);
          errorHandler(error, true);
        });
    }
    
  };

  return (
    <>
      <div data-test="editFood-page">
        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Store Details</h3>
            <Button.Primary
              disabled={loading}
              title={
                loading ? (
                  <Beat color={"#ffffff"} />
                ) : isMobile() ? (
                  "Save"
                ) : (
                  "Save Changes"
                )
              }
              onClick={handleUpdateData}
            />
          </div>
          <form className="mt-6">
            <Input.Label
              title={"Store name"}
              type="text"
              name="store_name"
              id="store_name"
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              placeholder="Enter store name"
              defaultValue={store_name}
              onChange={handleChange}
            />

            <Input.Label
              title={"Store Email"}
              type="text"
              name="store_email"
              id="store_email"
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              placeholder="Enter store email"
              defaultValue={store_email}
              onChange={handleChange}
            />

            <Input.Label
              title={"Store Address"}
              type="text"
              name="store_address"
              id="store_address"
              defaultValue={store_address}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              placeholder="Enter store address"
              onChange={handleChange}
            />
            <Input.Phone
              title={"Store Phone Number"}
              name={"store_phone"}
              id={"store_phone"}
              defaultCountry={"NG"}
              value={store_phone}
              htmlFor={"store_phone"}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              onChange={handleStorePhone}
            />

            {/* Introduce location for country, states and city */}
            <Location
              country_id={locationId.country_id}
              state_id={locationId.state_id}
              city_id={locationId.city_id}
              countryList={(value) => {
                setLocationList({
                  ...locationList,
                  countryList: value,
                });
              }}
              stateList={(value) => {
                setLocationList({
                  ...locationList,
                  stateList: value,
                });
              }}
              cityList={(value) => {
                setLocationList({ ...locationList, cityList: value });
              }}
            />
            <div className="grid grid-cols-2 gap-8">
              <Input.Select
                title={"Select Country"}
                name="store_country"
                id="store_country"
                defaultValue={store_country}
                className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                htmlFor={"store_country"}
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    country_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>{store_country ?? "Select Country"}</option>
                {locationList.countryList &&
                  locationList.countryList.length > 0 &&
                  locationList.countryList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({
                          name: item.name,
                          id: item.id,
                        })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </Input.Select>

              <Input.Select
                title={"Select State"}
                name="store_state"
                id="store_state"
                defaultValue={store_state}
                className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                htmlFor={"store_state"}
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    state_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>{store_state ?? "Select State"}</option>
                {locationList.stateList &&
                  locationList.stateList.length > 0 &&
                  locationList.stateList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({
                          name: item.name,
                          id: item.id,
                        })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </Input.Select>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <Input.Select
                title={"Select City"}
                name="store_city"
                id="store_city"
                defaultValue={store_city}
                className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                htmlFor={"store_city"}
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    city_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>{store_city ?? "Select City"}</option>
                {locationList.cityList &&
                  locationList.cityList.length > 0 &&
                  locationList.cityList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({
                          name: item.name,
                          id: item.id,
                        })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </Input.Select>

              <Input.Label
                title={"Zip Code"}
                type="number"
                name="store_zip"
                id="store_zip"
                defaultValue={store_zip}
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="Enter Store zip"
                onChange={handleChange}
              />
            </div>

            <div className="grid grid-cols-2 gap-8">
              <Input.Label
                title={"Latitude"}
                type="number"
                name="latitude"
                id="latitude"
                defaultValue={singleStore?.latitude}
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="Enter latitude"
                onChange={handleChange}
              />

              <Input.Label
                title={"Longitude"}
                type="number"
                name="longitude"
                id="longitude"
                defaultValue={singleStore?.longitude}
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="Enter longitude"
                onChange={handleChange}
              />
            </div>
          </form>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Store Category</h3>
            </div>
            <div className="mt-6">
              <label
                htmlFor="store_categories"
                className="text-sm font-medium text-[#151515] mb-2 block"
              >
                Categories
              </label>
              <SelectableInput
                options={categoryList}
                label="store_categories"
                name={"+ Add Category"}
                type="category"
                previousSelected={store_categories}
                selectOption={handleToggleCategories}
              />
              {submitted &&
                (store_categories?.length < 1 || store_categories === null) && (
                  <div className="text-xs text-red-500">
                    Store category is required
                  </div>
                )}
            </div>
          </div>

          <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Store Tags</h3>
            </div>
            <div className="mt-6">
              <label
                htmlFor="store_tags"
                className="text-sm font-medium text-[#151515] mb-2 block"
              >
                Tags
              </label>
              <SelectableInput
                options={tagList}
                label="store_tags"
                name={"+ Add Tag"}
                type="tag"
                previousSelected={store_tags}
                selectOption={handleToggleTags}
              />
              {submitted && (store_tags?.length < 1 || store_tags === null) && (
                <div className="text-xs text-red-500">
                  Store tags is required
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <div>
              <h3 className="text-lg font-medium">
                Store Images (200px by 200px)
              </h3>
              <p className="text-sm">
                Accepts .jpg, .png and .jpeg image formats
              </p>
            </div>

            <button className="text-primary" onClick={handleFileClick}>
              + Upload Image
            </button>
            <input
              type="file"
              className="hidden"
              accept="image/*"
              multiple
              onChange={onImageChange}
              ref={hiddenFileInput}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-6 gap-8 mt-8">
            {imageUrls?.length > 0 ? (
              imageUrls?.map((image, index) => (
                <figure className="w-full h-[160px] relative" key={index}>
                  <img
                    src={image}
                    className="object-cover w-full h-full"
                    alt="store"
                  />
                  <button
                    className="h-8 w-8 bg-[#FAE9E6] rounded-full absolute right-2 top-2"
                    onClick={() => removeFile(index)}
                  >
                    <XIcon className="h-5 w-5 text-primary mx-auto" />
                  </button>
                </figure>
              ))
            ) : store_pictures?.length > 0 ? (
              store_pictures?.map((picture, index) => (
                <figure className="w-full h-[160px] relative" key={index}>
                  <img
                    src={picture}
                    className="object-cover w-full h-[160px]"
                    alt="store"
                  />
                </figure>
              ))
            ) : (
              <p>No images</p>
            )}
          </div>
        </div>

        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <div>
              <h3 className="text-lg font-medium">
                Store Banner (1440px by 576px)
              </h3>
            </div>
            <>
              <button className="text-primary" onClick={handleBannerClick}>
                + Upload Banner
              </button>
              <input
                type="file"
                hidden
                className="form-control"
                onChange={uploadMultipleFiles}
                ref={hiddenBannerInput}
                multiple
              />
            </>
          </div>

          <div className="mt-8">
            {bannersUrl?.length > 0 ? (
              <div className="grid grid-cols-1 gap-8">
                {bannersUrl.map((url, index) => (
                  <figure className="w-full h-[250px] relative" key={index}>
                    <img
                      src={url}
                      className="object-cover w-full h-[250px]"
                      alt="banner"
                    />
                  </figure>
                ))}
              </div>
            ) : store_banners?.length > 0 ? (
              <div className="grid grid-cols-1 gap-8">
                {store_banners.map((banner, index) => (
                  <figure className="w-full h-[250px] relative" key={index}>
                    <img
                      src={banner}
                      className="object-cover w-full h-[250px]"
                      alt="banner"
                    />
                  </figure>
                ))}
              </div>
            ) : (
              <p>No images</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

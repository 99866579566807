import React from "react";
import SEARCH from "../../../assets/images/icons/search.svg";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Card = ({ children }) => {
  return (
    <div className="card" data-test="cardComponent">
      {children}
    </div>
  );
};
Card.Dashboard = ({ summary }) => {
  return (
    <div className={"w-full sm:w-full rounded-lg  cursor-pointer"}>
      <div className="px-3 py-4 sm:p-5 border dark:border-none bg-white dark:bg-[#121212] rounded-md">
        <div className="mb-6 flex justify-between items-center">
          <h5 className="text-[13px] sm:text-md sm:text-md dark:text-[#BCBCBC]">
            {summary.name}
          </h5>
          <span className="bg-[#F4F6FD] dark:bg-[#010101] rounded px-2 py-1 text-black dark:text-white text-[10px] sm:text-md">
            Today
          </span>
        </div>

        <div className="text-right flex justify-between items-center">
          <summary.icon className="h-[40px] sm:h-16 w-[40px] sm:w-16" />
          <div>
            <h4 className="font-bold text-2xl mb-1">{summary.amount ?? 0}</h4>
            <p className="text-center text-[#344B67] dark:text-[#BCBCBC] text-sm font-medium font-semibold w-[70px] text-right">
              {summary.status}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

Card.Settings = ({ title, onClick, text }) => {
  return (
    <div
      className={
        "w-full sm:w-[268px] sm:h-24 rounded-xl shadow-lg px-2 py-6  bg-white cursor-pointer"
      }
      onClick={onClick}
    >
      <div className="mb-6 items-center">
        <p className="text-sm text-[#a3aed0] font-medium">{title}</p>
        <p className="text-[#2B3674]">{text}</p>
      </div>
    </div>
  );
};

Card.TableFilter = ({
  results,
  searchPlaceholder,
  title,
  totalResults,
  filter,
  onChange,
  children,
  handleKeyDown,
}) => {
  return (
    <div className="bg-white dark:bg-[#121212] rounded-md py-5 px-4 sm:px-8">
      <div>
        <p className="mb-5 text-[#717485] dark:text-white">{title}</p>
      </div>

      <div className="flex justify-between">
        <div className="flex flex-col flex-1 sm:flex-row items-start sm:items-center gap-3 relative w-full">
          <div className="w-full sm:w-auto">{filter}</div>
          <div className="w-full sm:w-[380px] relative">
            <input
              type={"Search"}
              name={"search"}
              autoComplete="off"
              placeholder={searchPlaceholder}
              className="fa-search w-full sm:w-[380px] h-[36px] p-2 pr-6 border border-[#E4E4F3] dark:bg-transparent focus-within:border-none focus:border-primary focus:outline-primary focus:ring-0 sm:text-sm"
              onChange={onChange}
              onKeyDown={handleKeyDown}
            />
            <img
              className="fa-search absolute top-[10px] right-4 h-4 "
              src={SEARCH}
              alt="search-icon"
            />
          </div>
        </div>
        <div className="flex sm:flex gap-3 hidden">{children}</div>
      </div>
    </div>
  );
};

Card.AttributeFilter = ({
  results,
  totalResults,
  onClick,
  onChange,
  children,
}) => {
  return (
    <div className="bg-white dark:bg-[#121212] rounded-md py-5  px-8 ">
      <div className="flex justify-between">
        <p className=" mt-3 text-[#717485]">Attributes</p>
        <div className="flex gap-3">{children}</div>
      </div>
    </div>
  );
};

Card.AddPackageItem = ({ foods, image }) => {
  const onImageError = (e) => {
    e.target.src = image;
  };

  return (
    <div className="flex gap-6 items-center justify-between mb-4 pb-3">
      <table className="w-full">
        <thead>
          <tr>
            <td className="text-sm font-bold py-2 border-b border-[#E3E3F3]">
              SN
            </td>
            <td className="text-sm font-bold py-2 border-b border-[#E3E3F3]">
              Image
            </td>
            <td className="text-sm font-bold py-2 border-b border-[#E3E3F3]">
              Food Name
            </td>
            <td className="text-sm font-bold py-2 border-b border-[#E3E3F3]">
              Food Price
            </td>
            <td className="text-sm font-bold py-2 border-b border-[#E3E3F3]">
              Category
            </td>
            <td className="text-sm font-bold py-2 border-b border-[#E3E3F3]">
              Stock
            </td>
          </tr>
        </thead>
        <tbody>
          {foods.map((food, i) => (
            <tr key={i}>
              <td className="py-2 border-b border-[#E3E3F3]">{i + 1}</td>
              <td className="py-2 border-b border-[#E3E3F3]">
                <img
                  src={food?.food_pictures?.[0] ?? image}
                  onError={onImageError}
                  className="h-[54px] w-[64px] object-cover"
                  alt="food"
                />
              </td>
              <td className="py-2 border-b border-[#E3E3F3]">
                <h4 className="text-sm">
                  {food?.food_name ? food?.food_name : "Not available"}
                </h4>
              </td>
              <td className="py-2 border-b border-[#E3E3F3]">
                <h4 className="text-sm">
                  {food?.food_amount
                    ? `₦${numberWithCommas(food?.food_amount)}`
                    : "Not available"}
                </h4>
              </td>
              <td className="py-2 border-b border-[#E3E3F3]">
                <h4 className="text-sm">
                  {food?.food_categories?.[0]
                    ? food?.food_categories?.[0]
                    : "Not available"}
                </h4>
              </td>
              <td className="py-2 border-b border-[#E3E3F3]">
                <h4 className="text-sm">
                  {food?.stock ? food?.stock : "Not available"}
                </h4>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
Card.AddMerchPackageItem = ({ merchandise, image }) => {
  const onImageError = (e) => {
    e.target.src = image;
  };
  return (
    <div className="flex gap-6 items-center justify-between mb-4 pb-3">
      <table className="w-full">
        <thead>
          <tr>
            <td className="text-sm font-bold py-2 border-b border-[#E3E3F3]">
              SN
            </td>
            <td className="text-sm font-bold py-2 border-b border-[#E3E3F3]">
              Image
            </td>
            <td className="text-sm font-bold py-2 border-b border-[#E3E3F3]">
              Merchandise Name
            </td>
            <td className="text-sm font-bold py-2 border-b border-[#E3E3F3]">
              Merchandise Price
            </td>
            <td className="text-sm font-bold py-2 border-b border-[#E3E3F3]">
              Category
            </td>
            <td className="text-sm font-bold py-2 border-b border-[#E3E3F3]">
              Stock
            </td>
          </tr>
        </thead>
        <tbody>
          {merchandise?.map((merchandise, i) => (
            <tr key={i}>
              <td className="py-2 border-b border-[#E3E3F3]">{i + 1}</td>
              <td className="py-2 border-b border-[#E3E3F3]">
                <img
                  src={merchandise?.merchandise_pictures[0] ?? image}
                  onError={onImageError}
                  className="h-[54px] w-[64px] object-cover"
                  alt="food"
                />
              </td>
              <td className="py-2 border-b border-[#E3E3F3]">
                <h4 className="text-sm">
                  {merchandise?.merchandise_name ?? "Not available"}
                </h4>
              </td>
              <td className="py-2 border-b border-[#E3E3F3]">
                <h4 className="text-sm">
                  {merchandise?.merchandise_amount
                    ? `₦${numberWithCommas(merchandise?.merchandise_amount)}`
                    : "Not available"}
                </h4>
              </td>
              <td className="py-2 border-b border-[#E3E3F3]">
                <h4 className="text-sm">
                  {merchandise?.merchandise_categories?.[0] ?? "Not available"}
                </h4>
              </td>
              <td className="py-2 border-b border-[#E3E3F3]">
                <h4 className="text-sm">
                  {merchandise?.stock ?? "Not available"}
                </h4>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Card.AttributeItem = ({ attributes }) => {
  return (
    <>
      {attributes?.map((attribute, index) => {
        return (
          <div key={index} className="py-3 mb-1 border-b border-dashed">
            <p className="mb-3">Title : {attribute?.title}</p>
            <div className="grid grid-cols-3 gap-8 mb-2 pl-2">
              <p>Name</p>
              <p>Value</p>
            </div>

            <div className="grid grid-cols-3 gap-8 pl-2 mb-2" key={index}>
              <p>{attribute.name}</p>
              <p>{attribute.amount}</p>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Card;

import React, { Fragment, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CLOSE from "../../../assets/images/icons/close.svg";
import LOGO from "../../../assets/images/icons/ogwugo-o.svg";
import PAYSTACK from "../../../assets/images/icons/paystack.svg";
import FLUTTER from "../../../assets/images/icons/flutter.svg";
import Warning from "../../../assets/images/icons/warning-circle.svg";
import PaystackPlugin from "../../../pages/common/payments/Paystack";
import FlutterwavePlugin from "../../../pages/common/payments/Flutterwave";
import PaymentSkeleton from "../../skeleton/payment";
import { getOutletFromCookies } from "../../../utils/Auth";
import { showApplication } from "../../../redux/applicationSettings/applicationAction";

export function FeaturePaymentModal(props) {
  const { isOpen, setOpen, amount, reference_id } = props;
  const outlet = getOutletFromCookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paystackRef = useRef(null);
  const flutterRef = useRef(null);
  const { settings, isLoading } = useSelector((state) => state.application);

  useEffect(() => {
    dispatch(showApplication());
    // eslint-disable-next-line
  }, []);

  function closeModal() {
    setOpen(false);
  }

  function goToBank() {
    navigate(`/bank-transfer`, {
      state: { totalPrice: amount },
    });
  }

  return (
    <>
      <Transition show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-[629px] bg-white dark:bg-[#121212] rounded-sm px-4 pt-5 pb-4  overflow-hidden shadow-xl transform transition-all sm:my-10 sm:max-w-lg sm:w-full  sm:p-8">
                  <div className="sm:block absolute top-2 right-3 pt-4 pr-4">
                    <button type="button" className="" onClick={closeModal}>
                      <span className="sr-only">Close</span>
                      <img
                        src={CLOSE}
                        alt="close"
                        className=" w-3 h-3 sm:w-4 sm:h-4"
                      />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="text-center mx-auto ">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-meduim text-black dark:text-white"
                      >
                        How would you like to pay?
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className=" border-b-2 "></div>
                  <div className="mt-6 flex gap-2 items-center justify-center">
                    <img
                      src={Warning}
                      alt="warning"
                      className="w-[18px] h-[18px] cursor-pointer"
                    />
                    <p className="text-md">
                      "If your card hasn't been enabled for online transactions,
                      kindly opt for a bank transfer as an alternative."
                    </p>
                  </div>
                  <div className="mt-3 sm:mt-2">
                    <div className="mt-5">
                      {isLoading ? (
                        <PaymentSkeleton />
                      ) : (
                        <ul>
                          {settings?.ogwugo_payment_status === "available" && (
                            <li
                              className="flex mb-4 bg-white dark:bg-[#121212] h-20 shadow py-4 text-left rounded-sm cursor-pointer hover:bg-gray-100 group hover:shadow"
                              onClick={goToBank}
                            >
                              <img
                                src={LOGO}
                                alt="Ogwugo o logo"
                                className="bg-gray-100 p-2 ml-3 w-12 h-12 rounded-lg "
                              />
                              <div className="ml-3 overflow-hidden">
                                <p className="text-lg font-medium text-black dark:text-white">
                                  Ogwugo Pay
                                </p>
                                <p className=" text-base text-[#676565] dark:text-[#BCBCBC] font-normal">
                                  Transfer into Ogwugo account
                                </p>
                              </div>
                            </li>
                          )}
                          {settings?.pay_stack_payment_status ===
                            "available" && (
                            <li
                              onClick={() =>
                                paystackRef.current.getAmount_Reference(
                                  amount,
                                  reference_id
                                )
                              }
                              className="flex w-full mb-4 bg-white dark:bg-[#121212] h-20 shadow rounded-sm py-4 text-left cursor-pointer hover:bg-gray-100 group hover:shadow"
                            >
                              <img
                                src={PAYSTACK}
                                alt="payStack logo"
                                className="bg-gray-100 p-4 ml-3 w-12 h-12 rounded-lg "
                              />
                              <div className="ml-3 overflow-hidden ">
                                <p className="text-lg font-medium text-black dark:text-white">
                                  Paystack
                                </p>
                                <p className="text-base text-[#676565] dark:text-[#BCBCBC] font-normal">
                                  Pay with Card, USSD, Bank Transfer or Visa QR
                                </p>
                              </div>
                            </li>
                          )}
                          <PaystackPlugin
                            amount={amount}
                            email={
                              outlet?.restaurant_email || outlet?.store_email
                            }
                            onSuccess={() => setOpen(false)}
                            onClose={() => setOpen(false)}
                            reference={reference_id}
                            payment_type={"standard"}
                            ref={paystackRef}
                          />

                          {settings?.flutter_wave_payment_status ===
                            "available" && (
                            <li
                              onClick={() =>
                                flutterRef.current.getAmount_Reference(
                                  amount,
                                  reference_id
                                )
                              }
                              className="flex mb-4 bg-white dark:bg-[#121212] h-20  shadow-lg py-4 text-left rounded-sm cursor-pointer hover:bg-gray-100 group hover:shadow"
                            >
                              <img
                                src={FLUTTER}
                                alt="Ogwugo o logo"
                                className="bg-gray-100 p-2 ml-3 w-12 h-12 rounded-lg "
                              />
                              <div className="ml-3 overflow-hidden">
                                <p className="text-xl font-medium text-black dark:text-white">
                                  Flutterwave
                                </p>
                                <p className=" text-base text-[#676565] dark:text-[#BCBCBC] font-normal ">
                                  Pay with Card, USSD, Bank Transfer.
                                </p>
                              </div>
                            </li>
                          )}
                          <FlutterwavePlugin
                            amount={amount}
                            email={
                              outlet?.restaurant_email || outlet?.store_email
                            }
                            phone_number={
                              outlet?.restaurant_phone || outlet?.store_phone
                            }
                            name={outlet?.restaurant_name || outlet?.store_name}
                            tx_ref={reference_id}
                            payment_type={"standard"}
                            onSuccess={() => setOpen(false)}
                            onClose={() => setOpen(false)}
                            ref={flutterRef}
                          />
                          {/* <li className="flex mb-6 bg-white dark:bg-[#121212] h-20  shadow-lg py-4 text-left rounded-sm cursor-pointer hover:bg-gray-100 group hover:shadow">
                            <img
                              src={PAYSTACK}
                              alt="Ogwugo logo"
                              className="bg-gray-100 p-4 ml-3 w-12 h-12 rounded-lg "
                            />
                            <div className="ml-3 overflow-hidden">
                              <p className="text-xl font-meduim text-black">
                                Ussd
                              </p>
                              <p className=" text-base text-[#676565] font-normal ">
                                Pay with Ussd
                              </p>
                            </div>
                          </li> */}
                        </ul>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

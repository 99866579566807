import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { XIcon } from "@heroicons/react/outline";
import { Beat } from "../../../plugins/spinners.plugin";
import Button from "../../../components/buttons/buttons";
import Input from "../../../components/inputs/inputs";
import { cleanInput } from "../../../helpers/cleanInput";
import SelectableInput from "../../../components/inputs/SelectableInput";
import Location from "../../../components/includes/location";
import { getARestaurantAction } from "../../../redux/restaurant/restaurantActions";
import { getAllAttributesAction } from "../../../redux/attribute/attributeActions";
import { filterProductCategory } from "../../../redux/productCatergory/productCategoryAction";
import { filterProductTag } from "../../../redux/productTags/productTagActions";
import { getToken, getTeamFromCookies } from "../../../utils/Auth";
import successHandler from "../../../handlers/successHandler";
import errorHandler from "../../../handlers/errorHandler";

export default function EditRestaurantAdmin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const hiddenFileInput = useRef(null);
  const hiddenBannerInput = useRef(null);
  let base_url = process.env.REACT_APP_API_URL;
  const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);

  const { allCategories } = useSelector((state) => state.productCatergory);
  const { allTags } = useSelector((state) => state.productTag);
  const { restaurant } = useSelector((state) => state.restaurant);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [images, setImages] = useState();
  const [imageUrls, setImageUrls] = useState([]);
  const [image64, setImage64] = useState([]);
  const [fileArray, setFileArray] = useState([]);
  const [bannersUrl, setBannersUrl] = useState([]);
  const [data, setData] = useState({
    id: restaurant?.id,
    restaurant_name: restaurant?.restaurant_name,
    restaurant_email: restaurant?.restaurant_email,
    restaurant_address: restaurant?.restaurant_address,
    restaurant_phone: restaurant?.restaurant_phone,
    restaurant_categories: restaurant?.restaurant_categories,
    restaurant_tags: restaurant?.restaurant_tags,
    restaurant_country: restaurant?.restaurant_country,
    restaurant_city: restaurant?.restaurant_city,
    restaurant_state: restaurant?.restaurant_state,
    restaurant_zip: restaurant?.restaurant_zip,
    restaurant_banners: restaurant?.restaurant_banners,
    url_photos: [],
  });
  const {
    restaurant_name,
    restaurant_email,
    restaurant_address,
    restaurant_phone,
    restaurant_country,
    restaurant_state,
    restaurant_city,
    restaurant_categories,
    restaurant_tags,
    restaurant_zip,
    restaurant_banners,
  } = data;

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getARestaurantAction(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getAllAttributesAction());
    dispatch(filterProductTag({ type: "restaurant" }, 1));
    dispatch(filterProductCategory({ type: "restaurant" }, 1));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let items = allCategories?.data?.filter(
      (item) => item.type === "restaurant"
    );
    setCategoryList(items);
  }, [allCategories]);

  useEffect(() => {
    let items = allTags?.data?.filter((item) => item.type === "restaurant");
    setTagList(items);
  }, [allTags]);

  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }

  const handleBannerClick = (event) => {
    event.preventDefault();
    hiddenBannerInput.current.click();
  };

  const uploadMultipleFiles = (e) => {
    setFileArray([...e.target.files]);
  };

  useEffect(() => {
    if (fileArray.length < 1) return;
    const newImageUrls = [];
    fileArray.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
    setBannersUrl(newImageUrls);
  }, [fileArray]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setData({ ...data, [name]: value });
  };

  const handleRestaurantPhone = (e) => {
    let restaurant_phone = e?.slice(1);
    setData({ ...data, restaurant_phone: restaurant_phone });
  };

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  //this is for selecting restaurant tags
  const handleToggleTags = (value) => {
    let former_tags = [];
    if (data?.restaurant_tags) {
      former_tags = [...data?.restaurant_tags];
      if (former_tags.includes(value)) {
        let arr = [...former_tags];
        const filtered = arr.filter((item) => item !== value);
        setData({ ...data, restaurant_tags: filtered });
      } else {
        let new_tags = [...former_tags, value.name];
        setData({ ...data, restaurant_tags: new_tags });
      }
    } else {
      let new_tags = [...former_tags, value.name];
      setData({ ...data, restaurant_tags: new_tags });
    }
  };

  //this is for selecting restaurant categories
  const handleToggleCategories = (value) => {
    let former_categories = [];
    if (data?.restaurant_categories) {
      former_categories = [...data?.restaurant_categories];
      if (former_categories.includes(value)) {
        let arr = [...former_categories];
        const filtered = arr.filter((item) => item !== value);
        setData({ ...data, restaurant_categories: filtered });
      } else {
        let new_categories = [...former_categories, value.name];
        setData({ ...data, restaurant_categories: new_categories });
      }
    } else {
      let new_categories = [...former_categories, value.name];
      setData({ ...data, restaurant_categories: new_categories });
    }
  };

  //handling removing and adding images to existing images
  useEffect(() => {
    if (restaurant?.restaurant_pictures?.length > 0) {
      setData({ ...data, url_photos: restaurant?.restaurant_pictures });
    }
    // eslint-disable-next-line
  }, [restaurant?.restaurant_pictures]);

  function onImageChange(e) {
    setImages([...e.target.files]);
  }
  const handleFileClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  const removeFile = (index) => {
    let newArr = images;
    newArr.splice(index, 1);
    setImages([...newArr]);
  };

  const removeUrlPhoto = (index) => {
    let newArr = [...data?.url_photos];
    newArr.splice(index, 1);
    setData({ ...data, url_photos: newArr });
  };

  useEffect(() => {
    const newImageUrls = [];
    const newImage64 = [];
    images?.forEach((image) => {
      newImageUrls.push(URL.createObjectURL(image));
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        newImage64.push(reader.result);
      };
    });
    setImageUrls(newImageUrls);
    setImage64(newImage64);
  }, [images]);

  const handleUpdateData = async () => {
    setSubmitted(true);
    const copiedObject = JSON.parse(JSON.stringify(data));
    const mergedObject = Object.assign({}, copiedObject, {
      base64_photos: image64?.length > 0 ? image64 : [],
      banners: fileArray ? fileArray : [],
    });
    cleanInput(mergedObject);
    if (restaurant_categories?.length > 0 && restaurant_tags?.length > 0) {
      setLoading(true);
      axios
        .post(`${base_url}/api/restaurant/update`, mergedObject, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data;`,
            Authorization: `Bearer ${getToken()}`,
            Team: `${getTeamFromCookies()}`,
          },
        })
        .then((response) => {
          setLoading(false);
          successHandler(response, true);
          navigate(`/admin/restaurants/${restaurant.id}`);
        })
        .catch((error) => {
          //handle error
          setLoading(false);
          errorHandler(error, true);
        });
    }
  };

  return (
    <>
      <div data-test="editFood-page">
        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 sm:mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Restaurant Details</h3>
            <Button.Primary
              disabled={loading}
              title={
                loading ? (
                  <Beat color={"#ffffff"} />
                ) : isMobile() ? (
                  "Save"
                ) : (
                  "Save Changes"
                )
              }
              onClick={handleUpdateData}
            />
          </div>
          <form className="mt-6">
            <Input.Label
              title={"Restaurant name"}
              type="text"
              name="restaurant_name"
              id="restaurant_name"
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              placeholder="Search restaurant"
              defaultValue={restaurant_name}
              onChange={handleChange}
            />

            <Input.Label
              title={"Restaurant Email"}
              type="text"
              name="restaurant_email"
              id="restaurant_email"
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              placeholder="Search restaurant"
              defaultValue={restaurant_email}
              onChange={handleChange}
            />

            <Input.Label
              title={"Restaurant Address"}
              type="text"
              name="restaurant_address"
              id="restaurant_address"
              defaultValue={restaurant_address}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              placeholder="Enter restaurant address"
              onChange={handleChange}
            />
            <Input.Phone
              title={"Restaurant Phone Number"}
              name={"restaurant_phone"}
              id={"restaurant_phone"}
              defaultCountry={"NG"}
              value={restaurant_phone}
              htmlFor={"restaurant_phone"}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              onChange={handleRestaurantPhone}
            />

            {/* Introduce location for country, states and city */}
            <Location
              country_id={locationId.country_id}
              state_id={locationId.state_id}
              city_id={locationId.city_id}
              countryList={(value) => {
                setLocationList({
                  ...locationList,
                  countryList: value,
                });
              }}
              stateList={(value) => {
                setLocationList({
                  ...locationList,
                  stateList: value,
                });
              }}
              cityList={(value) => {
                setLocationList({ ...locationList, cityList: value });
              }}
            />
            <div className="grid grid-cols-2 gap-8">
              <Input.Select
                title={"Select Country"}
                name="restaurant_country"
                id="restaurant_country"
                defaultValue={restaurant_country}
                className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                htmlFor={"restaurant_country"}
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    country_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>{restaurant_country ?? "Select Country"}</option>
                {locationList.countryList &&
                  locationList.countryList.length > 0 &&
                  locationList.countryList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({
                          name: item.name,
                          id: item.id,
                        })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </Input.Select>

              <Input.Select
                title={"Select State"}
                name="restaurant_state"
                id="restaurant_state"
                defaultValue={restaurant_state}
                className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                htmlFor={"restaurant_state"}
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    state_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>{restaurant_state ?? "Select State"}</option>
                {locationList.stateList &&
                  locationList.stateList.length > 0 &&
                  locationList.stateList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({
                          name: item.name,
                          id: item.id,
                        })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </Input.Select>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <Input.Select
                title={"Select City"}
                name="restaurant_city"
                id="restaurant_city"
                defaultValue={restaurant_city}
                className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                htmlFor={"restaurant_city"}
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    city_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>{restaurant_city ?? "Select City"}</option>
                {locationList.cityList &&
                  locationList.cityList.length > 0 &&
                  locationList.cityList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({
                          name: item.name,
                          id: item.id,
                        })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </Input.Select>

              <Input.Label
                title={"Zip Code"}
                type="number"
                name="restaurant_zip"
                id="restaurant_zip"
                defaultValue={restaurant_zip}
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="Enter restaurant zip"
                onChange={handleChange}
              />
            </div>

            <div className="grid grid-cols-2 gap-8">
              <Input.Label
                title={"Latitude"}
                type="number"
                name="latitude"
                id="latitude"
                defaultValue={restaurant?.latitude}
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="Enter latitude"
                onChange={handleChange}
              />

              <Input.Label
                title={"Longitude"}
                type="number"
                name="longitude"
                id="longitude"
                defaultValue={restaurant?.longitude}
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="Enter longitude"
                onChange={handleChange}
              />
            </div>
          </form>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Restaurant Category</h3>
            </div>
            <div className="mt-6">
              <label
                htmlFor="restaurant_categories"
                className="text-sm font-medium text-[#151515] mb-2 block"
              >
                Categories
              </label>
              <SelectableInput
                options={categoryList}
                label="restaurant_categories"
                name={"+ Add Category"}
                type="category"
                previousSelected={restaurant_categories}
                selectOption={handleToggleCategories}
              />
              {submitted &&
                (restaurant_categories?.length < 1 ||
                  restaurant_categories === null) && (
                  <div className="text-sm text-red-500">
                    Restaurant category is required
                  </div>
                )}
            </div>
          </div>

          <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Restaurant Tags</h3>
            </div>
            <div className="mt-6">
              <label
                htmlFor="restaurant_tags"
                className="text-sm font-medium text-[#151515] mb-2 block"
              >
                Tags
              </label>
              <SelectableInput
                options={tagList}
                label="restaurant_tags"
                name={"+ Add Tag"}
                type="tag"
                previousSelected={restaurant_tags}
                selectOption={handleToggleTags}
              />
              {submitted &&
                (restaurant_tags?.length < 1 || restaurant_tags === null) && (
                  <div className="text-sm text-red-500">
                    Restaurant tags is required
                  </div>
                )}
            </div>
          </div>
        </div>

        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <div>
              <h3 className="text-lg font-medium">
                Restaurant Images (200px by 200px)
              </h3>
              <p className="text-sm">
                Accepts .jpg, .png and .jpeg image formats
              </p>
            </div>

            <button className="text-primary" onClick={handleFileClick}>
              + Upload Image
            </button>
            <input
              type="file"
              className="hidden"
              accept="image/*"
              multiple
              onChange={onImageChange}
              ref={hiddenFileInput}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-6 gap-8 mt-8">
            {data?.url_photos?.length > 0 &&
              data?.url_photos?.map((image, index) => (
                <figure className="w-full h-[160px] relative" key={index}>
                  <img
                    src={image}
                    className="object-cover w-full h-full"
                    alt="food"
                  />
                  <button
                    className="h-8 w-8 bg-[#FAE9E6] rounded-full absolute right-2 top-2"
                    onClick={() => removeUrlPhoto(index)}
                  >
                    <XIcon className="h-5 w-5 text-primary mx-auto" />
                  </button>
                </figure>
              ))}

            {imageUrls?.length > 0 &&
              imageUrls?.map((image, index) => (
                <figure className="w-full h-[160px] relative" key={index}>
                  <img
                    src={image}
                    className="object-cover w-full h-full"
                    alt="food"
                  />
                  <button
                    className="h-8 w-8 bg-[#FAE9E6] rounded-full absolute right-2 top-2"
                    onClick={() => removeFile(index)}
                  >
                    <XIcon className="h-5 w-5 text-primary mx-auto" />
                  </button>
                </figure>
              ))}
          </div>
        </div>

        <div className="border border-[#E4E4F3] dark:border-none  bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <div>
              <h3 className="text-lg font-medium">
                Restaurant Banner (1440px by 576px)
              </h3>
            </div>
            <>
              <button className="text-primary" onClick={handleBannerClick}>
                + Upload Banner
              </button>
              <input
                type="file"
                hidden
                className="form-control"
                onChange={uploadMultipleFiles}
                ref={hiddenBannerInput}
                multiple
              />
            </>
          </div>

          <div className="mt-8">
            {bannersUrl?.length > 0 ? (
              <div className="grid grid-cols-1 gap-8">
                {bannersUrl.map((url, index) => (
                  <figure className="w-full h-[250px] relative" key={index}>
                    <img
                      src={url}
                      className="object-cover w-full h-[250px]"
                      alt="banner"
                    />
                  </figure>
                ))}
              </div>
            ) : restaurant_banners?.length > 0 ? (
              <div className="grid grid-cols-1 gap-8">
                {restaurant_banners.map((banner, index) => (
                  <figure className="w-full h-[250px] relative" key={index}>
                    <img
                      src={banner}
                      className="object-cover w-full h-[250px]"
                      alt="banner"
                    />
                  </figure>
                ))}
              </div>
            ) : (
              <p>No images</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getABusiness,
  approveABusiness,
  unApproveABusiness,
} from "../../../redux/business/businessActions";
// eslint-disable-next-line no-unused-vars
import BusinessTable from "./businessTable";
import { useParams } from "react-router-dom";
import { Switch } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function SingleBusiness() {
  const dispatch = useDispatch();
  const { business } = useSelector((state) => state.business);
  const { id } = useParams();

  const [approved, setApproved] = useState(false);
  const [unApproved, setUnApproved] = useState(true);

  const handleUnApprove = (e) => {
    dispatch(unApproveABusiness({ id: id }));
  };
  const handleApprove = (e) => {
    dispatch(approveABusiness({ id: id }));
  };
  useEffect(() => {
    dispatch(getABusiness(id));
  }, [dispatch, id]);

  return (
    <div>
      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212]  rounded-md py-5 px-8 mb-12">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium capitalize">Business Details</h3>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Name</label>
            <p className="text-[#151515] dark:text-white text-sm">{business?.business_name}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Email</label>
            <p className="text-[#151515] dark:text-white text-sm break-words">
              {business?.business_email}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Phone Number</label>
            <p className="text-[#151515] dark:text-white text-sm">{business?.business_phone}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Address</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {business?.business_address}
            </p>
          </div>
          <div>
            {business?.management_approved === false ? (
              <Switch.Group as="div">
                <span>
                  <Switch.Description
                    as="p"
                    className="text-sm mb-2 text-[#9CA3AF] dark:text-[#BCBCBC]"
                  >
                    Approve Business
                  </Switch.Description>
                </span>
                <Switch
                  checked={approved}
                  onChange={(e) => {
                    setApproved(e);
                    handleApprove(e);
                  }}
                  className={classNames(
                    approved ? "bg-primary" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      approved ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white dark:bg-[#121212] shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </Switch.Group>
            ) : (
              <Switch.Group as="div">
                <span>
                  <Switch.Description
                    as="p"
                    className="text-sm mb-2 text-[#9CA3AF] dark:text-[#BCBCBC]"
                  >
                    Unapprove Business
                  </Switch.Description>
                </span>
                <Switch
                  checked={unApproved}
                  onChange={(e) => {
                    handleUnApprove(e);
                    setUnApproved(e);
                  }}
                  className={classNames(
                    unApproved ? "bg-primary" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      unApproved ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white dark:bg-[#121212] shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </Switch.Group>
            )}
          </div>
        </div>
      </div>
      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212]  rounded-md py-5 px-8 mb-12">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium capitalize">
            Business Owner Details
          </h3>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Name</label>
            <p className="text-[#151515] dark:text-white text-sm capitalize">
              {business?.user?.name}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Email</label>
            <p className="text-[#151515] dark:text-white text-sm break-words">
              {business?.user?.email}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Phone Number</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {business?.user?.phone ? business?.user?.phone : "Not available"}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Address</label>
            <p className="text-[#151515] dark:text-white text-sm">Not Available</p>
          </div>
        </div>
      </div>
      <BusinessTable business={business} />
    </div>
  );
}

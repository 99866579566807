import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Button from "../../../components/buttons/buttons";
import Input from "./../../../components/inputs/inputs";
import SearchableDropdown from "../../../helpers/searchableDropDown";
import { cleanInput } from "./../../../helpers/cleanInput";
import { Beat } from "../../../plugins/spinners.plugin";
import { getAllStores } from "../../../redux/stores/storeActions";
import { getAllRestaurantsAction } from "./../../../redux/restaurant/restaurantActions";
import { getAllUsersAction } from "./../../../redux/users/userActions";
import { createCoupon } from "../../../redux/coupons/couponAction";

export default function CreateCoupon() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allUsers } = useSelector((state) => state.user);
  const { allStores } = useSelector((state) => state.store);
  const { allRestaurants } = useSelector((state) => state.restaurant);
  const { loading } = useSelector((state) => state.coupon);

  let today = new Date().toISOString().split("T")[0];
  document.getElementsByName("expiry")[0]?.setAttribute("min", today);

  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState({
    slug: "",
    amount: "",
    quantity: "1",
    restaurant_id: "",
    store_id: "",
    min_order_amount_cap: "",
    title: "",
    user_id: "",
    expiry: "",
  });
  const { slug, amount, quantity, expiry, title, min_order_amount_cap } = data;
  const userData = allUsers?.data;
  const storeData = allStores?.data;
  const restaurantData = allRestaurants?.data;

  useEffect(() => {
    dispatch(getAllRestaurantsAction());
    dispatch(getAllStores());
    dispatch(getAllUsersAction());
    // eslint-disable-next-line
  }, []);
  const selectRestaurantOption = (option) => {
    const value = option?.id;
    setData({ ...data, restaurant_id: value });
  };
  const selectStoreOption = (option) => {
    const value = option?.id;
    setData({ ...data, store_id: value });
  };
  const selectUserOption = (option) => {
    const value = option?.id;
    setData({ ...data, user_id: value });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setData({ ...data, [name]: value });
  };
  const handleSubmit = async () => {
    cleanInput(data);
    setSubmitted(true);
    if (
      slug &&
      amount &&
      quantity &&
      expiry &&
      title &&
      min_order_amount_cap >= 100
    ) {
      const response = await dispatch(createCoupon({ ...data }));
      if (response.status === "success") {
        navigate("/admin/coupons/active/1");
      }
    }
  };

  return (
    <>
      <div className=" ml-52">
        <div className=" mx-auto bg-white dark:bg-[#121212] sm:w-[630px] w-full mt-10 sm:ml-10 sm:mt-0 sm:border px-6 sm:border-gray-200 dark:border-none">
          <div className="border-b-2 py-4 mb-6">
            <p className="text-base font-medium text-gray-800 dark:text-white flex justify-center ">
              Create Coupon
            </p>
          </div>
          <form>
            <div className=" sm:px-10">
              <div>
                <Input.Label
                  title={"Slug*"}
                  htmlFor={"slug"}
                  name={"slug"}
                  placeholder={"Enter a slug for coupon code"}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {submitted && !slug && (
                  <div className="text-primary text-xs ">Slug is required</div>
                )}
              </div>
              <div>
                <Input.Label
                  title={"Title*"}
                  htmlFor={"title"}
                  name={"title"}
                  placeholder={"Enter Coupon name"}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {submitted && !title && (
                  <div className="text-primary text-xs ">Name is required</div>
                )}
              </div>
              <div className="mt-4">
                <Input.Label
                  title={"Amount*"}
                  htmlFor={"amount"}
                  name={"amount"}
                  placeholder={"Price including shipping"}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {submitted && !amount && (
                  <div className="text-primary text-xs ">
                    Amount is required
                  </div>
                )}
              </div>
              <div className="mt-4">
                <Input.Number
                  title={"Order Cap"}
                  htmlFor="min_order_amount_cap"
                  name="min_order_amount_cap"
                  min={100}
                  autoComplete="off"
                  placeholder={"Minimum amount before coupon can be used"}
                  onChange={handleChange}
                />
                {submitted && min_order_amount_cap < 100 && (
                  <div className="text-primary text-xs ">
                    Order cap cannot be less than 100
                  </div>
                )}
              </div>
              <div className="mt-4">
                <Input.DatePicker
                  title={"Expiry date*"}
                  htmlFor={"expiry"}
                  name={"expiry"}
                  selected={expiry}
                  onChange={(event) => {
                    setData({
                      ...data,
                      expiry: moment(event.target.value).format("YYYY-MM-DD"),
                    });
                  }}
                />
                {submitted && !expiry && (
                  <div className="text-primary text-xs ">
                    Expiry date is required
                  </div>
                )}
              </div>
              <div className="">
                <label
                  htmlFor="store_id"
                  className="text-sm font-medium text-[#151515] dark:text-white"
                >
                  Attach User(optional)
                </label>
                <SearchableDropdown
                  options={userData}
                  label="name"
                  name={"user_id"}
                  placeholder={"Select a user"}
                  selectOption={selectUserOption}
                />
              </div>
              <div className="mt-4">
                <label
                  htmlFor="store_id"
                  className="text-sm font-medium text-[#151515] dark:text-white"
                >
                  Attach Restaurant(optional)
                </label>
                <SearchableDropdown
                  options={restaurantData}
                  label="restaurant_name"
                  name={"restaurant_id"}
                  placeholder={"Select a restaurant"}
                  selectOption={selectRestaurantOption}
                />
              </div>
              <div className="mt-4">
                <label
                  htmlFor="store_id"
                  className="text-sm font-medium text-[#151515] dark:text-white"
                >
                  Attach Store(optional)
                </label>
                <SearchableDropdown
                  options={storeData}
                  label="store_name"
                  name={"store_id"}
                  placeholder={"Select a store"}
                  selectOption={selectStoreOption}
                />
              </div>
              <div className="mt-4 mb-12">
                <Input.Number
                  title={"Quantity*"}
                  htmlFor="quantity"
                  name="quantity"
                  placeholder={"E.g: 1"}
                  defaultValue={quantity}
                  onChange={handleChange}
                  min={0}
                />
                {submitted && (!quantity || quantity < 1) && (
                  <div className="text-primary text-xs ">
                    Quantity is required and quantity cannot be less than 1
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
        <div>
          <Button.Primary
            disabled={loading}
            title={loading ? <Beat /> : "Create Coupon"}
            className={"w-[135px] sm:mt-10 sm:ml-72 ml-36 "}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
}

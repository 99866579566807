import React, { useMemo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../../../../components/buttons/buttons";
import Table from "../../../../../components/tables/tableCols2";
import TableFilter from '../../../../../components/tables/tableFilter';
import Copy from "../../../../../assets/images/icons/copy-outline.svg";
import TableSkeleton from "../../../../../components/skeleton/table";
import Orders from "../../../../../assets/images/icons/ordersEmpty.svg";
import { Pagination } from "../../../../../components/pagination/pagination";
import { AllFiltersModal } from "../../../../../components/modals/allFiltersModal";
import FilterStoreOrdersDropDown from "../../../../../components/dropDown/filter/storeOrderFilter";
import { writeFileWithXLSX } from "../../../../../components/exports/xlsx";
import { getOutletFromCookies } from "../../../../../utils/Auth";
import {
  filterStoreOrders,
  searchAllStoreOrders,
  getAllStoreOrdersNoPage,
} from "../../../../../redux/storeOrders/storeOrderAction";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function ClosedStoreOrders() {
  const outlet = getOutletFromCookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // let lastMonth = new Date();
  // lastMonth.setDate(lastMonth.getDate() - 30);
  const { allStoreOrders, filtering, search, searching, allStoreOrdersNoPage } =
    useSelector((state) => state.storeOrder);

  const [isFilterOpen, setFilterState] = useState(false);
  const [payLoad] = useState({
    store_id: outlet.id,
    status: "closed",
    // start_date: lastMonth,
    end_date: new Date(),
  });
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });

  let { page } = useParams();

  useEffect(() => {
    dispatch(filterStoreOrders(payLoad, page));
    dispatch(getAllStoreOrdersNoPage(payLoad));
  }, [dispatch, payLoad, page]);

  //to copy text to clipboard
  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand("copy", true, text);
    }
    toast.success("Copied", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  /**
   * Truncate transaction ref and add ellipsis in middle
   *
   * @param {string} string
   * @param {number} lengthToShow
   * @param {string} seperator
   * @returns {string}
   */
  const truncateText = (string, lengthToShow = 25, separator = "...") => {
    if (string?.length <= lengthToShow) return string;

    const sepLen = separator?.length;
    const charsToShow = lengthToShow - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return (
      string?.substr(0, frontChars) +
      separator +
      string?.substr(string.length - backChars)
    );
  };
  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order, index) => {
        let single = {
          created: (
            <span>{moment(order?.created_at).format("MMM DD, YYYY")}</span>
          ),
          id: (
            <div className="flex ">
              {truncateText(order.id)}
              <img
                className="ml-2"
                src={Copy}
                alt="copy-icon"
                onClick={() => copyTextToClipboard(order.id)}
              />
            </div>
          ),
          amount: order?.order_amount,
          payment:
            order?.order_paid === true ? (
              <p className="font-bold text-sm text-green-500">Paid</p>
            ) : (
              <p className="font-bold text-sm text-primary">Not Paid</p>
            ),
          delivery_fee: numberWithCommas(order?.order_delivery_fee),
          order_status: (
            <div className="font-bold text-primary">{order?.status}</div>
          ),
          order: order,
        };

        outputArray.push({ single });
        return true;
      });

      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allStoreOrders?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStoreOrders]);

  function createTableNoPage(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order, index) => {
        let single = {
          created: order?.created_at,
          id: order.id,
          name: order?.recipient_name,
          phone: order?.recipient_phone,
          payment: order?.order_paid === true ? "true" : "false",
          delivery_fee: order?.order_delivery_fee,
          order_status: order?.status,
        };

        outputArray.push(single);
        return true;
      });

      return outputArray;
    }
  }

  const goToSinglePage = (params) => {
    let id = params?.single?.order?.id;
    navigate(`/admin/${outlet.store_name}/orders/${id}`, {
      state: { order: params?.single?.order },
    });
  };
  const downloadCSV = () => {
    if (allStoreOrdersNoPage) {
      const newData = createTableNoPage(allStoreOrdersNoPage);
      writeFileWithXLSX(newData, {
        filename: "Active stores.xlsx",
        download: true,
      });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const showSearch = () => {
    if (input?.search?.length > 2) {
      let dataSet = createTableData(search);
      setData(dataSet);
    }
  };
  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allStoreOrders);
      setData(dataSet);
    }
  };

  useEffect(() => {
    if (input?.search?.length > 2) {
      async function doSearch() {
        try {
          let response = await dispatch(searchAllStoreOrders(input));
          if (response?.status === "success") {
            showSearch();
          }
        } catch (error) {}
      }
      doSearch();
    } else if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  function goToCreate() {
    navigate(`/admin/${outlet?.store_name}/order/store/create-order`);
  }

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "Order ID",
            accessor: "single.id",
          },
          {
            Header: "Date",
            accessor: "single.created",
          },
          {
            Header: "Amount",
            accessor: "single.amount",
          },
          {
            Header: "Delivery Fee",
            accessor: "single.delivery_fee",
          },
          {
            Header: "Payment Status",
            accessor: "single.payment",
          },
          {
            Header: "Orders Status",
            accessor: "single.order_status",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="stores-page">
      <TableFilter
        title={"Orders"}
        results={allStoreOrders?.to}
        totalResults={allStoreOrders?.total}
        onChange={handleChange}
        searchPlaceholder={"Order ID or Phone Number"}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterStoreOrdersDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
      >
        <Button.Primary title={"+ Create Order"} onClick={goToCreate} />
        <Button.Secondary
          title={"Download CSV"}
          onClick={() => downloadCSV()}
          disabled={!data}
        />
      </TableFilter>

      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white dark:bg-[#121212] rounded-md py-4">
        {filtering || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        ) : (
          <div className="text-center">
            {" "}
            <div className="flex justify-center my-12 ">
              <img src={Orders} alt="orders" />
            </div>
            <p className="font-bold text-xl my-3">Orders</p>
            <p className="text-sm text-gray-500">
              You do not have any closed Orders yet
            </p>
          </div>
        )}
        <Pagination
          data={allStoreOrders}
          route={`admin/${outlet?.store_name}/orders/closed`}
        />
      </div>
    </div>
  );
}

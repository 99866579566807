import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { MENU } from "../../assets/images/pictures/images.js";
import Category from "../../assets/images/emptyState/category-list.jpg";
import Restaurant from "../../assets/images/emptyState/restaurant-list.jpg";
import REFRESH from "../../assets/images/icons/refresh.svg";

function isBusinessHours(operating_hours) {
  const daysOfWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  // Get today's date and time
  const currentDatetime = new Date();
  const currentDay = daysOfWeek[currentDatetime.getDay()];
  const currentHour = currentDatetime.getHours();
  const currentMinute = currentDatetime.getMinutes();

  // Check if current time is within the operating hours for the current day
  if (operating_hours?.hasOwnProperty(currentDay)) {
    const { open, close } = operating_hours[currentDay];
    const [openHour, openMinute] = open.split(":").map(Number);
    const [closeHour, closeMinute] = close.split(":").map(Number);

    const opensBeforeCurrentTime =
      currentHour > openHour ||
      (currentHour === openHour && currentMinute >= openMinute);
    const closesAfterCurrentTime =
      currentHour < closeHour ||
      (currentHour === closeHour && currentMinute <= closeMinute);

    return opensBeforeCurrentTime && closesAfterCurrentTime;
  } else {
    return false;
  }
}

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Card = ({ children }) => {
  return (
    <div className="card" data-test="cardComponent">
      {children}
    </div>
  );
};

Card.Restaurants = ({
  title,
  tags,
  image,
  id,
  className,
  categories,
  operating_settings,
  operating_status,
  operating_hours,
}) => {
  const onImageError = (e) => {
    e.target.src = Restaurant;
  };

  return (
    <div
      className={
        "w-full sm:w-full h-full bg-white rounded-lg border rounded-b-xl cursor-pointer relative overflow-hidden" +
        className
      }
    >
      <Link to={`/restaurant/${id}`} className="h-full block">
        <div className="relative">
          <img
            className="rounded-t-lg w-full h-[200px] object-cover"
            src={image ?? Restaurant}
            onError={onImageError}
            alt="restaurant"
          />

          {(operating_status === "open" &&
            operating_settings === "available" &&
            operating_hours === null) ||
          (operating_status === "open" &&
            operating_settings === "available" &&
            operating_hours !== null &&
            isBusinessHours(operating_hours)) ? (
            ""
          ) : (
            <div className="absolute top-0 w-full h-full bg-gradient-to-b from-transparent to-black flex items-center z-10 rounded-t-lg">
              <p className="text-xl text-center font-bold text-white w-full">
                Closed
              </p>
            </div>
          )}
        </div>

        {/* {categories?.length > 0 && (
          <div className="absolute top-6 left-6 w-auto rounded-lg inline-flex items-center bg-white p-2 shadow-md">
            <span className="text-[#4D4C4C] text-sm font-bold">
              {categories?.length > 0 && categories[0]}
            </span>
          </div>
        )} */}

        <div className="p-5 bg-white rounded-b-xl">
          <h5 className="mb-2 text-lg font-bold tracking-tight text-black">
            {title ?? "Title"}
          </h5>

          <div className="flex gap-2 overflow-x-auto scroll-smooth max-w-[300px]">
            {tags?.length > 0 &&
              tags.map((tag, index) => (
                <p
                  key={index}
                  className="flex-none bg-[#F6F3F3] rounded-md px-[10px] py-[4px] text-center text-[#4D4C4C] text-sm"
                >
                  {tag}
                </p>
              ))}
          </div>
        </div>
      </Link>
    </div>
  );
};

Card.StoreCategories = ({ category, image }) => {
  const onImageError = (e) => {
    e.target.src = Category;
  };
  return (
    <Link to={`/user/store-category/${category?.name}`}>
      <div
        className={
          "w-full bg-white rounded-lg border border-gray-200 shadow-md"
        }
      >
        <div>
          <img
            className="w-full h-[200px] object-cover rounded-lg"
            src={category?.picture ?? Category}
            onError={onImageError}
            alt="categories"
          />
        </div>
      </div>
      <div className="my-4">
        <p className=" text-lg font-semibold">{category?.name ?? "category"}</p>
        {/* <p className=" text-sm text-[#FF725E] font-medium">
          {category?.found ?? "0"} found
        </p> */}
      </div>
    </Link>
  );
};

Card.Store = ({
  title,
  tags,
  id,
  image,
  categories,
  className,
  imageFallBack,
  operating_settings,
  operating_status,
  operating_hours,
}) => {
  const onImageError = (e) => {
    e.target.src = Restaurant;
  };
  return (
    <div
      className={
        "w-full sm:w-full bg-white rounded-lg border rounded-b-xl cursor-pointer relative overflow-hidden mb-2" +
        className
      }
    >
      <Link to={`/store/${id}`} className="h-full block">
        <div className="relative">
          <img
            className="rounded-t-lg w-full h-[200px] object-cover"
            src={image ?? Restaurant}
            onError={onImageError}
            alt="store"
          />
          {(operating_status === "open" &&
            operating_settings === "available" &&
            operating_hours === null) ||
          (operating_status === "open" &&
            operating_settings === "available" &&
            operating_hours !== null &&
            isBusinessHours(operating_hours)) ? (
            ""
          ) : (
            <div className="absolute top-0 w-full h-full bg-gradient-to-b from-transparent to-black flex items-center z-10 rounded-t-lg">
              <p className="text-xl text-center font-bold text-white w-full">
                Closed
              </p>
            </div>
          )}
        </div>

        {/* {categories?.length > 0 && (
          <div className="absolute top-6 left-6 w-auto rounded-lg inline-flex items-center bg-white p-2 shadow-md">
            <span className="text-[#4D4C4C] text-sm font-bold">
              {categories?.length > 0 && categories[0]}
            </span>
          </div>
        )} */}

        <div className="p-5 bg-white rounded-b-xl">
          <h5 className="mb-2 text-lg font-bold tracking-tight text-black">
            {title ?? "Title"}
          </h5>

          <div className="flex gap-2 overflow-x-auto scroll-smooth max-w-[300px]">
            {tags?.length > 0 &&
              tags.map((tag, index) => (
                <p
                  key={index}
                  className="flex-none bg-[#F6F3F3] rounded-md px-[10px] py-[4px] text-center text-[#4D4C4C] text-sm"
                >
                  {tag}
                </p>
              ))}
          </div>
        </div>
      </Link>
    </div>
  );
};

Card.Categories = ({ category }) => {
  const onImageError = (e) => {
    e.target.src = Category;
  };
  return (
    <Link to={`/user/restaurant-category/${category?.name}`}>
      <div
        className={
          "w-full bg-white rounded-lg border border-gray-200 shadow-md"
        }
      >
        <div>
          <img
            className="w-full h-[160px] sm:h-[200px] object-cover rounded-lg"
            src={category?.picture ?? Category}
            onError={onImageError}
            alt="categories"
          />
        </div>
      </div>
      <div className="my-4">
        <p className=" text-lg font-semibold">{category?.name ?? "category"}</p>
        {/* <p className=" text-sm text-[#FF725E] font-medium">
          {category?.found ?? "0"} found
        </p> */}
      </div>
    </Link>
  );
};

Card.Menu = ({ title, image, className, amount, id, tags }) => {
  const onImageError = (e) => {
    e.target.src = Restaurant;
  };
  return (
    <div
      className={
        "w-full sm:w-full bg-white rounded-lg relative mb-2 flex" + className
      }
    >
      <Link to={`/store/${id}`}>
        <img
          className="rounded-t-lg w-full h-[200px] object-cover"
          src={image ?? MENU}
          onError={onImageError}
          alt="food"
        />

        {/* {tags?.length > 0 && (
          <div className="absolute top-6 left-6 w-auto rounded-lg inline-flex items-center bg-white p-2 shadow-md">
            <span className="text-[#4D4C4C] text-sm font-bold">
              {tags?.length > 0 && tags[0]}
            </span>
          </div>
        )} */}
        <div className="p-5 border bg-white rounded-b-xl">
          <div className="flex justify-between">
            <p className="mb-2 text-lg font-bold tracking-tight text-black">
              {title ?? "Title"}
            </p>
            <p className="text-lg font-meduim"> {amount ?? 0}</p>
          </div>
          {/* <div className="text-[#333333] text-sm font-normal tracking-tight mb-3">
          <p>{outlet ?? "outlet"}</p>
        </div> */}
        </div>
      </Link>
    </div>
  );
};

Card.MenuRestaurant = ({ title, image, className, amount, id, tags }) => {
  const onImageError = (e) => {
    e.target.src = Restaurant;
  };
  return (
    <div
      className={
        "w-full sm:w-full bg-white rounded-lg mb-2 relative flex" + className
      }
    >
      <Link to={`/restaurant/${id}`}>
        <img
          className="rounded-t-lg w-full h-[200px] object-cover"
          src={image ?? MENU}
          onError={onImageError}
          alt="food"
        />

        {/* {tags?.length > 0 && (
          <div className="absolute top-6 left-6 w-auto rounded-lg inline-flex items-center bg-white p-2 shadow-md">
            <span className="text-[#4D4C4C] text-sm font-bold">
              {tags?.length > 0 && tags[0]}
            </span>
          </div>
        )} */}
        <div className="p-5 border bg-white rounded-b-xl">
          <div className="flex justify-between">
            <p className="mb-2 text-lg font-bold tracking-tight text-black">
              {title ?? "Title"}
            </p>
            <p className="text-lg font-meduim">{amount ?? 0}</p>
          </div>
          {/* <div className="text-[#333333] text-sm font-normal tracking-tight mb-3">
          <p>{outlet ?? "outlet"}</p>
        </div> */}
        </div>
      </Link>
    </div>
  );
};

Card.OrderStatus = ({ order }) => {
  let foodPreparationTime = order?.foods?.[0]?.food_preparation_time;
  let extraMinutes = 40;
  const [prepHours, prepMinutes] = (foodPreparationTime || "00:00")
    ?.split(":")
    ?.map(Number);
  const totalPrepMinutes = prepHours * 60 + prepMinutes;
  return (
    <div className="border border-[#E4E4F3] bg-white dark:bg-[#121212] rounded-lg shadow-2xl p-6 w-96 sm:w-[617px]">
      <div>
        <p className="text-gray-500 dark:text-[#BCBCBC] text-base font-normal">
          Order #{order?.id}
        </p>
        <p className=" text-lg sm:text-xl font-medium">
          We have recieved your order
        </p>
        <div className="flex mt-6 mb-6">
          <img src={REFRESH} alt="refresh icon" className="mr-2" />
          <p className="text-primary text-lg">{order?.status}</p>
        </div>

        <p className="text-gray-500 dark:text-[#BCBCBC] text-base font-normal">
          Estimated delivery:
        </p>
        <div className="flex justify-between text-lg sm:text-xl font-medium mb-3">
          <p>
            {extraMinutes}-{Math.round(totalPrepMinutes + extraMinutes)} mins
          </p>
          <p>
            {moment(order?.created_at).format("HH:mm")} -
            {moment(order?.created_at)
              .add(totalPrepMinutes + extraMinutes, "minutes")
              .format("HH:mm")}
          </p>
        </div>
        <div className="border-b-2"></div>
        <div className="py-4">
          <p className="text-gray-500 dark:text-[#BCBCBC] text-base font-normal mt-1">
            To:
          </p>
          <p className="sm:text-xl text-lg font-normal ">
            {order?.recipient_address}
          </p>
        </div>
      </div>
    </div>
  );
};

Card.OrderDetails = ({ order }) => {
  return (
    <div className="w-96 sm:w-[617px]">
      <div className="border border-[#E4E4F3] bg-white dark:bg-[#121212] rounded-md py-5 px-4 sm:px-8 mt-6">
        <div className="border-b border-[#E4E4F3] py-3 mb-4 flex justify-between items-center">
          <h3 className="text-lg font-medium">Order Details</h3>
        </div>

        {order?.order_items?.length > 0 &&
          order?.order_items?.map((product, i) => (
            <div key={i}>
              <div className="flex justify-between items-start mb-5">
                <div className="flex">
                  <span className="w-6 mr-3 sm:mr-10">{product.quantity}x</span>
                  <div>
                    <p className="mb-1">{product?.product_name}</p>
                    {product?.product_attributes?.length > 0 &&
                      product?.product_attributes?.map((attr, i) => (
                        <p key={i} className="text-sm text-[#676565]">
                          {attr.quantity}x {attr?.name} - NGN {attr.amount}
                        </p>
                      ))}
                  </div>
                </div>
                <p>NGN {product?.product_amount}</p>
              </div>
            </div>
          ))}
      </div>

      <div className="border border-[#E4E4F3] bg-white dark:bg-[#121212] rounded-md py-5 px-4 sm:px-8 mt-6">
        <div className="flex justify-between items-center py-2">
          <span>Subtotal</span>
          <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
            ₦{numberWithCommas(order?.order_sub_amount)}
          </span>
        </div>
        <div className="flex justify-between items-center py-2">
          <span>Delivery fee</span>
          <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
            ₦{numberWithCommas(order?.order_delivery_fee)}
          </span>
        </div>
        <div className="flex justify-between items-center py-2">
          <span>Service fee</span>
          <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
            ₦{numberWithCommas(order?.order_service_fee)}
          </span>
        </div>
        <div className="flex justify-between items-center py-2">
          <span>Insurance fee</span>
          <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
            ₦{numberWithCommas(order?.order_insurance_fee)}
          </span>
        </div>
        <div className="flex justify-between items-center py-2">
          <span>Tax fee</span>
          <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
            ₦{numberWithCommas(order?.order_tax_fee)}
          </span>
        </div>
        <div className="flex justify-between items-center py-2">
          <span>Discount</span>
          <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
            ₦{numberWithCommas(order?.order_discount)}
          </span>
        </div>
        <div className="flex justify-between items-center py-2 border-t-2 border-[#EAEAEA]">
          <span>Total</span>
          <span className="font-bold text-[#4D4D4D] dark:text-[#BCBCBC]">
            ₦{numberWithCommas(order?.order_amount)}
          </span>
        </div>
      </div>
    </div>
  );
};

Card.FoodPackage = ({ data, className }) => {
  const onImageError = (e) => {
    e.target.src = Restaurant;
  };
  return (
    <div
      className={
        "w-full rounded-lg cursor-pointer relative overflow-hidden" + className
      }
    >
      <Link to={`/food-package/customise/${data?.id}`}>
        <img
          className="rounded-t-lg w-full h-48  object-cover"
          src={
            data?.package_pictures?.length > 0
              ? data?.package_pictures?.[0]
              : Restaurant
          }
          onError={onImageError}
          alt="food-package"
        />

        <div className="p-5 border bg-white rounded-b-xl">
          <h5 className="mb-2 text-xl font-bold tracking-tight text-black">
            {data?.package_name}
          </h5>

          <div className="">
            <p className="mb-0 text-sm">
              Type: <span className="ml-1">Food Package</span>
            </p>
            <p className="mb-0 text-sm text-black">
              Expires:{" "}
              <span className="text-primary-500 font-bold ml-1">
                {moment(data?.package_end_date).format("DD/MM/YYYY")}
              </span>
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Card;

import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Beat } from "../../plugins/spinners.plugin";
import Button from "../buttons/buttons";
import { ReactComponent as Close } from "../../assets/images/icons/close.svg";
import { getOutletTags } from "../../redux/productTags/productTagActions";

export function FilterPackageModal(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const [tags, setTags] = useState([]);
  const [tagsArr, setTagsArr] = useState([]);
  const [categoryArr, setCategoryArr] = useState([]);
  const [data, setData] = useState({
    package_categories: [],
    package_tags: [],
  });
  const { package_categories, package_tags } = data;
  const { allTags } = useSelector((state) => state.productTag);
  const { isLoading } = useSelector((state) => state.publicRestaurant);

  useEffect(() => {
    dispatch(getOutletTags(1));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let items = allTags?.data?.filter((item) => item.type === "restaurant");
    setTags(items);
  }, [allTags]);

  const handleTags = (tag, i) => {
    let arr = [];
    let newArr = [];
    let tagIndex = [...tagsArr];
    let newtagIndex = [];
    if (data?.package_tags) {
      arr = [...data.package_tags];
      if (arr.includes(tag?.name)) {
        newArr = arr.filter((name) => name !== tag.name);
      } else {
        newArr = [...arr, tag.name];
      }
      setData({
        ...data,
        package_tags: newArr,
      });
      // track the index of items added and use their index to indicate as selected
      if (tagIndex.includes(i)) {
        newtagIndex = tagIndex.filter((index) => index !== i);
      } else {
        newtagIndex = [...tagIndex, i];
      }
      setTagsArr(newtagIndex);
    } else {
      let new_tags = [...arr, tag?.name];
      setData({ ...data, package_tags: new_tags });
      newtagIndex = [...tagIndex, i];
      setTagsArr(newtagIndex);
    }
  };

  const handleCategories = (category, i) => {
    let arr = [];
    let newArr = [];
    let catIndex = [...categoryArr];
    let newcatIndex = [];
    if (data?.package_categories) {
      arr = [...data?.package_categories];
      if (arr.includes(category?.name)) {
        newArr = arr.filter((name) => name !== category.name);
      } else {
        newArr = [...arr, category.name];
      }
      setData({
        ...data,
        package_categories: newArr,
      });
      // track the index of items added and use their index to indicate as selected
      if (catIndex.includes(i)) {
        newcatIndex = catIndex.filter((index) => index !== i);
      } else {
        newcatIndex = [...catIndex, i];
      }
      setCategoryArr(newcatIndex);
    } else {
      let new_tags = [...arr, category?.name];
      setData({ ...data, package_categories: new_tags });
      newcatIndex = [...catIndex, i];
      setCategoryArr(newcatIndex);
    }
  };

  const handleClear = () => {
    setData({
      ...data,
      package_tags: [],
      package_categories: [],
    });
    setTagsArr([]);
    setCategoryArr([]);
    props.setFilterCount(0);
    props.onClose();
  };

  return (
    <div data-test="filterModal">
      <Transition show={props.show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-[600px] bg-white dark:bg-[#121212] rounded-3xl px-4 pt-5 pb-4  overflow-hidden shadow-xl transform transition-all sm:my-10 sm:max-w-lg sm:w-full  sm:p-8">
                  <div className=" sm:block absolute top-1 sm:top-3 right-5 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-[#ffeae5] dark:bg-[#CE2600] rounded-md sm:p-3 p-2"
                      onClick={props.onClose}
                    >
                      <span className="sr-only">Close</span>
                      <Close className="w-3 h-3 sm:w-4 sm:h-4 white-icon" />
                    </button>
                  </div>
                  <div className="text-center sm:text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-xl mb-4 font-bold text-black dark:text-white"
                    >
                      Filter
                    </Dialog.Title>
                  </div>
                  <div>
                    <div className="border-b-2"></div>
                    <h3 className=" text-lg font-medium text-left sm:text-left mt-6 ">
                      Sort by Tags
                    </h3>

                    <div className="flex flex-wrap gap-3 mt-3">
                      {tags?.length > 0 &&
                        tags.map((tag, i) => (
                          <button
                            key={i}
                            className={`${
                              tagsArr?.includes(i)
                                ? "bg-primary text-white"
                                : "bg-[#F6F3F3] text-[#4D4C4C]"
                            }  rounded-md px-3 py-2 text-center text-sm cursor-pointer`}
                            onClick={() => handleTags(tag, i)}
                          >
                            {tag.name}
                          </button>
                        ))}
                    </div>

                    {!params["*"].includes("category") && (
                      <>
                        <h3 className=" text-lg font-medium text-left sm:text-left mt-12 ">
                          Sort by Categories
                        </h3>
                        <div className="flex flex-wrap gap-3 mt-3">
                          {props?.categories?.length > 0 &&
                            props?.categories?.map((category, i) => (
                              <button
                                key={i}
                                className={`${
                                  data?.package_categories?.includes(
                                    category?.name
                                  )
                                    ? "bg-primary text-white"
                                    : "bg-[#F6F3F3] text-[#4D4C4C]"
                                } rounded-md px-3 py-2 text-center text-sm cursor-pointer`}
                                onClick={() => handleCategories(category, i)}
                              >
                                {category?.name}
                              </button>
                            ))}
                        </div>
                      </>
                    )}

                    <div className="flex justify-between mt-32">
                      <button
                        className="bg-white text-primary w-48 h-12 sm:w-60 sm:mr-4 border border-primary rounded-lg"
                        type="button"
                        onClick={handleClear}
                      >
                        Clear All
                      </button>
                      <Button.Primary
                        className="bg-primary text-white w-48 h-12 sm:w-60 rounded-lg"
                        type="button"
                        title={
                          isLoading ? (
                            <Beat color={"#ffffff"} />
                          ) : (
                            "Apply Filter"
                          )
                        }
                        disabled={
                          package_tags?.length + package_categories?.length < 1
                        }
                        onClick={() => {
                          props.setFilterCount(
                            package_tags?.length + package_categories?.length
                          );
                          props.onClick(data);
                          props.onClose();
                        }}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// images
import { ReactComponent as UserProfile } from "../../../assets/svgIcons/profile.svg";
import { ReactComponent as Location } from "../../../assets/svgIcons/location.svg";
import { ReactComponent as Orders } from "../../../assets/svgIcons/cart.svg";
import { ReactComponent as Referral } from "../../../assets/svgIcons/referral.svg";
import { ReactComponent as Invite } from "../../../assets/svgIcons/invite.svg";
import { ReactComponent as Wallet } from "../../../assets/svgIcons/wallet.svg";
import { ReactComponent as Badge } from "../../../assets/svgIcons/badge.svg";
import { ReactComponent as Palette } from "../../../assets/svgIcons/palette.svg";
import { ReactComponent as Key } from "../../../assets/svgIcons/key.svg";
import { ReactComponent as Delete } from "../../../assets/svgIcons/delete.svg";
import Copy from "../../../assets/images/icons/copy.svg";
import { EMPTY } from "../../../assets/images/pictures/images";
// import INVITE from "../../../assets/images/icons/profile.svg";
// import ORDERS from "../../../assets/images/icons/orders.svg";
// import REFERALS from "../../../assets/images/icons/referrals.svg";
// import LOCATION from "../../../assets/images/icons/Location.svg";
// import PASSWORD from "../../../assets/images/icons/password.svg";
// import DELETE from "../../../assets/images/icons/delete.svg";
// import WALLET from "../../../assets/images/icons/wallet.svg";
// import PROFILE from "../../../assets/images/icons/profile.svg";
// import Button from "../../../components/buttons/buttons";
import NavBarUser from "../../../components/navigation/NavBarUser";
import { copyTextToClipboard } from "../../../components/helpers";
import { getUserFromCookies } from "../../../utils/Auth";
import Container from "../../../components/container/container";
import MyAddresses from "./myAddresses";
import ResetPassword from "./ResetPassword";
import MyReferrals from "./myReferrals";
import MyInvites from "./invite/myInvites";
import DeleteUser from "./deleteAccount";
import MyOrders from "./orders";
import Personal from "./Personal";
import MyPayments from "./myPayments";
import KYCVerification from "./KYCVerification";
import Display from "./Display";

const navigation = [
  { name: "Profile", href: "info", icon: UserProfile },
  { name: "My Addresses", href: "addresses", icon: Location },
  { name: "My Orders", href: "orders", icon: Orders },
  { name: "My Referrals", href: "referrals", icon: Referral },
  { name: "My Invites", href: "invites", icon: Invite },
  { name: "My Payments", href: "payments", icon: Wallet },
  { name: "KYC Verification", href: "kyc-verification", icon: Badge },
  { name: "Change Password", href: "password", icon: Key },
  { name: "Display", href: "display", icon: Palette },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Profile() {
  const user = getUserFromCookies();
  const navigate = useNavigate();
  const { active_tab } = useParams();

  const { myProfile } = useSelector((state) => state.profile);

  useEffect(() => {
    if (!active_tab) {
      navigate(`/profile/info`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_tab]);

  /**
   * Truncate transaction ref and add ellipsis in middle
   *
   * @param {string} string
   * @param {number} lengthToShow
   * @param {string} seperator
   * @returns {string}
   */
  const truncateText = (string, lengthToShow = 20, separator = "...") => {
    if (string?.length <= lengthToShow) return string;

    const sepLen = separator?.length;
    const charsToShow = lengthToShow - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return (
      string?.substr(0, frontChars) +
      separator +
      string?.substr(string.length - backChars)
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onImageError = (e) => {
    e.target.src = EMPTY;
  };

  return (
    <div data-test="personalComponent">
      <NavBarUser />
      <Container>
        <div className="pt-4 sm:pt-2 sm:flex gap-8 sm:justify-between items-start mx-auto sm:my-12">
          <div className="hidden sm:block relative px-4 sm:w-[375px] w-full sm:rounded-md bg-white dark:bg-[#121212] sm:h-[1000px] shadow-2xl">
            <div className="flex flex-col items-center relative pt-16">
              <img
                src={myProfile?.picture ?? EMPTY}
                alt="profile"
                onError={onImageError}
                className=" border border-primary p-1 rounded-full w-[86px] h-[86px] sm:h-[86px] mb-4 sm:mb-2"
              />
              <p className=" text-2xl font-medium text-[#344867] dark:text-white mb-3 sm:mb-2 capitalize">
                {user?.name}
              </p>
              <p className=" text-base font-normal text-[#344867] dark:text-[#3D7DFD]">
                {user?.email}
              </p>
              <div className="flex mt-2">
                <p className=" text-base font-normal text-[#344867] dark:text-[#BCBCBC]">
                  ID: {truncateText(user?.id)}
                </p>
                <img
                  className="ml-2 w-6 h-6"
                  src={Copy}
                  alt="copy-icon"
                  onClick={() => copyTextToClipboard(user.id)}
                />
              </div>
            </div>
            <nav className="flex-1 space-y-1 px-2 mt-12">
              {navigation.map((item, i) => (
                <div
                  key={i}
                  className="border-b border-[#e5e7eb] dark:border-none mt-1 mb-2"
                >
                  <div
                    className={classNames(
                      item.href === active_tab
                        ? "text-primary"
                        : "text-[#344867] dark:text-[#BCBCBC] hover:text-primary",
                      "flex text-center gap-3 items-center my-2 px-2 py-2 cursor-pointer"
                    )}
                    onClick={() => navigate(`/profile/${item.href}`)}
                  >
                    <div className="bg-[#FFEAE5] dark:bg-[#CE2600] h-[2rem] w-[2rem] rounded-md flex items-center justify-center">
                      <item.icon className="w-5 h-5 white-icon" />
                    </div>
                    {/* <img src={item?.icon} alt="icon" className="mr-3 w-8 h-8" /> */}

                    <p className="text-sm hover:text-primary">{item.name}</p>
                  </div>
                </div>
              ))}
            </nav>

            <div className="border-b border-[#e5e7eb] dark:border-none px-2 mt-2 mb-32">
              <div
                className="flex text-center items-center gap-3 px-2 mb-3 mt-3 cursor-pointer"
                onClick={() => navigate(`/profile/delete`)}
              >
                <div className="bg-[#FFEAE5] dark:bg-[#CE2600] h-[2rem] w-[2rem] rounded-md flex items-center justify-center">
                  <Delete alt="icon" className="w-5 h-5 white-icon" />
                </div>
                <p className=" text-sm text-primary ">Delete Account</p>
              </div>
            </div>
          </div>

          {active_tab === "info" && <Personal />}
          {active_tab === "addresses" && <MyAddresses />}
          {active_tab === "orders" && <MyOrders />}
          {active_tab === "password" && <ResetPassword />}
          {active_tab === "referrals" && <MyReferrals />}
          {active_tab === "invites" && <MyInvites />}
          {active_tab === "delete" && <DeleteUser />}
          {active_tab === "payments" && <MyPayments />}
          {active_tab === "kyc-verification" && <KYCVerification />}
          {active_tab === "display" && <Display />}
        </div>
      </Container>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "@headlessui/react";
import Button from "../../../../../components/buttons/buttons";
import { ManageEmployeeRolesModal } from "../../../../../components/modals/roles/manageEmployeeRolesModal";
import {
  getSingleEmployee,
  approveEmployee,
  unApproveEmployee,
} from "../../../../../redux/employee/employeeActions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SingleEmployeeRole() {
  const dispatch = useDispatch();
  const location = useLocation();
  const employee = location.state.employee;
  const employee_id = employee.id;
  const { singleEmployee } = useSelector((state) => state.employee);

  const [approved, setApproved] = useState(false);
  const [unApproved, setUnApproved] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  // const { userRoles } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getSingleEmployee(employee_id));
  }, [dispatch, employee, employee_id]);

  useEffect(() => {
    if (singleEmployee?.user_id) {
      //  dispatch(showUserRolesAction(singleEmployee?.user_id));
    }
  }, [dispatch, singleEmployee?.user_id]);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  const handleApprove = (e) => {
    dispatch(approveEmployee(employee_id), () =>
      dispatch(getSingleEmployee(employee_id))
    );
  };
  const handleUnApprove = (e) => {
    dispatch(unApproveEmployee(employee_id), () =>
      dispatch(getSingleEmployee(employee_id))
    );
  };

  return (
    <div data-test="singleEmployee-page">
      <ManageEmployeeRolesModal
        show={isOpen}
        onClose={closeModal}
        employee_id={employee_id}
        user={singleEmployee?.user}
      />
      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-6">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Employee Details</h3>
        </div>

        <div className="grid  grid-cols-2 gap-6 sm:grid-cols-4 sm:gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-4">
              Employee Name
            </label>
            <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">
              {singleEmployee?.employee_name}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Employee Email
            </label>
            <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">
              {singleEmployee?.user?.email}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Phone Number
            </label>
            <p className="text-[#151515] dark:text-white text-sm font-bold mt-3">
              {singleEmployee?.employee_phone}
            </p>
          </div>
          <div>
            {singleEmployee?.business_approved === false ? (
              <Switch.Group as="div">
                <span>
                  <Switch.Description
                    as="p"
                    className="text-sm mb-2 text-[#9CA3AF] dark:text-[#BCBCBC]"
                  >
                    Approve Employee
                  </Switch.Description>
                </span>
                <Switch
                  checked={approved}
                  onChange={(e) => {
                    setApproved(e);
                    handleApprove(e);
                  }}
                  className={classNames(
                    approved ? "bg-primary" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      approved ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white dark:bg-[#121212] shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </Switch.Group>
            ) : (
              <Switch.Group as="div">
                <span>
                  <Switch.Description
                    as="p"
                    className="text-sm mb-2 text-[#9CA3AF] dark:text-[#BCBCBC]"
                  >
                    Unapprove Employee
                  </Switch.Description>
                </span>
                <Switch
                  checked={unApproved}
                  onChange={(e) => {
                    handleUnApprove(e);
                    setUnApproved(e);
                  }}
                  className={classNames(
                    unApproved ? "bg-primary" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      unApproved ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white dark:bg-[#121212] shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </Switch.Group>
            )}
          </div>
          <div className=" block sm:hidden">
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Gender
            </label>
            <p className="text-[#151515] dark:text-white text-sm  font-bold mt-3">
              {employee?.user?.gender}
            </p>
          </div>
          <div className=" block sm:hidden">
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Company Approved
            </label>
            {singleEmployee?.business_approved === true ? (
              <p className="text-sm text-green-500 font-bold  mt-3">Approved</p>
            ) : (
              <p className="text-red-600 text-sm font-bold  mt-3">Unapproved</p>
            )}
          </div>
          <div className=" block sm:hidden">
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Status
            </label>
            {singleEmployee?.status === "active" ? (
              <p className="text-sm text-green-500 font-bold mt-3">Active</p>
            ) : (
              <p className="text-primary text-sm font-bold mt-3">Inactive</p>
            )}
          </div>
          <div className=" block sm:hidden">
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Position
            </label>
            <p className="text-[#151515] dark:text-white text-sm font-bold mt-3">
              {singleEmployee?.employee_job_title}
            </p>
          </div>
          <div className=" block sm:hidden">
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Address
            </label>
            <p className="text-[#151515] dark:text-white text-sm font-bold mt-3">
              {singleEmployee?.employee_address}
            </p>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Employee History</h3>
        </div>

        <div className="grid grid-cols-2 gap-6 sm:grid-cols-4 sm:gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Company Approval{" "}
            </label>
            {singleEmployee?.business_approved === true ? (
              <p className="text-sm text-green-500 mt-3 font-bold">Approved</p>
            ) : (
              <p className="text-red-600 text-sm  mt-3 font-bold">Unapproved</p>
            )}
          </div>

          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Flag Count
            </label>
            <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">
              {singleEmployee?.flag}
            </p>
          </div>
          {/* <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Total Transactions
            </label>
            <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">12</p>
          </div> */}
          {/* <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Last Transaction Date
            </label>
            <p className="text-[#151515] dark:text-white text-sm mt-3 font-bold">Mar 8, 2020</p>
          </div> */}
        </div>
      </div>

      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Roles</h3>
          <Button.Primary
            type={"button"}
            title={"Manage Roles"}
            onClick={openModal}
          />
        </div>

        {singleEmployee?.user?.roles?.length > 0 ? (
          singleEmployee?.user?.roles?.map((role, index) => (
            <div key={index} className="grid grid-cols-2 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Role
                </label>
                <p className="text-[#151515] dark:text-white text-sm">
                  {role?.display_name}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
                  Description
                </label>
                <p className="text-[#151515] dark:text-white text-sm">
                  {role?.description}
                </p>
              </div>
            </div>
          ))
        ) : (
          <p className="font-medium py-4 text-center">No roles available</p>
        )}
      </div>
    </div>
  );
}

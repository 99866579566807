import React, { useMemo, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import Button from "../../../components/buttons/buttons";
import Table from "../../../components/tables/tableCols1";
import TableSkeleton from "../../../components/skeleton/table";
import TableFilter from "../../../components/tables/tableFilter";
import { Beat } from "../../../plugins/spinners.plugin";
import { PaginationNoRoute } from "../../../components/pagination/paginationNoRoute";
import EmptyState from "../../../assets/images/emptyState/orders.svg";
import FilterStoreDropDown from "../../../components/dropDown/filter/storeFilter";
import { AllFiltersModal } from "../../../components/modals/allFiltersModal";
import { copyTextFromTable } from "../../../components/helpers";
import { ReactComponent as COPY } from "../../../assets/images/icons/copy1.svg";
import { writeFileWithXLSX } from "../../../components/exports/xlsx";
import {
  filterStoreOrders,
  searchAllStoreOrders,
  getAllStoreOrdersNoPage,
} from "../../../redux/storeOrders/storeOrderAction";
import searchRestaurantType from "../../../redux/restaurantOrder/restaurantOrderTypes";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function StoreOrders() {
  const dispatch = useDispatch();
  let { id } = useParams();
  const [isFilterOpen, setFilterState] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });

  const { allStoreOrders, filtering, searching, downloading } = useSelector(
    (state) => state.storeOrder
  );

  const [payLoad] = useState({
    end_date: new Date(),
    store_id: id,
  });

  useEffect(() => {
    dispatch(filterStoreOrders({ ...payLoad, store_id: id }, page));
    // eslint-disable-next-line
  }, [page, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(
        searchAllStoreOrders({ ...input, store_id: id })
      );
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const showSearch = (search) => {
    if (input?.search?.length > 2) {
      let dataSet = createTableData(search);
      setData(dataSet);
    }
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allStoreOrders?.data);
      setData(dataSet);
      dispatch({
        type: searchRestaurantType["SEARCH_RESTAURANT_ORDERS"],
        payLoad: { data: [] },
      });
    }
  };

  useEffect(() => {
    if (input?.search?.length > 2) {
      async function doSearch() {
        try {
          let response = await dispatch(searchAllStoreOrders(input));
          if (response?.status === "success") {
            showSearch();
          }
        } catch (error) {
          console.log("error", error);
        }
      }
      doSearch();
    } else if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order, index) => {
        let show = {
          status: (
            <span>
              {order?.status === "delivered" ? (
                <span className="text-[#00D220] font-bold">
                  {order?.status}
                </span>
              ) : (
                <span className="text-primary font-bold">{order?.status}</span>
              )}
            </span>
          ),
          date: moment(order?.created_at).format("MMM DD, YYYY"),
          payment_type: order?.order_payment_method ?? "Not available",
          id: (
            <span className="flex items-center">
              {order?.id}{" "}
              <button
                type="button"
                onClick={(e) => {
                  navigator.clipboard.writeText(order?.id);
                  copyTextFromTable(e, order?.id);
                }}
              >
                <COPY className="w-[14px] h-[14px] ml-1" />
              </button>
            </span>
          ),
          amount: <span>NGN {numberWithCommas(order?.order_amount)}</span>,
          foodData: order,
        };
        outputArray.push({ show });
        return true;
      });

      return outputArray;
    }
  }

  function createTableDataDownload(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order, index) => {
        let show = {
          status: order?.status,
          date: moment(order?.created_at).format("MMM DD, YYYY"),
          payment_type: order?.order_payment_method ?? "Not available",
          id: order?.id,
          amount: numberWithCommas(order?.order_amount),
        };
        outputArray.push(show);
        return true;
      });

      return outputArray;
    }
  }

  const downloadCSV = async () => {
    const response = await dispatch(
      getAllStoreOrdersNoPage({
        ...payLoad,
        store_id: allStoreOrders?.id,
        pagination: 0,
      })
    );
    if (response) {
      const newData = createTableDataDownload(response?.data);
      writeFileWithXLSX(newData, {
        filename: "store orders.xlsx",
        download: true,
      });
    }
  };

  useLayoutEffect(() => {
    const dataSet = createTableData(allStoreOrders?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStoreOrders]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "Status",
            accessor: "show.status",
          },
          {
            Header: "Date",
            accessor: "show.date",
          },
          {
            Header: "Payment Type",
            accessor: "show.payment_type",
          },
          {
            Header: "Order ID",
            accessor: "show.id",
          },
          {
            Header: "Amount",
            accessor: "show.amount",
          },
        ],
      },
    ],
    []
  );

  return (
    <div>
      {/* <Card.TableFilter
        onChange={handleChange}
        title="Store Order"
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterStoreDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
        searchPlaceholder={"Name or Phone Number"}
      >
        <Button.Secondary
          title={downloading ? <Beat color={"#ffffff"} /> : "Download CSV"}
          onClick={downloadCSV}
          disabled={!data}
        />
      </Card.TableFilter> */}
      {/* <TableFilter
        results={allCatalogues?.data?.length}
        totalResults={allCatalogues?.data?.length}
        title={"Store Order"}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        searchPlaceholder={"Name or Phone Number"}
      >
        <Button.Primary title={"Create Catalogue"} onClick={goToCreate} />
        <Button.Secondary
          title={downloading ? <Beat color={"#ffffff"} /> : "Download CSV"}
          onClick={() => downloadCSV()}
          disabled={!data}
        />
      </TableFilter> */}
      <TableFilter
        title={"Store Order"}
        results={allStoreOrders?.length}
        totalResults={allStoreOrders?.total}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        payLoad={payLoad}
        // page={params.page}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterStoreDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
        searchPlaceholder="Name or Phone Number"
      >
        <Button.Secondary
          title={downloading ? <Beat color={"#ffffff"} /> : "Download CSV"}
          onClick={downloadCSV}
          disabled={!data}
        />
      </TableFilter>
      <div className="border-b border-[#E4E4F3]"></div>
      <div className="bg-white dark:bg-[#121212] rounded-md py-4 px-3 sm:px-8">
        {filtering || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <>
            <Table
              columns={columns}
              data={data?.length > 0 ? data : []}
              className="w-full"
              onClick={() => {}}
            />
            <PaginationNoRoute
              data={allStoreOrders}
              page={page}
              setPage={setPage}
            />
          </>
        ) : (
          <div className="text-center">
            <div className="flex justify-center my-12 ">
              <img src={EmptyState} alt="orders" />
            </div>
            <p className="font-bold text-xl my-3">Store Orders</p>
            <p className="text-sm text-gray-500">
              You do not have any Store Orders yet
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "@headlessui/react";
import moment from "moment";
import Button from "../../../components/buttons/buttons";
import StoreOrders from "./StoreOrders";
import AllMerchandise from "./AllMerchandise";
import {
  approveStoreByManagement,
  unApproveStoreByManagement,
  approveStore,
  unApproveStore,
  getSingleStore,
} from "../../../redux/stores/storeActions";
import { getStoreStatistics } from "../../../redux/statistics/statisticsAction";
import { getAUsersAction } from "./../../../redux/users/userActions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SingleStore() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);

  const { user } = useSelector((state) => state.user);
  const { singleStore } = useSelector((state) => state.store);
  const { storeStats } = useSelector((state) => state.statistics);

  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);

  const [approved, setApproved] = useState(false);
  const [published, setPublished] = useState(false);
  const [active_tab, setActiveTab] = useState("orders");
  const [payLoad] = useState({
    store_id: id,
    start_date: lastMonth,
    end_date: new Date(),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getSingleStore(id));
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (singleStore?.user_id) {
      setApproved(singleStore?.management_approved);
      setPublished(singleStore?.business_approved);
      dispatch(getAUsersAction(singleStore?.user_id));
      dispatch(getStoreStatistics(payLoad));
    }
    // eslint-disable-next-line
  }, [singleStore?.user_id]);

  const approveByStore = () => {
    dispatch(approveStore({ id: singleStore?.id }));
  };
  const unapproveByStore = () => {
    dispatch(unApproveStore({ id: singleStore?.id }));
  };

  const handleApprove = () => {
    dispatch(approveStoreByManagement({ id: singleStore?.id }));
  };

  const handleUnApprove = () => {
    dispatch(unApproveStoreByManagement({ id: singleStore?.id }));
  };

  const toggle = (tab) => {
    if (active_tab !== tab?.href) {
      setActiveTab(tab?.href);
    }
  };

  return (
    <div data-test="singleStore-page">
      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-3 sm:px-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">{singleStore?.store_name}</h3>

          <Button.Primary
            title={isMobile() ? "Edit" : "Edit Store"}
            onClick={() => navigate(`/admin/stores/edit/${singleStore?.id}`)}
          />
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Store ID</label>
            <p className="text-[#151515] dark:text-white text-sm">{singleStore?.id}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Email</label>
            <p className="text-[#151515] dark:text-white text-sm break-words">
              {singleStore?.store_email}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Phone Number</label>
            <p className="text-[#151515] dark:text-white text-sm">{singleStore?.store_phone}</p>
          </div>
          {/* publish/unpublish store */}
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              {singleStore?.business_approved ? "Unpublish" : "Publish"}
            </label>
            <Switch.Group as="div" className="flex">
              <Switch
                checked={published}
                onChange={(e) => {
                  setPublished(e);
                  singleStore?.business_approved
                    ? unapproveByStore()
                    : approveByStore();
                }}
                className={classNames(
                  published ? "bg-primary" : "bg-gray-200",
                  "relative inline-flex mt-1 h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    published ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white dark:bg-[#121212] shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>

          {/* approve/unapprove store */}
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              {singleStore?.management_approved ? "Unapprove" : "Approve"}
            </label>
            <Switch.Group as="div" className="flex">
              <Switch
                checked={approved}
                onChange={(e) => {
                  setApproved(e);
                  singleStore?.management_approved
                    ? handleUnApprove()
                    : handleApprove();
                }}
                className={classNames(
                  approved ? "bg-primary" : "bg-gray-200",
                  "relative inline-flex mt-1 h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    approved ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white dark:bg-[#121212] shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Created On</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {moment(singleStore?.created_at).format("DD, MMM YYYY, HH:MM A")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Status</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {singleStore?.management_approved ? "Approved" : "Unapproved"}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Country</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {singleStore?.store_country}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">State</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {" "}
              {singleStore?.store_state}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">City</label>
            <p className="text-[#151515] dark:text-white text-sm"> {singleStore?.store_city}</p>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-3 sm:px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Store Owner Details</h3>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-5 grid-col-2 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Name</label>
            <p className="text-[#151515] dark:text-white text-sm">{user?.name}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Email</label>
            <p className="text-[#151515] dark:text-white text-sm break-words">{user?.email}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Phone Number</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {user?.phone ? user?.phone : "Not Available"}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Address</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {user?.address > 0 ? user?.address[0] : "Not Available"}
            </p>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-3 sm:px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Orders History</h3>
          <p>
            Period: <span className="text-primary">*Four (4) Months Ago*</span>
          </p>
        </div>

        <div className="grid sm:grid-cols-6 grid-cols-2 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Total Orders </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {storeStats?.stats?.orders?.total_orders}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Delivered Orders
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {storeStats?.stats?.orders?.delivered_orders}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Shipped Orders
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {storeStats?.stats?.orders?.shipped_orders}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              Unshipped Orders
            </label>
            <p className="text-[#151515] dark:text-white text-sm">
              {storeStats?.stats?.orders?.unshipped_orders}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Closed Orders</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {storeStats?.stats?.orders?.closed_orders}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Order Paid</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {storeStats?.stats?.orders?.order_paid}
            </p>
          </div>
        </div>
      </div>

      {/* Store Orders and Merchandise tab section */}
      <div className="border-b border-gray-200 mt-5">
        <nav
          className="-mb-px flex space-x-8 overflow-x-auto"
          aria-label="Tabs"
        >
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => {
                toggle(tab);
              }}
              className={classNames(
                tab.href === active_tab
                  ? "border-primary text-primary font-bold"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={tab.href === active_tab ? "page" : undefined}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
      {active_tab === "orders" && <StoreOrders />}
      {active_tab === "merchandise" && <AllMerchandise />}
    </div>
  );
}

const tabs = [
  { name: "Orders", href: "orders" },
  { name: "Merchandise", href: "merchandise" },
];

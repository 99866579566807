import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Button from "../../../components/buttons/buttons";
import Fail from "../../../assets/images/icons/failed-txn.svg";
import Success from "../../../assets/images/icons/success-txn.svg";
import Pending from "../../../assets/images/icons/pending-txn.svg";
import Empty from "../../../assets/images/emptyState/invites.png";
import TableSkeleton from "../../../components/skeleton/table";
import { PaginationNoRoute } from "../../../components/pagination/paginationNoRoute";
import RetryPaymentModal from "../../../components/modals/payment/retryPaymentModal";
import ViewPaymentModal from "../../../components/modals/payment/viewPaymentModal";
import { getMyPayments } from "../../../redux/payment/paymentActions";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function MyPayments() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const retryPaymentModalRef = useRef(null);
  const viewPaymentModalRef = useRef(null);
  const [page, setPage] = useState(1);
  const { myPayments, isLoading } = useSelector((state) => state.payment);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date();
  var year = d.getUTCFullYear();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    dispatch(getMyPayments(page));
  }, [dispatch, page]);

  const groupedData = myPayments?.data?.reduce((acc, obj) => {
    const d = new Date(obj.created_at);
    let month = months[d.getMonth()];
    if (!acc[month]) {
      acc[month] = [];
    }
    acc[month].push(obj);
    return acc;
  }, {});

  return (
    <>
      <div className="sm:w-[820px] w-full mt-10 sm:ml-10 sm:mt-0 px-6 bg-white dark:bg-[#121212] min-h-screen rounded-sm shadow-2xl">
        <div className="border-b-2 py-4 sm:py-6 mb-6 block">
          <p className="text-base font-medium text-gray-800 dark:text-white text-center">
            My Payments
          </p>
        </div>

        <div>
          {isLoading ? (
            <TableSkeleton />
          ) : myPayments?.data?.length > 0 ? (
            <>
              {Object.entries(groupedData).map(([month, objs]) => (
                <div key={month}>
                  <h1 className="text-xl font-medium py-6 uppercase ">
                    {month} {year}
                  </h1>
                  <div className="sm:border-b-2 dark:border-none"></div>
                  <ul className="sm:p-2">
                    {objs.map((obj, index) => (
                      <li
                        key={index}
                        className="border-b border-gray-300 dark:border-none grid grid-cols-3 sm:grid-cols-4 gap-2 items-center py-2"
                      >
                        <div className="hidden sm:flex text-base text-[#676565]">
                          <p>
                            {moment(obj?.created_at).format("MMM DD . HH:MM")}
                          </p>
                        </div>

                        {obj?.status === "pending" ? (
                          <div className="flex items-center gap-1">
                            <p className="text-[#FFA927] ml-1 text-sm sm:text-lg font-bold sm:font-medium sm:min-w-[100px]">
                              Pending
                            </p>
                            <img src={Pending} alt="icon" className="w-5 h-5" />
                          </div>
                        ) : (
                          <div className="flex items-center gap-1">
                            {obj?.paid ? (
                              <p className="text-[#00D220] ml-1 text-sm sm:text-lg font-bold sm:font-medium sm:min-w-[100px]">
                                Success
                              </p>
                            ) : (
                              <p className="text-primary ml-1 text-sm sm:text-lg font-bold sm:font-medium sm:min-w-[100px]">
                                Failed
                              </p>
                            )}
                            {obj?.paid ? (
                              <img
                                src={Success}
                                alt="icon"
                                className="w-5 h-5"
                              />
                            ) : (
                              <img src={Fail} alt="icon" className="w-5 h-5" />
                            )}
                          </div>
                        )}

                        <p className="text-base sm:text-lg font-medium">
                          ₦{numberWithCommas(obj?.amount)}
                        </p>

                        {obj?.paid || obj?.status === "pending" ? (
                          <Button.Secondary
                            title={"View"}
                            type="button"
                            className="px-0"
                            onClick={() => {
                              viewPaymentModalRef.current.open();
                              viewPaymentModalRef.current.getId(obj?.id);
                            }}
                          />
                        ) : (
                          <Button.Primary
                            title={"Retry"}
                            type="button"
                            className="px-0"
                            onClick={() => {
                              if (retryPaymentModalRef.current) {
                                retryPaymentModalRef.current.open();
                                retryPaymentModalRef.current.getPfm(obj?.pfm);
                                retryPaymentModalRef.current.getTotalPrice(
                                  obj?.amount
                                );
                                retryPaymentModalRef.current.getProviderPrice(
                                  obj
                                );
                                retryPaymentModalRef.current.getReference(
                                  obj?.id
                                );
                              }
                            }}
                          />
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
              <PaginationNoRoute
                data={myPayments}
                page={page}
                setPage={setPage}
              />
            </>
          ) : (
            <div className="my-10 mt-20  text-center">
              <img
                src={Empty}
                alt="empty state"
                className="flex justify-center mx-auto mb-4"
              />
              <p className="font-bold mb-2">No Payments</p>
              <p className="text-sm text-gray-400">
                Your Invite list shows here.
              </p>
            </div>
          )}
        </div>
        <Button.Secondary
          type="button"
          title={"Back"}
          className={"sm:hidden rounded-md mb-8 sm:mb-0 w-full mt-8"}
          onClick={() => navigate("/profile")}
        />
        <RetryPaymentModal ref={retryPaymentModalRef} visibility={false} />
        <ViewPaymentModal ref={viewPaymentModalRef} visibility={false} />
      </div>
    </>
  );
}

import React from "react";
import GMAIL from "../../../../assets/images/icons/Gmail.svg";
// import APPLE from "../../../../assets/images/icons/apple.svg";
import CLOSE from "../../../../assets/images/icons/close.svg";
import { ReactComponent as Apple } from "../../../../assets/images/icons/apple.svg";
import LoginForm from "../../../forms/auth/loginForm";
import { Dialog } from "@headlessui/react";

export default function FormSection0({ formWizardRef, props }) {
  const google_client = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  let base_url = window.location.origin;

  const handleGoogle = () => {
    let data = {
      scope: "openid+profile+email",
      include_granted_scopes: "true",
      response_type: "token",
      state: "state_parameter_passthrough_value",
      redirect_uri: `${base_url}/api/auth/socialite/google/callback`,
      client_id: google_client,
    };
    let url = `https://accounts.google.com/o/oauth2/v2/auth?scope=${data.scope}&include_granted_scopes=${data.include_granted_scopes}&response_type=${data.response_type}&state=${data.state}&redirect_uri=${data.redirect_uri}&client_id=${data.client_id}`;
    return window.open(url, "_parent");
  };

  const handleApple = () => {
    let data = {
      client_id: "com.ogwugo.food",
      nonce: (Math.random() + 1).toString(36),
      redirect_uri: `${base_url}/api/auth/socialite/apple/callback`,
      response_mode: "fragment",
      response_type: "code+id_token",
      scope: "",
      state: "state_parameter_passthrough_value",
    };
    let url = `https://appleid.apple.com/auth/authorize?client_id=${data.client_id}&nonce=${data.nonce}&redirect_uri=${data.redirect_uri}&response_mode=${data.response_mode}&response_type=${data.response_type}&scope=${data.scope}&state=${data.state}`;
    return window.open(url, "_parent");
  };

  return (
    <Dialog.Panel className="relative sm:w-[600px] bg-white dark:bg-darkBg rounded-3xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8  w-full sm:p-8">
      <div className=" sm:block absolute top-2 right-2 pt-4 pr-4">
        <button
          type="button"
          className="bg-[#ffeae5] rounded-lg  p-2 sm:p-3 "
          onClick={props.onClose}
        >
          <span className="sr-only">Close</span>
          <img src={CLOSE} alt="close" className=" w-3 h-3 sm:w-4 sm:h-4" />
        </button>
      </div>
      <div className="sm:flex sm:items-start">
        <div className="mt-16 md:mt-10 sm:mt-10 sm:ml-4 text-left">
          <Dialog.Title
            as="h3"
            className="text-xl font-bold text-black dark:text-white"
          >
            Create an account or login
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm font-normal text-gray-500">
              Login below or create a new Ogwugo account
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4">
        <button
          type="button"
          className=" mt-5 w-full inline-flex justify-center h-14 rounded-md border border-transparent shadow-sm px-4 py-4 bg-primary text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring focus:ring-offset focus:ring-red-500 sm:mt-6 sm:text-sm"
          onClick={() => {
            formWizardRef.current.goTo(1);
          }}
        >
          Register on Ogwugo
        </button>

        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center items-center h-14 rounded-md border border-primary shadow-sm px-4 py-4 bg-white dark:bg-[#010101] text-lg font-medium text-black dark:text-white hover:text-gray-500 focus:outline-none focus:ring focus:ring-offset focus:ring-primary sm:mt-2 sm:text-sm"
          onClick={() => handleGoogle()}
        >
          <span className="mr-3">
            <img src={GMAIL} alt="google" />
          </span>
          Connect with Google
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center items-center h-14 rounded-md border border-primary shadow-sm px-4 py-4 bg-white dark:bg-[#010101] text-lg font-medium text-black dark:text-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-2  sm:text-sm"
          onClick={() => handleApple()}
        >
          <span className="mr-2">
            <Apple className="white-icon" />
          </span>
          Connect with Apple
        </button>
        <div className="mt-10 grid grid-cols-3 gap-6 sm:gap-8 items-center">
          <div className="border-b-2"></div>
          <p className="text-base text-[#676566] text-center">
            Or continue with
          </p>
          <div className="border-b-2 "></div>
        </div>
        <LoginForm formWizardRef={formWizardRef} props={props} />
      </div>
    </Dialog.Panel>
  );
}

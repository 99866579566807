import React, { useState, useMemo, useEffect } from "react";
import Table from "../../../../../components/tables/tableCols1";

const dummyData = [
  {
    sn: "1",
    parameters: "Label",
    required: "Yes",
    description: "A string used to identify the generated token",
  },
];

const employee_location = `
  const createOrder = async () => {
    const res = await axios({
      method: post,
      url: \`\${base_url}/api/third-party/employee/locate\`,
      data: {
          token: "eyJpdiI6IlSIsInZhbHVlIjoiV1hSSDZDdml0RHVLMW9GK0JTcDhqYlBPd1BCU09pZWd0aDBlV3BueVZhZ..."
          id: "53465e675eiei65e4675", //employee id
          business_id: "5464754ut66e654"
      }
    });
  }

`;

const list_businesses = `
  const createOrder = async () => {
    const res = await axios({
      method: post,
      url: \`\${base_url}/api/third-party/business/list\`,
      data: {
          token: "eyJpdiI6IlSIsInZhbHVlIjoiV1hSSDZDdml0RHVLMW9GK0JTcDhqYlBPd1BCU09pZWd0aDBlV3BueVZhZ..."
      }
    });
  }

`;

const order_restaurant_closed = `
  const createOrder = async () => {
    const res = await axios({
      method: post,
      url: \`\${base_url}/api/third-party/restaurant/order/mark/closed\`,
      data: {
          token: "eyJpdiI6IlSIsInZhbHVlIjoiV1hSSDZDdml0RHVLMW9GK0JTcDhqYlBPd1BCU09pZWd0aDBlV3BueVZhZ...",
          id: "123457775yhii5e3",
          properties: "1"
      }
    });
  }

`;

const order_store_closed = `
  const createOrder = async () => {
    const res = await axios({
      method: post,
      url: \`\${base_url}/api/third-party/store/order/mark/closed\`,
      data: {
          token: "eyJpdiI6IlVCMV1hSSDZDdml0RHVLMW9GK0JTcDhqYlBPd1BCU09pZWd0aDBlV3BueVZhZ...",
          id: "123457775yhii5e3",
          properties: "1"
      }
    });
  }

`;

const order_restaurant_delivered = `
  const createOrder = async () => {
    const res = await axios({
      method: post,
      url: \`\${base_url}/api/third-party/restaurant/order/mark/delivered\`,
      data: {
          token: "eyJpdiI6IlSIsInZhbHVlIjoiV1hSSDZDdml0RHVLMW9GK0JTcDhqYlBPd1BCU09pZWd0aDBlV3BueVZhZ...",
          id: "123457775yhii5e3",
          properties: "1"
      }
    });
  }

`;

const order_store_delivered = `
  const createOrder = async () => {
    const res = await axios({
      method: post,
      url: \`\${base_url}/api/third-party/store/order/mark/delivered\`,
      data: {
          token: "eyJpdiI6IlVCMV1hSSDZDdml0RHVLMW9GK0JTcDhqYlBPd1BCU09pZWd0aDBlV3BueVZhZ...",
          id: "123457775yhii5e3",
          properties: "1"
      }
    });
  }

`;

const view_restaurant_order = `
  const createOrder = async () => {
    const res = await axios({
      method: post,
      url: \`\${base_url}/api/third-party/restaurant/order/me\`,
      data: {
        token: "eyJpdiPSIsInZhbHVlIjoiV1hSSDZDdml0RHVLMW9GK0JTcDhqYlBPd1BCU09pZWd0aDBlV3BueVZhZ...",
        restaurant_id: "123457775yhii5e3"
      }
    });
  }

`;

const view_store_order = `
  const createOrder = async () => {
    const res = await axios({
      method: post,
      url: \`\${base_url}/api/third-party/store/order/me\`,
      data: {
        token: "eyJpdiPSIsInZhbHVlIjoiV1hSSDZDdml0RHVLMW9GK0JTcDhqYlBPd1BCU09pZWd0aDBlV3BueVZhZ...",
        store_id:: "123457775yhii5e3"
      }
    });
  }

`;

export default function ThirdPartyDocumentation() {
  const [data, setData] = useState([]);
  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((item, index) => {
        let single = {
          sn: <span>{item?.sn}</span>,
          parameters: (
            <div className="text-sm text-[#00D220] bg-[#D1FED8] rounded-md px-3 py-1 w-fit">
              {item?.parameters}
            </div>
          ),
          required: item?.required,
          description: item?.description,
        };

        outputArray.push({ single });
        return true;
      });

      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(dummyData);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dummyData]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "single.sn",
          },
          {
            Header: "Parameters",
            accessor: "single.parameters",
          },
          {
            Header: "Required ?",
            accessor: "single.required",
          },
          {
            Header: "Description",
            accessor: "single.description",
          },
        ],
      },
    ],
    []
  );
  return (
    <div className="third-party-documentation-page">
      <div className="bg-white dark:bg-[#121212] text-black dark:text-white rounded-md py-4">
        <div className="py-2 text-center max-w-[90%] mx-auto">
          <p className="text-[#4D4D4D] dark:text-white text-xs sm:text-lg">
            "We’ve already set the groundwork, so you can focus on creating
            without the hassle".
          </p>
        </div>
        <div className="border-b border-gray-200 dark:border-none" />

        <div className="py-4 text-center">
          <h2 className="text-primary text-base sm:text-4xl font-bold">
            Third-Party Integration
          </h2>
        </div>
        <div className="border-b border-gray-200 dark:border-none" />

        <div className="py-8 px-3 sm:px-4">
          <p className="mb-5 text-xs sm:text-base dark:text-[#BCBCBC]">
            Third-party businesses with a valid token can perform a variety of
            actions within the system. Below is an overview of the available
            features and how to get started:
          </p>
          <h3 className="mb-5 text-base sm:text-2xl font-bold">
            Features and Actions Available
          </h3>
          <ul>
            <li className="mb-5">
              <p className="text-sm sm:text-lg font-bold mb-1">
                Create a Third-Party Token
              </p>
              <p className="text-xs sm:text-base dark:text-[#BCBCBC]">
                Generate a unique token for authentication purposes. This token
                must accompany every request made by the business to perform
                actions within the system.
              </p>
            </li>
            <li className="mb-5">
              <p className="text-sm sm:text-lg font-bold mb-1">View Orders</p>
              <p className="text-xs sm:text-base dark:text-[#BCBCBC]">
                Access and manage order details.
              </p>
            </li>
            <li className="mb-5">
              <p className="text-sm sm:text-lg font-bold mb-1">
                Mark Order as Delivered
              </p>
              <p className="text-xs sm:text-base dark:text-[#BCBCBC]">
                Update the status of an order to "delivered" once it has been
                successfully fulfilled.
              </p>
            </li>
            <li className="mb-5">
              <p className="text-sm sm:text-lg font-bold mb-1">
                Mark Order as Closed
              </p>
              <p className="text-xs sm:text-base dark:text-[#BCBCBC]">
                Finalize and close completed orders.
              </p>
            </li>
            <li className="mb-5">
              <p className="text-sm sm:text-lg font-bold mb-1">
                List Businesses
              </p>
              <p className="text-xs sm:text-base dark:text-[#BCBCBC]">
                Retrieve a list of all registered businesses within the system.
              </p>
            </li>
            <li className="mb-5">
              <p className="text-sm sm:text-lg font-bold mb-1">
                View Employee Location
              </p>
              <p className="text-xs sm:text-base dark:text-[#BCBCBC]">
                Track and view the current location of employees associated with
                the third-party business.
              </p>
            </li>
          </ul>
        </div>

        <div className="border-b border-gray-200 dark:border-none" />

        <div className="py-4 text-center">
          <h2 className="text-primary text-base sm:text-4xl font-bold">
            Creating a Third-Party Token
          </h2>
        </div>
        <div className="border-b border-gray-200 dark:border-none" />

        <div className="py-5 px-3 sm:px-4">
          <div className="py-4">
            <h2 className="text-base sm:text-2xl font-bold">
              Creating a Third-Party Token
            </h2>
            <p className="text-xs sm:text-base dark:text-[#BCBCBC]">
              To enable secure access to the system, third-party businesses must
              first create a token. The token serves as a key for authentication
              and is required for all subsequent requests.
            </p>
          </div>

          <div className="py-4">
            <h2 className="text-lg font-bold mb-2">Request Parameters:</h2>
            <div className="border border-[#E4E4F3]">
              <Table
                columns={columns}
                data={data?.length > 0 ? data : []}
                onClick={() => {}}
                className="w-full"
                rowClass="hover:shadow-md cursor-pointer"
              />
            </div>
          </div>

          <div className="py-4">
            <h2 className="text-md sm:text-base font-bold mb-1">Example:</h2>
            <p className="text-xs sm:text-base dark:text-[#BCBCBC]">
              When creating a token, only the label parameter is needed.
            </p>
            <p className="text-xs sm:text-base dark:text-[#BCBCBC]">
              This setup ensures secure communication and seamless integration
              for third-party businesses.
            </p>
          </div>
        </div>

        {/* View orders section */}
        <div className="border-b border-gray-200 dark:border-none" />
        <div className="py-4 text-center">
          <h2 className="text-primary text-base sm:text-4xl font-bold">
            View Orders
          </h2>
        </div>
        <div className="border-b border-gray-200 dark:border-none" />

        <div className="py-5 px-3 sm:px-4">
          <div className="py-4">
            <h2 className="text-base sm:text-2xl font-bold">View Orders</h2>
            <p className="text-xs sm:text-base dark:text-[#BCBCBC]">
              The third party business can view their restaurant or store orders
              by sending a request like the one below:
            </p>
          </div>

          <div className="py-4">
            <h2 className="text-md sm:text-base font-semibold mb-1">
              See sample request:
            </h2>
            <div className="px-4 border border-[#E4E4F3] mt-2">
              <pre
                style={{
                  color: "white",
                  fontFamily: "monospace",
                  overflowX: "auto",
                }}
                className="text-xs sm:text-base dark:text-[#BCBCBC]"
              >
                <code>{view_restaurant_order}</code>
              </pre>
            </div>

            <h2 className="text-md sm:text-base font-semibold my-3">
              For store orders:
            </h2>

            <div className="px-4 border border-[#E4E4F3] mt-2">
              <pre
                style={{
                  color: "white",
                  fontFamily: "monospace",
                  overflowX: "auto",
                }}
                className="text-xs sm:text-base dark:text-[#BCBCBC]"
              >
                <code>{view_store_order}</code>
              </pre>
            </div>
          </div>
        </div>

        {/* Mark orders delivered section */}
        <div className="border-b border-gray-200 dark:border-none" />
        <div className="py-4 text-center">
          <h2 className="text-primary text-base sm:text-4xl font-bold">
            Mark order as delivered
          </h2>
        </div>
        <div className="border-b border-gray-200 dark:border-none" />

        <div className="py-5 px-3 sm:px-4">
          <div className="py-4">
            <h2 className="text-base sm:text-2xl font-bold">
              Mark order as delivered
            </h2>
            <p className="text-xs sm:text-base dark:text-[#BCBCBC]">
              The third party business can view their restaurant or store orders
              by sending a request like the one below:
            </p>
          </div>

          <div className="py-4">
            <h2 className="text-md sm:text-base font-semibold mb-1">
              See sample request:
            </h2>
            <div className="px-4 border border-[#E4E4F3] mt-2">
              <pre
                style={{
                  color: "white",
                  fontFamily: "monospace",
                  overflowX: "auto",
                }}
                className="text-xs sm:text-base dark:text-[#BCBCBC]"
              >
                <code>{order_restaurant_delivered}</code>
              </pre>
            </div>

            <h2 className="text-md sm:text-base font-semibold my-3">
              For store orders:
            </h2>

            <div className="px-4 border border-[#E4E4F3] mt-2">
              <pre
                style={{
                  color: "white",
                  fontFamily: "monospace",
                  overflowX: "auto",
                }}
                className="text-xs sm:text-base dark:text-[#BCBCBC]"
              >
                <code>{order_store_delivered}</code>
              </pre>
            </div>
          </div>
        </div>

        {/* Mark orders closed section */}
        <div className="border-b border-gray-200 dark:border-none" />
        <div className="py-4 text-center">
          <h2 className="text-primary text-base sm:text-4xl font-bold">
            Mark order as closed
          </h2>
        </div>
        <div className="border-b border-gray-200 dark:border-none" />

        <div className="py-5 px-3 sm:px-4">
          <div className="py-4">
            <h2 className="text-base sm:text-2xl font-bold">
              Mark order as closed
            </h2>
            <p className="text-xs sm:text-base dark:text-[#BCBCBC]">
              The third party business can mark orders as closed by sending a
              request like the one below:
            </p>
          </div>

          <div className="py-4">
            <h2 className="text-md sm:text-base font-semibold mb-1">
              See sample request:
            </h2>
            <div className="px-4 border border-[#E4E4F3] mt-2">
              <pre
                style={{
                  color: "white",
                  fontFamily: "monospace",
                  overflowX: "auto",
                }}
                className="text-xs sm:text-base dark:text-[#BCBCBC]"
              >
                <code>{order_restaurant_closed}</code>
              </pre>
            </div>

            <h2 className="text-md sm:text-base font-semibold my-3">
              For store orders:
            </h2>

            <div className="px-4 border border-[#E4E4F3] mt-2">
              <pre
                style={{
                  color: "white",
                  fontFamily: "monospace",
                  overflowX: "auto",
                }}
                className="text-xs sm:text-base dark:text-[#BCBCBC]"
              >
                <code>{order_store_closed}</code>
              </pre>
            </div>
          </div>
        </div>

        {/* List Businesses section */}
        <div className="border-b border-gray-200 dark:border-none" />
        <div className="py-4 text-center">
          <h2 className="text-primary text-base sm:text-4xl font-bold">
            List Businesses
          </h2>
        </div>
        <div className="border-b border-gray-200 dark:border-none" />

        <div className="py-5 px-3 sm:px-4">
          <div className="py-4">
            <h2 className="text-base sm:text-2xl font-bold">List Businesses</h2>
            <p className="text-xs sm:text-base dark:text-[#BCBCBC]">
              The third party business can mark orders as closed by sending a
              request like the one below:
            </p>
          </div>

          <div className="py-4">
            <h2 className="text-md sm:text-base font-semibold mb-1">
              See sample request:
            </h2>
            <div className="px-4 border border-[#E4E4F3] mt-2">
              <pre
                style={{
                  color: "white",
                  fontFamily: "monospace",
                  overflowX: "auto",
                }}
                className="text-xs sm:text-base dark:text-[#BCBCBC]"
              >
                <code>{list_businesses}</code>
              </pre>
            </div>
          </div>
        </div>

        {/* View Employee Location section */}
        <div className="border-b border-gray-200 dark:border-none" />
        <div className="py-4 text-center">
          <h2 className="text-primary text-base sm:text-4xl font-bold">
            View Employee Location
          </h2>
        </div>
        <div className="border-b border-gray-200 dark:border-none" />

        <div className="py-5 px-3 sm:px-4">
          <div className="py-4">
            <h2 className="text-base sm:text-2xl font-bold">
              View Employee Location
            </h2>
            <p className="text-xs sm:text-base dark:text-[#BCBCBC]">
              For a third party business to view an employees' location, they
              should send an http request like:
            </p>
          </div>

          <div className="py-4">
            <h2 className="text-md sm:text-base font-semibold mb-1">
              See sample request:
            </h2>
            <div className="px-4 border border-[#E4E4F3] mt-2">
              <pre
                style={{
                  color: "white",
                  fontFamily: "monospace",
                  overflowX: "auto",
                }}
                className="text-xs sm:text-base dark:text-[#BCBCBC]"
              >
                <code>{employee_location}</code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

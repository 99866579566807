import React from "react";
import Input from "../../../../../components/inputs/inputs";

export default function LeftInput({ handleChange, data }) {
  const { recipient_name, order_paid, order_discount, order_payment_method } =
    data;

  return (
    <>
      <div className="border-b border-[#E4E4F3] dark:border-none py-3 text-center">
        <h3 className="text-base text-gray-500 dark:text-white font-medium">
          Edit Order
        </h3>
      </div>
      <div className="text-right mt-4">
        <h5 className="text-[#C4C4C4] text-sm"> New Recipient +</h5>
      </div>

      <form className="mt-2">
        <div className="mt-2 mb-3 relative">
          <input
            type={"search"}
            name={"recipient_name"}
            htmlFor={"recipient_name"}
            autoComplete="off"
            className={
              "mt-2 block pl-3 pr-10 w-full text-base bg-[#C4C4C4] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
            }
            placeholder={"Search user by name, phone Number, email..."}
            value={recipient_name}
            disabled
          />
        </div>

        <div className="grid grid-cols-2 gap-8">
          <Input.Select
            title={"Discount"}
            name={"order_payment_method"}
            htmlFor={"order_payment_method"}
            className="bg-[#C4C4C4]"
            disabled
          >
            <option value={"Amount"}>Amount</option>
          </Input.Select>

          <Input.Number
            title={"."}
            htmlFor={"order_discount"}
            name={"order_discount"}
            placeholder={"0"}
            className="bg-[#C4C4C4]"
            defaultValue={order_discount}
            disabled
          />
        </div>
        <Input.Select
          title={"Payment Method"}
          name={"order_payment_method"}
          onChange={handleChange}
          htmlFor={"order_payment_method"}
          defaultValue={order_payment_method}
        >
          <option>Select payment Method</option>
          <option value={"Paystack"}>Paystack</option>
          <option value={"Transfer"}>Bank Transfer</option>
        </Input.Select>

        <Input.Select
          title={"Payment Status"}
          name={"order_paid"}
          onChange={handleChange}
          htmlFor={"order_paid"}
          defaultValue={order_paid ? "1" : "0"}
        >
          <option>Select payment status</option>
          <option value={"1"}>Paid</option>
          <option value={"0"}>Not Paid</option>
        </Input.Select>
      </form>
    </>
  );
}

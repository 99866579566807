import React, { useState } from "react";
// import Button from "../../../../components/buttons/buttons";
import LeftSide from "./components/leftSide";
import RightSide from "./components/rightSide";

export default function CreateStoreOrder() {
  const [submitted, setSubmitted] = useState(false);
  let [recipient_phone, setRecipientPhone] = useState("");
  recipient_phone = recipient_phone?.slice(1);
  const [data, setData] = useState({
    business_id: "",
    user_address_id: "1",
    user_id: "",
    store_id: "",
    recipient_name: "",
    recipient_email: "",
    recipient_phone: "",
    recipient_address: "",
    recipient_city: "",
    recipient_state: "",
    recipient_country: "",
    recipient_invoice: "",
    order_type: "standard",
    order_paid: "",
    order_payment_method: "",
    order_items: [],
    order_amount: "",
    order_discount: 0,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setData({ ...data, [name]: value });
  };
  return (
    <div data-test="createOrder-page">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] sm:h-[786px] rounded-md py-5 px-8">
          <LeftSide
            handleChange={handleChange}
            data={data}
            setData={setData}
            submitted={submitted}
            recipient_phone={recipient_phone}
            setRecipientPhone={setRecipientPhone}
          />
        </div>

        <RightSide
          data={data}
          setData={setData}
          submitted={submitted}
          setSubmitted={setSubmitted}
          handleChange={handleChange}
          recipient_phone={recipient_phone}
        />
      </div>
    </div>
  );
}

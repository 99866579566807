import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Subscription from "./subscription";
import Packages from "./packages";
import Items from "./items";
import Users from "./users";

const tabs = [
  { name: "Subscriptions", href: "subscriptions" },
  { name: "Food Packages", href: "packages" },
  { name: "Food Package Item", href: "items" },
  // { name: "Subscribed Users", href: "users" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FoodPackages() {
  const navigate = useNavigate();
  const DEFAULT_ACTIVE_TAB = "packages";
  const { active_tab, page } = useParams();

  useEffect(() => {
    if (!active_tab || !page) {
      navigate(`/admin/food-packages/${DEFAULT_ACTIVE_TAB}/1`);
    }
    // eslint-disable-next-line
  }, [active_tab, page]);

  const toggle = (tab) => {
    if (active_tab !== tab) {
      navigate(`/admin/food-packages/${tab.href}/1`);
    }
  };

  return (
    <>
      <div className="sm:block">
        <div className="border-b border-gray-200 dark:border-none">
          <nav
            className="-mb-px flex space-x-8 overflow-x-auto"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => {
                  toggle(tab);
                }}
                className={classNames(
                  tab.href === active_tab
                    ? "border-primary text-primary font-bold"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab.href === active_tab ? "page" : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div>
        {active_tab === "subscriptions" && <Subscription />}
        {active_tab === "packages" && <Packages />}
        {active_tab === "items" && <Items />}
        {active_tab === "users" && <Users />}
      </div>
    </>
  );
}

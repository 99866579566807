import React, { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../../components/buttons/buttons";
import { Beat } from "../../../../../plugins/spinners.plugin";
import { writeFileWithXLSX } from "../../../../../components/exports/xlsx";
import Table from "../../../../../components/tables/tableCols1";
import TableSkeleton from "../../../../../components/skeleton/table";
import TableFilter from "../../../../../components/tables/tableFilter";
import EmptyState from "../../../../../assets/images/emptyState/feature.svg";
import { Pagination } from "../../../../../components/pagination/pagination";
import { AllFiltersModal } from "../../../../../components/modals/allFiltersModal";
import FilterFeatureDropDown from "../../../../../components/dropDown/filter/filterFeature";
import { searchFeaturedProducts } from "../../../../../redux/feature/featureActions";
import {
  getAllFeaturedNoPagination,
  filterFeaturedProducts,
} from "../../../../../redux/feature/featureActions";
import featureType from "../../../../../redux/feature/featureTypes";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function All() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { page } = useParams();
  // const { searching } = useSelector((state) => state.business);
  const { allFeatured, filtering, searching } = useSelector(
    (state) => state.feature
  );
  const { downloading } = useSelector((state) => state.feature);
  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);

  const [isFilterOpen, setFilterState] = useState(false);
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });
  const [payLoad] = useState({
    // start_date: lastMonth,
    end_date: new Date(),
  });

  useEffect(() => {
    dispatch(filterFeaturedProducts(payLoad, page));
    // eslint-disable-next-line
  }, [dispatch, page]);

  useEffect(() => {
    if (!page) {
      navigate(`/admin/feature/active/1`);
    }
  }, [page, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleDownload = async () => {
    const response = await dispatch(
      getAllFeaturedNoPagination({
        ...payLoad,
        pagination: 0,
      })
    );
    if (response) {
      const newData = createTableDataDownload(response?.data);
      writeFileWithXLSX(newData, {
        filename: "Active_Feature.xlsx",
        download: true,
      });
    }
  };

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((featured, index) => {
        let single = {
          index: index + 1,
          outlet: featured?.outlet_id,
          item: featured?.id,
          amount: (
            <span>NGN {numberWithCommas(featured?.featured_amount)}</span>
          ),
          payment_status: featured.featured_paid ? (
            <span className="text-green-400">Paid</span>
          ) : (
            <span className="text-red-600">Unpaid</span>
          ),
          duration: <span>{featured?.featured_duration_in_hours} hours</span>,
          featured: featured,
        };
        outputArray.push({ single });
        return true;
      });
      return outputArray;
    }
  }

  function createTableDataDownload(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((featured, index) => {
        let single = {
          index: index + 1,
          feature_id: featured?.id,
          outlet_id: featured?.outlet_id,
          featured_amount: featured?.featured_amount,
          payment_status: featured.featured_paid ? "Paid" : "Unpaid",
          featured_duration: featured?.featured_duration_in_hours + "hours",
        };
        outputArray.push(single);
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allFeatured?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFeatured]);

  const goToSingle = (params) => {
    let id = params?.single?.featured?.id;
    navigate(`/admin/feature-report/${id}`, {
      state: { featured: params?.single?.featured },
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allFeatured?.data);
      setData(dataSet);
      dispatch({
        type: featureType["SEARCH_FEATURED"],
        payLoad: { data: [] },
      });
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchFeaturedProducts(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "single.index",
          },
          {
            Header: "Feature ID",
            accessor: "single.item",
          },
          {
            Header: "Feature Amount",
            accessor: "single.amount",
          },
          {
            Header: "Payment Status",
            accessor: "single.payment_status",
          },
          {
            Header: "Outlet ID",
            accessor: "single.outlet",
          },
          {
            Header: "Feature Duration",
            accessor: "single.duration",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="active-feature-page">
      <TableFilter
        title={"Feature"}
        results={allFeatured?.to}
        totalResults={allFeatured?.total}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        searchPlaceholder={"Amount, payment id"}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterFeatureDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
      >
        <Button.Primary
          title={downloading ? <Beat color={"#CE2000"} /> : "Check Stats"}
          onClick={() => navigate(`/admin/feature-report/id/statistics`)}
          disabled={!data}
        />
        <Button.Secondary
          title={downloading ? <Beat color={"#CE2000"} /> : "Download CSV"}
          onClick={handleDownload}
          disabled={!data}
        />
      </TableFilter>

      <div className="border-b border-[#E4E4F3]" />
      <div className="bg-white dark:bg-[#121212] rounded-md py-4 px-3 sm:px-8">
        {filtering || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            className="w-full"
            onClick={goToSingle}
            rowClass="hover:shadow-md cursor-pointer"
          />
        ) : (
          <div className="py-36 text-center">
            <div className="flex justify-center">
              <img src={EmptyState} className="w-auto" alt="no data" />
            </div>
            <h3 className="mt-5 text-xl font-bold">Feature</h3>
            <p className="mt-2 text-sm text-[#9CA3AF]">
              You do not have any feature yet.
            </p>
          </div>
        )}

        <Pagination data={allFeatured} route={"admin/feature/active"} />
      </div>
    </div>
  );
}

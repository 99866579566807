import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Switch } from "@headlessui/react";
import DeleteSubscriptionFood from "../../../../components/modals/admin/deleteSubscriptionFood";
import { getARestaurantAction } from "../../../../redux/restaurant/restaurantActions";
import {
  getASubscriptionFoodAction,
  approveSubscriptionFood,
  unapproveSubscriptionFood,
} from "../../../../redux/subscriptionFood/subscriptionFoodActions";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PackageItemDetails() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [enabled, setEnabled] = useState();

  const { restaurant } = useSelector((state) => state.restaurant);
  let { singleSubscriptionFood } = useSelector(
    (state) => state.subscriptionFood
  );

  useEffect(() => {
    if (id) {
      dispatch(getASubscriptionFoodAction(id));
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    setEnabled(singleSubscriptionFood?.management_approved);
  }, [singleSubscriptionFood?.management_approved]);

  useEffect(() => {
    if (singleSubscriptionFood?.restaurant_id) {
      dispatch(getARestaurantAction(singleSubscriptionFood?.restaurant_id));
    }
    // eslint-disable-next-line
  }, [singleSubscriptionFood?.restaurant_id]);

  const handleApprove = () => {
    dispatch(approveSubscriptionFood({ id: singleSubscriptionFood?.id }));
  };

  const handleUnapprove = () => {
    dispatch(unapproveSubscriptionFood({ id: singleSubscriptionFood?.id }));
  };

  return (
    <div data-test="foodDetails-page">
      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-4 sm:px-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center gap-2">
          <h3 className="text-md sm:text-lg font-medium">
            Food Package Item Details
          </h3>
          <DeleteSubscriptionFood
            id={singleSubscriptionFood?.id}
            food={singleSubscriptionFood?.food_name}
            outlet={restaurant?.restaurant_name}
          />
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Item</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {singleSubscriptionFood?.food_name}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Amount</label>
            <p className="text-[#151515] dark:text-white text-sm">
              NGN {numberWithCommas(singleSubscriptionFood?.food_amount)}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Created On</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {moment(singleSubscriptionFood?.created_at).format(
                "MMM DD, YYYY"
              )}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">
              {singleSubscriptionFood?.management_approved
                ? "Unapprove Package Item"
                : "Approve Package Item"}
            </label>
            <Switch.Group as="div" className="flex">
              <Switch
                checked={enabled}
                onChange={(e) => {
                  setEnabled(e);
                  singleSubscriptionFood?.management_approved
                    ? handleUnapprove()
                    : handleApprove();
                }}
                className={classNames(
                  enabled ? "bg-primary" : "bg-gray-200",
                  "relative inline-flex mt-1 h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white dark:bg-[#121212] shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-5 px-4 sm:px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-md sm:text-lg font-medium">Restaurant Details</h3>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Name</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {restaurant?.restaurant_name}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Email</label>
            <p className="text-[#151515] dark:text-white text-sm break-words">
              {restaurant?.restaurant_email}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Phone Number</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {restaurant?.restaurant_phone}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Flag Count</label>
            <p className="text-[#151515] dark:text-white text-sm">{restaurant?.flag}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Status</label>

            {restaurant?.management_approved ? (
              <p className="text-[#151515] dark:text-white text-sm">Approved</p>
            ) : (
              <p className="text-[#151515] dark:text-white text-sm">Unapproved</p>
            )}
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Created On</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {moment(restaurant?.created_at).format("MMM DD, YYYY")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">Country</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {restaurant?.restaurant_country}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">State</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {restaurant?.restaurant_state}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] dark:text-[#BCBCBC] text-sm mb-2">City</label>
            <p className="text-[#151515] dark:text-white text-sm">
              {restaurant?.restaurant_city}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

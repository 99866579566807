import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Success from "../../../../assets/images/icons/check_circle.svg";
import Error from "../../../../assets/images/icons/cancel_circle.svg";
import { showApplication } from "../../../../redux/applicationSettings/applicationAction";

export default function AdminDashboardSummary() {
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state.application);

  useEffect(() => {
    dispatch(showApplication());
  }, [dispatch]);

  return (
    <div className="grid grid-col-1 md:grid-cols-2 gap-4 mt-12">
      <div className="px-3 py-4 sm:px-8 sm:py-8 border dark:border-none bg-white dark:bg-[#121212] rounded-md">
        <div className="flex justify-between items-center mb-4">
          <span className="text-[#344B67] dark:text-white text-sm font-bold">
            Checkout via Service Area
          </span>
          <div className="flex items-center gap-1 lg:min-w-[124px]">
            {settings?.checkout_service_area_status === "available" ? (
              <img src={Success} className="w-[24px] h-[24px]" alt="Success" />
            ) : (
              <img src={Error} className="w-[24px] h-[24px]" alt="Error" />
            )}
            <span className="text-[#344B67] dark:text-white text-sm font-bold capitalize hidden md:block">
              {settings?.checkout_service_area_status}
            </span>
          </div>
        </div>

        <div className="flex justify-between items-center mb-4">
          <span className="text-[#344B67] dark:text-white text-sm font-bold">
            Checkout via Fee Per Meter
          </span>

          <div className="flex items-center gap-1 lg:min-w-[124px]">
            {settings?.checkout_fee_per_meter_status === "available" ? (
              <img src={Success} className="w-[24px] h-[24px]" alt="Success" />
            ) : (
              <img src={Error} className="w-[24px] h-[24px]" alt="Error" />
            )}
            <span className="text-[#344B67] dark:text-white text-sm font-bold capitalize hidden md:block">
              {settings?.checkout_fee_per_meter_status}
            </span>
          </div>
        </div>

        <div className="flex justify-between items-center mb-4">
          <span className="text-[#344B67] dark:text-white text-sm font-bold">Service Fee Status</span>
          <div className="flex items-center gap-1 lg:min-w-[124px]">
            {settings?.platform_charges?.service_fee?.status === "available" ? (
              <img src={Success} className="w-[24px] h-[24px]" alt="Success" />
            ) : (
              <img src={Error} className="w-[24px] h-[24px]" alt="Error" />
            )}
            <span className="text-[#344B67] dark:text-white text-sm font-bold capitalize hidden md:block">
              {settings?.platform_charges?.service_fee?.status}
            </span>
          </div>
        </div>
        <div className="flex justify-between items-center mb-4">
          <span className="text-[#344B67] dark:text-white text-sm font-bold">Service Fee Limit</span>
          <span className="text-[#344B67] dark:text-white text-sm font-bold lg:min-w-[124px]">
            NGN {settings?.platform_charges?.service_fee?.limit}
          </span>
        </div>
        <div className="flex justify-between items-center mb-4">
          <span className="text-[#344B67] dark:text-white text-sm font-bold">
            Service Fee Percentage
          </span>
          <span className="text-[#344B67] dark:text-white text-sm font-bold lg:min-w-[124px]">
            {settings?.platform_charges?.service_fee?.percentage}%
          </span>
        </div>
        <div className="flex justify-between items-center mb-4">
          <span className="text-[#344B67] dark:text-white text-sm font-bold">
            Sms Notification Provider
          </span>
          <span className="text-[#344B67] dark:text-white text-sm font-bold capitalize lg:min-w-[124px]">
            {settings?.sms_notification_provider}
          </span>
        </div>
        <div className="flex justify-between items-center mb-4">
          <span className="text-[#344B67] dark:text-white text-sm font-bold">
            Mail Notification Provider
          </span>
          <span className="text-[#344B67] dark:text-white text-sm font-bold capitalize lg:min-w-[124px]">
            {settings?.mail_notification_provider}
          </span>
        </div>
        <div className="flex justify-between items-center mb-4">
          <span className="text-[#344B67] dark:text-white text-sm font-bold">
            Push Notification Provider
          </span>
          <span className="text-[#344B67] dark:text-white text-sm font-bold capitalize lg:min-w-[124px]">
            {settings?.push_notification_provider}
          </span>
        </div>
      </div>

      <div className="px-3 py-4 sm:px-8 sm:py-8 border dark:border-none bg-white dark:bg-[#121212] rounded-md">
        <div className="flex justify-between items-center mb-4">
          <span className="text-[#344B67] dark:text-white text-sm font-bold">
            All Restaurants Operation
          </span>
          <div className="flex items-center gap-1 lg:min-w-[124px]">
            {settings?.restaurant_operating_status === "available" ? (
              <img src={Success} className="w-[24px] h-[24px]" alt="Success" />
            ) : (
              <img src={Error} className="w-[24px] h-[24px]" alt="Error" />
            )}
            <span className="text-[#344B67] dark:text-white text-sm font-bold capitalize hidden md:block">
              {settings?.restaurant_operating_status}
            </span>
          </div>
        </div>

        <div className="flex justify-between items-center mb-4">
          <span className="text-[#344B67] dark:text-white text-sm font-bold">All Store Operation</span>
          <div className="flex items-center gap-1 lg:min-w-[124px]">
            {settings?.store_operating_status === "available" ? (
              <img src={Success} className="w-[24px] h-[24px]" alt="Success" />
            ) : (
              <img src={Error} className="w-[24px] h-[24px]" alt="Error" />
            )}
            <span className="text-[#344B67] dark:text-white text-sm font-bold capitalize hidden md:block">
              {settings?.store_operating_status}
            </span>
          </div>
        </div>

        <div className="flex justify-between items-center mb-4">
          <span className="text-[#344B67] dark:text-white text-sm font-bold">
            Multiple Store Checkout
          </span>
          <div className="flex items-center gap-1 lg:min-w-[124px]">
            {settings?.multiple_outlet_checkout_status === "available" ? (
              <img src={Success} className="w-[24px] h-[24px]" alt="Success" />
            ) : (
              <img src={Error} className="w-[24px] h-[24px]" alt="Error" />
            )}
            <span className="text-[#344B67] dark:text-white text-sm font-bold capitalize hidden md:block">
              {settings?.multiple_outlet_checkout_status}
            </span>
          </div>
        </div>

        <div className="flex justify-between items-center mb-4">
          <span className="text-[#344B67] dark:text-white text-sm font-bold">Automatic Shipping</span>
          <div className="flex items-center gap-1 lg:min-w-[124px]">
            {settings?.automatic_shipping_status === "available" ? (
              <img src={Success} className="w-[24px] h-[24px]" alt="Success" />
            ) : (
              <img src={Error} className="w-[24px] h-[24px]" alt="Error" />
            )}
            <span className="text-[#344B67] dark:text-white text-sm font-bold capitalize hidden md:block">
              {settings?.automatic_shipping_status}
            </span>
          </div>
        </div>

        <div className="flex justify-between items-center mb-4">
          <span className="text-[#344B67] dark:text-white text-sm font-bold">Paystack Payment</span>
          <div className="flex items-center gap-1 lg:min-w-[124px]">
            {settings?.pay_stack_payment_status === "available" ? (
              <img src={Success} className="w-[24px] h-[24px]" alt="Success" />
            ) : (
              <img src={Error} className="w-[24px] h-[24px]" alt="Error" />
            )}
            <span className="text-[#344B67] dark:text-white text-sm font-bold capitalize hidden md:block">
              {settings?.pay_stack_payment_status}
            </span>
          </div>
        </div>

        <div className="flex justify-between items-center mb-4">
          <span className="text-[#344B67] dark:text-white text-sm font-bold">Ogwugo Payment</span>
          <div className="flex items-center gap-1 lg:min-w-[124px]">
            {settings?.ogwugo_payment_status === "available" ? (
              <img src={Success} className="w-[24px] h-[24px]" alt="Success" />
            ) : (
              <img src={Error} className="w-[24px] h-[24px]" alt="Error" />
            )}
            <span className="text-[#344B67] dark:text-white text-sm font-bold capitalize hidden md:block">
              {settings?.ogwugo_payment_status}
            </span>
          </div>
        </div>

        <div className="flex justify-between items-center mb-4">
          <span className="text-[#344B67] dark:text-white text-sm font-bold">Flutterwave Payment</span>
          <div className="flex items-center gap-1 lg:min-w-[124px]">
            {settings?.flutter_wave_payment_status === "available" ? (
              <img src={Success} className="w-[24px] h-[24px]" alt="Success" />
            ) : (
              <img src={Error} className="w-[24px] h-[24px]" alt="Error" />
            )}
            <span className="text-[#344B67] dark:text-white text-sm font-bold capitalize hidden md:block">
              {settings?.flutter_wave_payment_status}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActiveMerchandise from "../store/active";
import InActiveMerchandise from "../store/inactive";
import { getOutletFromCookies } from "../../../../utils/Auth";
import AllMerchandise from "./all";

const tops = [
  { name: "All", href: "all" },
  { name: "Active", href: "active" },
  { name: "Inactive", href: "inactive" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Merchandise() {
  const navigate = useNavigate();
  const outlet = getOutletFromCookies();
  const outlet_name = outlet?.store_name;
  const DEFAULT_ACTIVE_TAB = "all";
  const { active_tab } = useParams();
  useEffect(() => {
    if (!active_tab) {
      navigate(`/admin/${outlet_name}/merchandise/${DEFAULT_ACTIVE_TAB}/1`);
    }
  });

  const toggle = (tab) => {
    if (active_tab !== tab) {
      navigate(`/admin/${outlet_name}/merchandise/${tab.href}/1`);
    }
  };

  return (
    <div className="merchandise-page">
      {/* <div className="mb-10">
        <h1 className="text-3xl font-semibold text-gray-900">
          {active_tab === "active"
            ? "Active Merchandise"
            : active_tab === "inactive"
            ? "Inactive Merchandise"
            : active_tab === "all"
            ? "All Merchandise"
            : ""}
        </h1>
      </div> */}
      <div className="sm:block">
        <div className="border-b border-gray-200 dark:border-none">
          <nav
            className="-mb-px flex space-x-8 overflow-x-scroll sm:overflow-hidden"
            aria-label="Tabs"
          >
            {tops.map((tab) => (
              <button
                key={tab.name}
                onClick={() => {
                  toggle(tab);
                }}
                className={classNames(
                  tab.href === active_tab
                    ? "border-primary text-primary font-bold"
                    : "border-transparent text-gray-500 dark:text-[#BCBCBC] hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab.href === active_tab ? "page" : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div>
        {active_tab === "active" && <ActiveMerchandise />}
        {active_tab === "inactive" && <InActiveMerchandise />}
        {active_tab === "all" && <AllMerchandise />}
      </div>
    </div>
  );
}

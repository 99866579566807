import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import moment from "moment";
import Button from "../../../../../components/buttons/buttons";
import EmptyState from "../../../../../assets/images/emptyState/orders.svg";
import TableSkeleton from "../../../../../components/skeleton/table";
import Delivered from "../../../../../assets/images/icons/delivered.svg";
import Unshipped from "../../../../../assets/images/icons/unshipped.svg";
import Shipped from "../../../../../assets/images/icons/shipped.svg";
import Closed from "../../../../../assets/images/icons/closed.svg";
import ViewOrderModal from "../../../../../components/modals/viewOrder/foodPackageOrder";
import { getMySubscriptionFoodOrder } from "../../../../../redux/packageSubscriptionFoodOrder/packageSubscriptionFoodOrderAction";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function MyFoodPackageOrders() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const viewOrderModalRef = useRef(null);
  const { myPackages, isLoading } = useSelector(
    (state) => state.packageSubscriptionFoodOrder
  );

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date();
  var year = d.getUTCFullYear();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getMySubscriptionFoodOrder());
    // eslint-disable-next-line
  }, []);

  // const goToTrack = (id) => {
  //   navigate(`/track/${id}`);
  // };

  const groupedData = myPackages?.data?.reduce((acc, obj) => {
    const d = new Date(obj.created_at);
    let month = months[d.getMonth()];
    if (!acc[month]) {
      acc[month] = [];
    }
    acc[month].push(obj);
    return acc;
  }, {});

  return (
    <>
      <div className="text-left ">
        {isLoading ? (
          <TableSkeleton />
        ) : myPackages?.data?.length > 0 ? (
          Object.entries(groupedData).map(([month, objs]) => (
            <div key={month}>
              <h1 className=" text-xl font-medium py-6 uppercase ">
                {month} {year}
              </h1>
              <div className="sm:border-b-2 dark:border-none"></div>
              <ul className="sm:p-2 my-1">
                {objs.map((obj, index) => (
                  <li
                    key={index}
                    className="border-b border-gray-300 dark:border-none grid grid-cols-4 sm:grid-cols-5 gap-2 items-center py-2"
                  >
                    <div className="hidden sm:flex text-base">
                      <p>{moment(obj?.created_at).format("MMM DD, HH:MM")}</p>
                    </div>

                    {obj?.status === "delivered" && (
                      <div className="flex gap-1 sm:gap-3 items-center">
                        <img
                          src={Delivered}
                          alt="icon"
                          className="w-3 sm:w-4 h-3 sm:h-4 mt-1"
                        />
                        <p className="text-[#00D220] sm:ml-1 text-sm sm:text-lg font-medium">
                          {obj?.status}
                        </p>
                      </div>
                    )}
                    {obj?.status === "shipped" && (
                      <div className="flex gap-1 sm:gap-3 items-center">
                        <img
                          src={Shipped}
                          alt="icon"
                          className="w-3 sm:w-4 h-3 sm:h-4 mt-1"
                        />
                        <p className="text-[#046ECF] sm:ml-1 text-sm sm:text-lg font-medium">
                          {obj?.status}
                        </p>
                      </div>
                    )}
                    {obj?.status === "unshipped" && (
                      <div className="flex gap-1 sm:gap-3 items-center">
                        <img
                          src={Unshipped}
                          alt="icon"
                          className="w-3 sm:w-4 h-3 sm:h-4 mt-1"
                        />
                        <p className="text-primary sm:ml-1 text-sm sm:text-lg font-medium">
                          {obj?.status}
                        </p>
                      </div>
                    )}
                    {obj?.status === "closed" && (
                      <div className="flex gap-1 sm:gap-3 items-center">
                        <img
                          src={Closed}
                          alt="icon"
                          className="w-3 sm:w-4 h-3 sm:h-4 mt-1"
                        />
                        <p className="text-[#FFA927] sm:ml-1 text-sm sm:text-lg font-medium">
                          {obj?.status}
                        </p>
                      </div>
                    )}
                    {obj?.status === "queued" && (
                      <div className="flex gap-1 sm:gap-3 items-center">
                        <img
                          src={Closed}
                          alt="icon"
                          className="w-3 sm:w-4 h-3 sm:h-4 mt-1"
                        />
                        <p className="text-[#FFA927] sm:ml-1 text-sm sm:text-lg font-medium">
                          {obj?.status}
                        </p>
                      </div>
                    )}

                    <p className="text-gray-400 text-sm sm:text-base ml-4 font-normal">
                      {obj?.order_items?.length} item
                      {obj?.order_items?.length > 1 && "s"}
                    </p>
                    <p className="text-base sm:text-lg font-medium">
                      ₦{numberWithCommas(obj?.order_amount)}
                    </p>
                    <Button.Secondary
                      title={"View"}
                      type="button"
                      onClick={() => {
                        viewOrderModalRef.current.open();
                        viewOrderModalRef.current.getId(obj?.id);
                      }}
                    />
                  </li>
                ))}
              </ul>
            </div>
          ))
        ) : (
          <div>
            <div className="flex justify-center py-12">
              <img src={EmptyState} alt="Empty state" className="mt-10" />
            </div>
            <p className="my-10 text-center mb-10">
              You do not have any orders yet.
            </p>
          </div>
        )}
      </div>
      <ViewOrderModal ref={viewOrderModalRef} visibility={false} />
    </>
  );
}

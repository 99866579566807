import React, { useEffect, useMemo, useState, useLayoutEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/buttons/buttons";
import Table from "../../../components/tables/tableCols1";
import TableSkeleton from "../../../components/skeleton/table";
import TableFilter from "../../../components/tables/tableFilter";
import Business from "../../../assets/images/icons/BusinessEmpty.svg";
import { Pagination } from "../../../components/pagination/pagination";
import {
  filterBusinessAction,
  searchBusinessAction,
  filterBusinessActionNoPage,
} from "../../../redux/business/businessActions";
import { writeFileWithXLSX } from "../../../components/exports/xlsx";
import { AllFiltersModal } from "../../../components/modals/allFiltersModal";
import FilterBusinessDropDown from "../../../components/dropDown/filter/filterBusiness";
import businessType from "../../../redux/business/businessTypes";

export default function UnApprovedBusinesses() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let params = useParams();
  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);
  const [isFilterOpen, setFilterState] = useState(false);
  const { allBusinesses, filtering, searching, filterNoPage } = useSelector(
    (state) => state.business
  );

  const [payLoad] = useState({
    management_approved: 0,
    // start_date: lastMonth,
    // end_date: new Date(),
  });

  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });

  useEffect(() => {
    dispatch(filterBusinessAction(payLoad, params.page));
    dispatch(filterBusinessActionNoPage(payLoad));
  }, [dispatch, payLoad, params]);
  const allUnapproved = allBusinesses?.data;

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((business, index) => {
        let single = {
          num: <span className="text-base font-semibold">{index + 1}</span>,
          name: business?.business_name,
          email: business?.business_email,
          location: business.business_city,
          status:
            business.management_approved === true ? (
              <span className="text-green-400 font-bold">Active</span>
            ) : (
              <span className="text-primary font-bold">Inactive</span>
            ),
          phone: business?.business_phone,

          business: business,
        };
        outputArray.push({ single });
        return true;
      });
      return outputArray;
    }
  }
  useLayoutEffect(() => {
    const dataSet = createTableData(allUnapproved);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBusinesses]);

  function createTableNoPage(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((business, index) => {
        let single = {
          name: business?.business_name,
          email: business?.business_email,
          location: business?.business_city,
          status: business.management_approved === true ? "active" : "inactive",
          phone: business?.business_phone,
        };
        outputArray.push(single);
        return true;
      });
      return outputArray;
    }
  }

  const downloadCSV = () => {
    if (filterNoPage) {
      const newData = createTableNoPage(filterNoPage);
      writeFileWithXLSX(newData, {
        filename: "UnApproved Businesses.xlsx",
        download: true,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allUnapproved);
      setData(dataSet);
      dispatch({
        type: businessType["SEARCH_BUSINESS"],
        payLoad: { data: [] },
      });
    }
  };

  const goToSinglePage = (params) => {
    let id = params?.single?.business?.id;
    navigate(`/admin/business/${id}`);
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchBusinessAction(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);
  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "single.num",
          },
          {
            Header: "Business Name",
            accessor: "single.name",
          },

          {
            Header: "Email",
            accessor: "single.email",
          },
          {
            Header: "Location",
            accessor: "single.location",
          },
          {
            Header: "Status",
            accessor: "single.status",
          },

          {
            Header: "Phone Number",
            accessor: "single.phone",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="">
      <TableFilter
        title={"Business"}
        results={allUnapproved?.length}
        totalResults={allBusinesses?.total}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        payLoad={payLoad}
        page={params.page}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterBusinessDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
        searchPlaceholder="Business name, Email, Phone number"
      >
        <Button.Secondary
          title={"Download CSV"}
          onClick={() => downloadCSV()}
          disabled={!data}
        />
      </TableFilter>

      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white dark:bg-[#121212] rounded-md py-4 px-3">
        {data?.length > 0 ? (
          <>
            {filtering || searching ? (
              <TableSkeleton />
            ) : (
              <Table
                columns={columns}
                data={data?.length > 0 ? data : []}
                onClick={goToSinglePage}
                className="w-full"
                rowClass="hover:shadow-md cursor-pointer"
              />
            )}
          </>
        ) : (
          <div className="text-center">
            {" "}
            <div className="flex justify-center my-12 ">
              <img src={Business} alt="orders" />
            </div>
            <p className="font-bold text-xl my-3">Businesses</p>
            <p className="text-sm text-gray-500">
              You do not have any unapproved businesses yet
            </p>
          </div>
        )}

        <Pagination data={allBusinesses} route={"admin/business/unapproved"} />
      </div>
    </div>
  );
}

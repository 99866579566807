import React, { useMemo, useEffect, useState, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Beat } from "../../../../plugins/spinners.plugin";
import Button from "../../../../components/buttons/buttons";
import Table from "../../../../components/tables/tableCols1";
import TableSkeleton from "../../../../components/skeleton/table";
import TableFilter from "../../../../components/tables/tableFilter";
import { Pagination } from "../../../../components/pagination/pagination";
import EmptyState from "../../../../assets/images/emptyState/restaurant.svg";
import { writeFileWithXLSX } from "../../../../components/exports/xlsx";
import { AllFiltersModal } from "../../../../components/modals/allFiltersModal";
import FilterRestaurantDropDown from "../../../../components/dropDown/filter/filterRestaurant";
import {
  filterRestaurantsAction,
  searchRestaurantsAction,
  getAllRestaurantsNoPagination,
} from "../../../../redux/restaurant/restaurantActions";
import restaurantType from "../../../../redux/restaurant/restaurantTypes";

export default function Inactive() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page } = useParams();

  const [isFilterOpen, setFilterState] = useState(false);
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });
  const [payLoad] = useState({
    management_approved: false,
  });

  const { allRestaurants, downloading } = useSelector(
    (state) => state.restaurant
  );
  const { filtering, searching } = useSelector((state) => state.restaurant);

  useEffect(() => {
    dispatch(filterRestaurantsAction(payLoad, page));
    // eslint-disable-next-line
  }, [page]);

  function createTableData(myData) {
    let dataToMap = myData;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((restaurant, index) => {
        let show = {
          sn: index + 1,
          name: restaurant.restaurant_name,
          phone: restaurant.restaurant_phone,
          status: (
            <>
              {restaurant.management_approved ? (
                <span className="text-[#00D220] font-bold">Approved</span>
              ) : (
                <span className="text-primary font-bold">Unapproved</span>
              )}
            </>
          ),
          location: restaurant.restaurant_city,
          email: restaurant.restaurant_email,
          restaurant: restaurant,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  function createTableDataDownload(myData) {
    let dataToMap = myData;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((restaurant, index) => {
        let show = {
          sn: index + 1,
          name: restaurant.restaurant_name,
          phone: restaurant.restaurant_phone,
          status: restaurant.management_approved ? "Approved" : "Unapproved",
          location: restaurant.restaurant_city,
          email: restaurant.restaurant_email,
        };
        outputArray.push(show);
        return true;
      });
      return outputArray;
    }
  }

  useLayoutEffect(() => {
    const dataSet = createTableData(allRestaurants?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRestaurants]);

  function goToSinglePage(params) {
    let id = params?.show?.restaurant?.id;
    navigate(`/admin/restaurants/${id}`);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allRestaurants?.data);
      setData(dataSet);
      dispatch({
        type: restaurantType["SEARCH_RESTAURANT"],
        payLoad: { data: [] },
      });
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchRestaurantsAction(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const handleDownload = async () => {
    const response = await dispatch(
      getAllRestaurantsNoPagination({
        management_approved: false,
        pagination: 0,
      })
    );
    if (response) {
      const newData = createTableDataDownload(response?.data);
      writeFileWithXLSX(newData, {
        filename: "Inactive_Restaurants.xlsx",
        download: true,
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.sn",
          },
          {
            Header: "Restaurant Name",
            accessor: "show.name",
          },
          {
            Header: "Phone Number",
            accessor: "show.phone",
          },
          {
            Header: "Status",
            accessor: "show.status",
          },
          {
            Header: "Location",
            accessor: "show.location",
          },
          {
            Header: "Email",
            accessor: "show.email",
          },
        ],
      },
    ],
    []
  );
  return (
    <div>
      <TableFilter
        results={allRestaurants?.to}
        totalResults={allRestaurants?.total}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterRestaurantDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
        searchPlaceholder={"Restaurant Name, Email or Phone Number"}
      >
        <Button.Secondary
          title={downloading ? <Beat color={"#CE2600"} /> : "Download CSV"}
          type="button"
          onClick={handleDownload}
        />
      </TableFilter>

      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white dark:bg-[#121212] rounded-md py-4 px-3">
        {filtering || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        ) : (
          <div className="py-36 text-center">
            <div className="flex justify-center">
              <img src={EmptyState} className="w-auto" alt="no data" />
            </div>
            <h3 className="mt-5 text-xl font-bold">Restaurant</h3>
            <p className="mt-2 text-sm text-[#9CA3AF]">
              You do not have any inactive restaurant yet.
            </p>
          </div>
        )}
        <Pagination
          data={allRestaurants}
          route={"admin/restaurants/inactive"}
        />
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getOutletFromCookies } from "../../../../utils/Auth";
import All from "./tabs/all";
import Approved from "./tabs/approved";
import Unapproved from "./tabs/unapproved";

const tabs = [
  { name: "All", href: "all" },
  { name: "Approved", href: "approved" },
  { name: "Unapproved", href: "unapproved" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Food() {
  const DEFAULT_ACTIVE_TAB = "all";
  const navigate = useNavigate();
  const outlet = getOutletFromCookies();
  const { active_tab } = useParams();

  useEffect(() => {
    if (!active_tab) {
      navigate(
        `/admin/${outlet?.restaurant_name}/food/${DEFAULT_ACTIVE_TAB}/1`
      );
    }
    // eslint-disable-next-line
  }, []);

  const toggle = (tab) => {
    if (active_tab !== tab) {
      navigate(`/admin/${outlet?.restaurant_name}/food/${tab.href}/1`);
    }
  };

  return (
    <div data-test="Food-page">
      <div className="sm:block">
        <div className="border-b border-gray-200 dark:border-none">
          <nav
            className="-mb-px flex space-x-8 overflow-x-auto"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => {
                  toggle(tab);
                }}
                className={classNames(
                  tab.href === active_tab
                    ? "border-primary text-primary font-bold"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab.href === active_tab ? "page" : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>

      <div>
        {active_tab === "all" && <All />}
        {active_tab === "approved" && <Approved />}
        {active_tab === "unapproved" && <Unapproved />}
      </div>
    </div>
  );
}

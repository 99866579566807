import React from "react";
import Container from "../../../../components/container/container";

export default function StorePrivacyPolicy() {
  return (
    <Container>
      <div data-test="storePrivacy-page">
        <div className="hidden sm:block bg-white dark:bg-[#121212] px-4 sm:px-14 py-4 text-center sm:text-left w-full sm:max-w-[1200px] mx-auto mb-1 rounded-sm">
          <p className="text-lg text-gray-800 dark:text-[#BCBCBC]">
            Learn about how we manage your data
          </p>
        </div>
        <div className=" justify-center items-center mx-auto pb-10">
          <div className="bg-white dark:bg-[#121212] rounded-sm border border-gray-200 dark:border-none px-4 sm:px-4 sm:max-w-[1200px] mx-auto sm:rounded-sm w-full pb-16 sm:pb-0">
            <h3 className="sm:hidden border-b py-5  flex justify-center text-lg font-medium">
              Learn How We Manage Your Data
            </h3>
            <div className=" border sm:border-hidden sm:shadow-none shadow py-1 sm:py-1 mb-6 my-4 px-4">
              <h3 className="text-primary text-base font-bold my-6 sm:text-4xl ">
                Ogwugo Privacy Policy
              </h3>
              <div className="sm:border-b dark:border-none"></div>
              <p className=" text-sm sm:text-lg sm:text-gray-500 dark:text-[#BCBCBC] sm:mt-8">
                Ogwugo.com is an electronic commerce platforms predominantly
                used by business entities to facilitate electronic commerce and
                such business use does not generally involve the collection of
                personal information of individuals. Ogwugo recognizes the
                importance of privacy as well as the importance of maintaining
                the confidentiality of personal information. This Privacy
                Policyapplies to all products and services provided by us and
                sets out how we may collect, use and disclose information in
                relation to users of the Sites.The information you share with
                Ogwugo allows us to provide the products and services you need
                and want while giving you the very best shopping experience. Our
                founder reminded us that a promise we make is a promise we keep,
                and its our promise to customers that we respect the trust you
                place in us and the privacy of the information you share. Part
                of providing superior customer service includes making sure that
                we are building a relationship of trust with customers. Our way
                of doing that is to let you know in a clear, prominent, and
                easily accessible way how we collect, use, share, and above all
                protect your personal information.<br></br>Our Policy outlines:
                How and why we collect your personal information; How your
                personal information is used and protected; When and with whom
                we share your personal information; and What choices you can
                make about how we collect, use, and share your personal
                information. You may use our services and products via a mobile
                device either through mobile applications or mobile optimized
                websites. This Privacy Policy also applies to such use of our
                services and products.
              </p>
              <h3 className="text-base my-6 font-bold  sm:text-4xl">
                Use of Information
              </h3>
              <div className="sm:border-b dark:border-none"></div>
              <p className=" text-sm sm:text-lg sm:text-gray-500 dark:text-[#BCBCBC] sm:mt-8">
                Use of Personal Data If you provide any Personal Data to us, you
                are deemed to have authorized us to collect, retain and use that
                Personal Data for the following purposes:
                <div>
                  <ol>
                    <li className="py-1">1. Verifying your identity</li>
                    <li className="py-1">
                      2. Verifying your eligibility to register as a user of the
                      Sites
                    </li>{" "}
                    <li className="py-1">
                      {" "}
                      3. Processing your registration as a user, providing you
                      with a log-in ID for the Sites and maintaining and
                      managing your registration
                    </li>
                    <li className="py-1">
                      4. To facilitate communication between buyers and sellers
                      on the Sites and processing your transactions on the Sites
                    </li>
                    <li className="py-1">
                      {" "}
                      5. Performing research or statistical analysis in order to
                      improve the content and layout of the Sites, to improve
                      our product offerings and services and for marketing and
                      promotional purposes
                    </li>
                    <li className="py-1">
                      6. Subject to obtaining your consent in such form as may
                      be required under the applicable law, we (including our
                      affiliated companies and their designated Service
                      Providers (as defined in C.1 below) may use your name,
                      phone number, residential address, email address, fax
                      number and other data (Marketing Data) to provide notices,
                      surveys, product alerts, communications and other
                      marketing materials to you relating to goods and services
                      offered by us on the Sites including but not limited to
                      Gold Suppliers membership, Verified Members membership,
                      Free Members membership (each of Gold Suppliers
                      membership, Verified Members membership, and Free Members
                      membership, a Membershi, and collectively, the Memberships
                      the value added services ancillary to the Memberships, and
                      other products and services offered by us and/or our
                      affiliated companies and their designated Service
                      Providers from time to time to members of the Sites
                    </li>
                    <li className="py-1">
                      7. If you voluntarily submit any information to the Sites
                      for publication on the Sites through the publishing tools,
                      including but not limited to, Company Profile, Product
                      Catalog, Trade Leads, TrustPass Profile and any discussion
                      forum, then you are deemed to have given consent to the
                      publication of such information on the Sites (Voluntary
                      Information)
                    </li>
                    <li className="py-1">
                      8. Making such disclosures as may be required for any of
                      the above purposes or as required by law, regulations and
                      guidelines or in respect of any investigations, claims or
                      potential claims ought on or against us.
                    </li>
                  </ol>
                </div>
              </p>
              <h3 className="text-base my-6 font-bold sm:text-4xl">
                Information We Collect
              </h3>
              <div className="sm:border-b dark:border-none"></div>
              <p className=" text-sm mb-3 sm:text-lg sm:text-gray-500 dark:text-[#BCBCBC] sm:mt-8">
                <ol>
                  <li className="py-1">
                    {" "}
                    1. Your privacy is important to us and we have taken steps
                    to ensure that we do not collect more information from you
                    than is necessary for us to provide you with our services
                    and to protect your account.
                  </li>
                  <li className="py-1">
                    2. Information including, but not limited to, user name,
                    address, phone number, fax number, email address, gender,
                    date and/or year of birth and user preferences (Registration
                    Information) may be collected at the time of user
                    registration on the Sites.
                  </li>{" "}
                  <li className="py-1">
                    3. In connection with any transaction and payment services
                    or services under our buyer protection schemes we provide on
                    the Sites, information, including but not limited to, bank
                    account numbers, billing and delivery information,
                    credit/debit card numbers and expiration dates and tracking
                    information from cheques or money orders (Account
                    Information) may be collected to, among other things,
                    facilitate the sale and purchase as well as the settlement
                    of purchase price of the products or services transacted on
                    or procured through the Sites.
                  </li>{" "}
                  <li className="py-1">
                    4. We record and retain details of users activities on the
                    Sites. Information relating to such transactions including,
                    but not limited to, the types and specifications of the
                    goods, pricing and delivery information and any trade
                    dispute records (Activities Information) may be collected
                    when sale and purchase transactions are conducted on or
                    facilitated through the Sites.
                  </li>
                  <li className="py-1">
                    5. From time to time, we collect information about our users
                    and prospective users during trade shows, industry events
                    and other functions. The information we may collect at these
                    locations may include, but is not limited to, user name,
                    address, phone number, fax number and email address (Event
                    Information).
                  </li>
                  <li className="py-1">
                    6. We record and retain records of users' buying and owsing
                    activities on our platform including but not limited to IP
                    addresses, owsing patterns and buyer behavioral patterns. In
                    addition, we gather statistical information about the Sites
                    and visitors to the Sites including, but not limited to, IP
                    addresses, owser software, operating system, software and
                    hardware attributes, pages viewed, number of sessions and
                    unique visitors (together owsing Information).
                  </li>
                  <li className="py-1">
                    7. Registration Information, Account Information, Activities
                    Information, Event Information and owsing Information
                    generally relate to business entities and are together
                    referred to as business data (Business Data).
                  </li>
                  <li className="py-1">
                    8. It is mandatory for users of the Sites to provide certain
                    categories of Business Data and Personal Data (as specified
                    at the time of collection). In the event that users do not
                    provide any or sufficient Business Data and/or Personal Data
                    marked as mandatory, we may not be able to complete the
                    registration process or provide such users with our products
                    or services
                  </li>
                </ol>
              </p>
              <h3 className="text-base my-6 font-bold sm:text-4xl">
                Disclosure of Personal Data
              </h3>
              <div className="sm:border-b dark:border-none"></div>
              <p className=" text-sm mb-3 sm:text-lg sm:text-gray-500 dark:text-[#BCBCBC] sm:mt-8">
                <ol>
                  <li className="py-1">
                    1. You further agree that we may disclose and transfer
                    (whether within or outside the jurisdiction of the Ogwugo
                    that you are contracting with) your Personal Data to service
                    providers engaged by us and/or other affiliated companies of
                    Ogwugo to assist us with providing you with our services
                    (including but not limited to data entry, database
                    management, promotions, products and services alerts,
                    delivery services, payment extension services, membership
                    authentication and verification services and logistics
                    services) (Service Providers) These Service Providers are
                    under a duty of confidentiality to us and are only permitted
                    to use your Personal Data in connection with the purposes
                    specified at B.1 to B.9 above, and not for their own
                    purposes
                  </li>
                  <li className="py-1">
                    {" "}
                    2. You agree that we may disclose and transfer (whether
                    within or outside the jurisdiction of Ogwugo that you are
                    contracting with), for the purposes specified at B.1 to B.9
                    above, your Personal Data to other affiliated companies
                    and/or their designated Service Providers of Ogwugo
                    services, which comprises a group of companies operating
                    leading online and mobile marketplaces in consumer and
                    business-to-business commerce, as well as cloud computing
                    and other services.
                  </li>
                  <li className="py-1">
                    3. When necessary we may also disclose and transfer (whether
                    within or outside the jurisdiction of Ogwguo that you are
                    contracting with) your Personal Data to our professional
                    advisers, law enforcement agencies, insurers, government and
                    regulatory and other organizations for the purposes
                    specified at B.9 above.
                  </li>
                  <li className="py-1">
                    {" "}
                    4. Any Personal Data supplied by you will be retained by us
                    and will be accessible by our employees, any Service
                    Providers engaged by us and third parties referred to at C.2
                    and C.3 above, for or in relation to any of the purposes
                    stated in B.1 to B.9 above.
                  </li>

                  <li className="py-1">
                    {" "}
                    5. All Voluntary Information may be made publicly available
                    on the Sites and therefore accessible by any internet user.
                    Any Voluntary Information that you disclose to us becomes
                    public information and you relinquish any proprietary rights
                    (including but not limited to the rights of confidentiality
                    and copyright) in such information. You should exercise
                    caution when deciding to include personal or proprietary
                    information in the Voluntary Information that you submit to
                    us.
                  </li>
                  <li className="py-1">
                    6. We may share your Account Information with banks or
                    vendors to enable your transactions on the Sites to be
                    completed. In addition, we may use your Account Information
                    to determine your credit-worthiness and, in the process of
                    such determination, we may need to make such Account
                    Information available to banks or credit agencies. While we
                    have in place up-to-date technology and internal procedures
                    to keep your Account Information and other Personal Data
                    secure from intruders, there is no guarantee that such
                    technology or procedures can eliminate all of the risks of
                    theft, loss or misuse.
                  </li>
                  <li className="py-1">
                    7. We may provide statistical information to third parties,
                    but when we do so, we do not provide personally-identifying
                    information without your permission.
                  </li>
                  <li className="py-1">
                    8. We have established relationships with other parties and
                    websites to offer you the benefit of products and services
                    which we do not offer. We offer you access to these other
                    parties and their websites either through the use of
                    hyperlinks to these sites from the Sites or through offering
                    co-anded sites in which both we and other parties share the
                    same uniform resource locator, domain name or pages within a
                    domain name on the Internet. In some cases you may be
                    required to submit personal information to register or apply
                    for products or services provided by such third parties or
                    co-anded partners. This Privacy Policy does not apply to
                    these third party sites or co-anded sites. The privacy
                    policies of those other parties may differ from ours, and we
                    have no control over the information that you submit to
                    those third parties. You should read the relevant privacy
                    policy for those third party sites and co-anded sites before
                    responding to any offers, products or services advertised by
                    those parties
                  </li>
                </ol>
              </p>
              <h3 className="text-base my-6 font-bold sm:text-4xl">
                How we collect User Information
              </h3>
              <div className="sm:border-b dark:border-none"></div>
              <p className=" text-sm mb-3 sm:text-lg sm:text-gray-500 dark:text-[#BCBCBC] sm:mt-8">
                We collect information from users when they place orders,
                subscribe to a newsletter or enter information on our app.
              </p>
              <p className=" text-sm mb-3 sm:text-lg sm:text-gray-500 dark:text-[#BCBCBC] sm:mt-8">
                We may use the information we collect from users when they
                register, make a purchase, sign up for our newsletter, respond
                to a survey or marketing communication, surf the website, or use
                certain other site features in the following ways To personalize
                your experience and to allow us to deliver the type of content
                and product offerings in which you are most interested To
                improve our website in order to better serve users To administer
                a contest, promotion, survey or other site feature To quickly
                process User transactions To send periodic emails regarding
                order or other products and services To follow up with them
                after correspondence (live chat, email or phone inquiries)
              </p>
              <h3 className="text-base my-6 font-bold sm:text-4xl">
                How we store and Protect User Information
              </h3>
              <div className="sm:border-b dark:border-none"></div>
              <p className=" text-sm mb-3 sm:text-lg sm:text-gray-500 dark:text-[#BCBCBC] sm:mt-8">
                Our website is scanned on a regular basis for security holes and
                known vulnerabilities in order to make visits to our site as
                safe as possible.We use regular Malware Scanning Your personal
                information is contained behind secured networks and is only
                accessible by a limited number of persons who have special
                access rights to such systems, and are required to keep the
                information confidential. In addition, all sensitive/credit
                information supplied by users are encrypted via Secure Socket
                Layer (SSL) technology We implement a variety of security
                measures when a user places an order enters, submits, or
                accesses their information to maintain the safety of your
                personal information All transactions are processed through a
                gateway provider and are not stored or processed on our servers.
              </p>
              <h3 className="text-base my-6 font-bold sm:text-4xl">
                How we disclose User Information
              </h3>
              <div className="sm:border-b dark:border-none"></div>
              <p className=" text-sm mb-3 sm:text-lg sm:text-gray-500 dark:text-[#BCBCBC] sm:mt-8">
                User information can only be disclosed at the consent of the
                user. Information disclosed, such as location, email, phone
                number, and mail address will be used to process user
                transactions on our site.Unable to return Under the applicable
                laws, you have the right of access to personal information held
                by us and to request correction of the information. If you have
                any questions regarding this Privacy Policy or if you wish to
                access or correct your Personal Data, you may send your request
                in writing to the following address: Legal Counsel, The Legal
                Department Ogwugo.com, BETHEL PLAZA, 36 GARDEN AVENUE, ENUGU,
                ENUGU STATE, NIGERIA. Ogwugo Advertising Co., Ltd., No. 16B,
                Paradise City Estate, GRA, Enugu, Nigeria. In accordance with
                the applicable laws, we reserve the right to charge you a
                reasonable fee for the processing of any data access or
                correction request.We use cookies to store specific information
                about you and track your visits to the Sites. It is not uncommon
                for websites to use cookies to enhance identification of their
                users. A cookie is a small amount of data that is sent to your
                browser and stored on your computer or hard drive. A cookie can
                be sent to your computer's hard drive only if you access the
                Sites using a computer. If you do not de-activate or erase the
                cookie, each time you use the same computer to access the Sites,
                our web servers will be notified of your visit to the Sites and
                in turn we may have knowledge of your visit and the pattern of
                your usage.Generally, we use cookies to identify you and enable
                us to
                <ol type="I">
                  <li className="py-1">
                    access your Registration Information or Account Information
                    so you do not have to re-enter it.
                  </li>
                  <li className="py-1">
                    gather statistical information about usage by users.
                  </li>
                  <li className="py-1">
                    research visiting patterns and help target advertisements
                    based on user interests.
                  </li>{" "}
                  <li className="py-1">
                    assist our partners to track user visits to the Sites and
                    process orders and
                  </li>{" "}
                  <li className="py-1">
                    track progress and participation in promotions.
                  </li>
                </ol>
              </p>
              <h3 className="text-base my-6 font-bold sm:text-4xl">
                Security Measures
              </h3>
              <div className="sm:border-b dark:border-none"></div>
              <p className=" text-sm mb-3 sm:text-lg sm:text-gray-500 dark:text-[#BCBCBC] sm:mt-8">
                We employ commercially reasonable security methods to prevent
                unauthorized access to the Sites, to maintain data accuracy and
                to ensure the correct use of the information we hold. For
                registered users of the Sites, your Registration Information and
                Account Information (if any) can be viewed and edited through
                your account, which is protected by a password. We recommend
                that you do not divulge your password to anyone. Our personnel
                will never ask you for your password in an unsolicited phone
                call or in an unsolicited email. If you share a computer with
                others, you should not choose to save your log-in information
                (e.g., user ID and password) on that shared computer. Remember
                to sign out of your account and close your owser window when you
                have finished your session. No data transmission over the
                internet or any wireless network can be guaranteed to be
                perfectly secure. As a result, while we try to protect the
                information we hold for you, we cannot guarantee the security of
                any information you transmit to us and you do so at your own
                risk.
              </p>
              <h3 className="text-base my-6 font-bold sm:text-4xl">
                Changes to Privacy Policy
              </h3>
              <div className="sm:border-b dark:border-none"></div>
              <p className=" text-sm mb-3 sm:text-lg sm:text-gray-500 dark:text-[#BCBCBC] sm:mt-8">
                Any changes to this Privacy Policy will be communicated by us
                posting an amended and restated Privacy Policy on the Sites.
                Once posted on the Sites the new Privacy Policy will be
                effective immediately. You agree that any information we hold
                about you (as described in this Privacy Policy and whether or
                not collected prior to or after the new Privacy Policy became
                effective) will be governed by the latest version of the Privacy
                Policy
              </p>
              <h3 className="text-base my-6 font-bold sm:text-4xl">
                Your Feedback
              </h3>
              <div className="sm:border-b dark:border-none"></div>
              <p className=" text-sm mb-3 sm:text-lg sm:text-gray-500 dark:text-[#BCBCBC] sm:mt-8">
                We welcome your input regarding our Privacy Policy and any
                comments on the services we provide to you. You may send us your
                comments and responses by post to: The Legal Department,
                Ogwugo.com 16B Paradise city estate GRA , Enugu, Nigeria.
              </p>
              <h3 className="text-base my-6 font-bold sm:text-4xl">
                Help Desk
              </h3>
              <div className="sm:border-b dark:border-none"></div>
              <p className=" text-sm mb-3 sm:text-lg sm:text-gray-500 dark:text-[#BCBCBC] sm:mt-8">
                Endeavour to contact the ogwugo customer service when faced with
                confusing challenges, to avoid making unwarranted mistakes such
                as:Purchasing the wrong item,making payments to a wrong account,
                Inputting a wrong location, etc.The Customer Care is always at
                your disposal. Feel free to contact.
              </p>
              <h3 className="text-base my-6 font-bold sm:text-4xl">
                Use a payment method with buyer protections
              </h3>
              <div className="sm:border-b dark:border-none"></div>
              <p className=" text-sm mb-3 sm:text-lg sm:text-gray-500 dark:text-[#BCBCBC] sm:mt-8">
                In many cases, credit card companies limit your liability for
                online purchases in cases of fraud. Some online payment systems
                do not share your full credit card number with sellers in order
                to give you extra protections.
              </p>
              <h3 className="text-base my-6 font-bold sm:text-4xl">
                Only send information over secure connections
              </h3>
              <div className="sm:border-b dark:border-none"></div>
              <p className=" text-sm mb-3 sm:text-lg sm:text-gray-500 dark:text-[#BCBCBC] sm:mt-8">
                Look for the "https://" connection in the address bar (and the
                padlock icon in your address bar if youre using Google Chrome or
                Internet Explorer) when transmitting any sensitive information
                like credit card or bank numbers. When accessing financial
                accounts, check that the website has an Extended Validation
                Certificate mdash; the URL or website name should show up as
                green in the URL bar of many modern owsers, meaning the
                organization that operates the website has been validated.
                Ogwugo.com is always at your disposal to provide a secure means
                of transacting online For Ogwugo transactions, you should use
                Ogwugo's only approved payment methods Typing sensitive web
                addresses into your browser's address bar navigate to sensitive
                accounts by clicking a link or copying and pasting the address.
                Instead, type out the web address yourself. But make sure that
                you are inputting the correct address; some typosquatting sites
                look exactly like the real site, but are set up to phish your
                account information. Avoid entering personal information on
                suspicious sitesIf a site is asking for personal information
                beyond what is required to purchase a product or receive a
                service (e.g., bank account information, security question
                answers, or passwords) be suspicious as these types of inquiries
                may be indicative of a phishing attempt. Some sites may be a
                carbon copy of the official site, including logos and text, but
                are set up by fraudsters with the sole purpose of obtaining your
                personal information. Avoid hacked sites and keep an eye on the
                owsers address barIf you click on a link and get instantly
                redirected, that site may have been hacked and contain malware.
                Malware, such as viruses, worms, and Trojan horses, can silently
                install unwanted software on your computer. Some hacked sites
                wont automatically redirect you to a different page, but may
                contain irrelevant and spammy content on the page. Keeping an
                eye on the address bar to ensure the link you click on is the
                one you are delivered to is one way to be vigilant.
              </p>
              <h3 className="text-base my-6 font-bold sm:text-4xl">
                Keep a record of the transaction
              </h3>
              <div className="sm:border-b dark:border-none"></div>
              <p className=" text-sm mb-3 sm:text-lg sm:text-gray-500 dark:text-[#BCBCBC] sm:mt-8">
                Having a digital or paper copy of large transactions can help
                you if you do need to make a return or contest unauthorized
                charges made to your account. Verify Your Purchase/bgt Always
                verify every purchase you make once you have recieved the item.
                This will aid us have a proper documentation of your account's
                progress
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import CLOSE from "../../../assets/images/icons/close.svg";
import CUTLERY from "../../../assets/images/icons/cutlery1.svg";
import Button from "../../buttons/buttons";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export default function ViewMerchFeature() {
  const [isOpen, setIsOpen] = useState(false);
  const { singleFeatured } = useSelector((state) => state.feature);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <Button.Primary
        type={"button"}
        title={"View Feature Request"}
        onClick={openModal}
      />
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-full sm:w-[620px] bg-white dark:bg-[#121212] rounded-3xl py-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg">
                  <div className="pb-5 pt-1 px-6">
                    <div className="sm:block absolute top-4 right-5">
                      <button
                        type="button"
                        className="bg-[#ffeae5] rounded-md p-1 sm:p-3  "
                        onClick={closeModal}
                      >
                        <span className="sr-only">Close</span>
                        <img
                          src={CLOSE}
                          alt="close"
                          className=" w-3 h-3 sm:w-4 sm:h-4"
                        />
                      </button>
                    </div>
                    <div className="text-left mt-2">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium text-black dark:text-white pb-2 border-b mb-8"
                      >
                        Feature Details
                      </Dialog.Title>
                    </div>
                    <div>
                      <h2 className="text-center font-bold text-[40px] my-4">
                        ₦{numberWithCommas(singleFeatured?.featured_amount)}
                      </h2>
                      <div className="my-3 border-b border-[#EEEEEE] pb-3">
                        <p className="text-2xl mb-2">From</p>
                        <div className="flex items-center gap-3">
                          <img
                            src={CUTLERY}
                            className="w-[40px] h-[40px]"
                            alt="icon"
                          />
                          <p className="font-medium text-lg">Ntachi Osa </p>
                        </div>
                        <p className="font-medium">
                          No 2 Chime Avenue, New Haven Enugu
                        </p>
                      </div>
                      <div className="my-3 border-b border-[#EEEEEE] pb-3">
                        <p className="text-2xl mb-2">For</p>
                        <p className="font-medium text-lg">Feature </p>
                        <p className="font-medium">
                          You will get:{" "}
                          {singleFeatured?.featured_duration_in_hours} days{" "}
                        </p>
                      </div>
                      <div className="rounded-[10px] shadow-lg py-3 px-4">
                        <h5 className="border-b border-[#EEEEEE] py-2">
                          Payment Summary
                        </h5>
                        <li className="flex justify-between py-2">
                          <span>Amount</span>
                          <span>
                            ₦{numberWithCommas(singleFeatured?.featured_amount)}
                          </span>
                        </li>
                        <li className="flex justify-between py-2">
                          <span>Discount</span>
                          <span>
                            ₦
                            {numberWithCommas(
                              singleFeatured?.featured_discount
                            )}
                          </span>
                        </li>
                        <li className="flex justify-between py-2 border-t border-[#EEEEEE]">
                          <span>Total</span>
                          <span>
                            ₦
                            {numberWithCommas(
                              Number(singleFeatured?.featured_amount) -
                                Number(singleFeatured?.featured_discount)
                            )}
                          </span>
                        </li>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Table from "../../../../components/tables/tableCols2";
import TableSkeleton from "../../../../components/skeleton/table";
import TableFilter from "../../../../components/tables/tableFilter";
import Button from "../../../../components/buttons/buttons";
import { Beat } from "../../../../plugins/spinners.plugin";
import { writeFileWithXLSX } from "../../../../components/exports/xlsx";
import { Pagination } from "../../../../components/pagination/pagination";
import { ReactComponent as COPY } from "../../../../assets/images/icons/copy1.svg";
import EmptyState from "../../../../assets/images/emptyState/food-package.svg";
import { AllFiltersModal } from "../../../../components/modals/allFiltersModal";
import { copyTextFromTable, concatId } from "../../../../components/helpers";
import FilterFoodPackageOrdersDropDown from "../../../../components/dropDown/filter/filterFoodPackageOrders";
import {
  filterSubscriptionFoodAction,
  searchSubscriptionFoodOrder,
  getAllSubscriptionFoodOrdersNoPagination,
} from "../../../../redux/packageSubscriptionFoodOrder/packageSubscriptionFoodOrderAction";
import packageFoodType from "../../../../redux/packageSubscriptionFoodOrder/packageSubscriptionFoodOrderTypes";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Subscription() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);

  const [isFilterOpen, setFilterState] = useState(false);
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });
  const [payLoad] = useState({
    start_date: lastMonth,
    end_date: new Date(),
  });

  const { allPackageOrders, downloading } = useSelector(
    (state) => state.packageSubscriptionFoodOrder
  );
  const { filtering, searching } = useSelector(
    (state) => state.packageSubscriptionFoodOrder
  );

  useEffect(() => {
    dispatch(filterSubscriptionFoodAction(payLoad, 1));
    // eslint-disable-next-line
  }, []);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order, index) => {
        let show = {
          id: index + 1,
          package_id: (
            <span className="flex items-center">
              #{concatId(order?.id)}
              <button
                type="button"
                onClick={(e) => {
                  copyTextFromTable(e, order?.id);
                }}
              >
                <COPY className="w-[14px] h-[14px] ml-1" />
              </button>
            </span>
          ),
          payment_id: (
            <span className="flex items-center">
              #{concatId(order?.payment_id)}
              <button
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(order?.payment_id);
                }}
              >
                <COPY className="w-[14px] h-[14px] ml-1" />
              </button>
            </span>
          ),
          day: moment(order?.created_at).format("MMM DD, YYYY"),
          price: <p>₦{numberWithCommas(order?.order_amount)}</p>,
          recipient_name: order?.recipient_name,
          recipient_phone: order?.recipient_phone,
          packageData: order,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  function createTableDataDownload(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order, index) => {
        let show = {
          index: index + 1,
          package_id: order?.id,
          payment_id: order?.payment_id,
          date: moment(order?.created_at).format("MMM DD, YYYY"),
          price: order?.order_amount,
          recipient_name: order?.recipient_name,
          recipient_phone: order?.recipient_phone,
        };
        outputArray.push(show);
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allPackageOrders?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPackageOrders]);

  function goToSinglePage(params) {
    let id = params?.show?.packageData?.id;
    navigate(`/admin/orders/package-food/${id}`);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allPackageOrders?.data);
      setData(dataSet);
      dispatch({
        type: packageFoodType["SEARCH_PACKAGE"],
        payLoad: { data: [] },
      });
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchSubscriptionFoodOrder(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const handleDownload = async () => {
    const response = await dispatch(
      getAllSubscriptionFoodOrdersNoPagination({
        ...payLoad,
        pagination: 0,
      })
    );
    if (response) {
      const newData = createTableDataDownload(response?.data);
      writeFileWithXLSX(newData, {
        filename: "Food_Packages_Subscriptions.xlsx",
        download: true,
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.id",
          },
          {
            Header: "Package ID",
            accessor: "show.package_id",
          },
          {
            Header: "Payment ID",
            accessor: "show.payment_id",
          },
          {
            Header: "Date",
            accessor: "show.day",
          },
          {
            Header: "Remiter Price",
            accessor: "show.price",
          },
          {
            Header: "Subscriber",
            accessor: "show.recipient_name",
          },
          {
            Header: "Phone Number",
            accessor: "show.recipient_phone",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="subscription-page">
      <TableFilter
        title={"Subscriptions"}
        searchPlaceholder={"Order ID or Phone Number"}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterFoodPackageOrdersDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
      >
        <Button.Secondary
          title={downloading ? <Beat color={"#CE2600"} /> : "Download CSV"}
          type="button"
          onClick={handleDownload}
        />
      </TableFilter>
      <div className="border-b border-[#E4E4F3]"></div>
      <div className="bg-white dark:bg-[#121212] rounded-md py-4 px-3 sm:px-8">
        {filtering || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        ) : (
          <div className="py-36 text-center">
            <div className="flex justify-center">
              <img src={EmptyState} className="w-auto" alt="no data" />
            </div>
            <h3 className="mt-5 text-xl font-bold">Food Packages</h3>
            <p className="mt-2 text-sm text-[#9CA3AF]">
              You have not added any food package yet.
            </p>
          </div>
        )}
      </div>

      <Pagination data={allPackageOrders} />
    </div>
  );
}

import React, { useMemo, useEffect, useState, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Table from "../../../../components/tables/tableCols1";
import TableSkeleton from "../../../../components/skeleton/table";
import EmptyState from "../../../../assets/images/emptyState/orders.svg";
import { ReactComponent as COPY } from "../../../../assets/images/icons/copy1.svg";
import { PaginationNoRoute } from "../../../../components/pagination/paginationNoRoute";
import { filterStoreOrders } from "../../../../redux/storeOrders/storeOrderAction";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Store() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id} = useParams();
  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [payLoad] = useState({
    end_date: new Date(),
    user_id: id,
  });

  const { allStoreOrders, filtering } = useSelector(
    (state) => state.storeOrder
  );

  useEffect(() => {
    dispatch(filterStoreOrders(payLoad, page));
    // eslint-disable-next-line
  }, [page]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order) => {
        let show = {
          id: (
            <span className="flex items-center">
              {order?.id}{" "}
              <button
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(order?.id);
                }}
              >
                <COPY className="w-[14px] h-[14px] ml-1" />
              </button>
            </span>
          ),
          createdAt: moment(order?.created_at).format("MMM DD, YYYY"),
          amount: <span>NGN {numberWithCommas(order?.order_amount)}</span>,
          payment_type: order?.order_payment_method ?? "Not available",
          status: order?.status,
          orderData: order,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }
  useLayoutEffect(() => {
    const dataSet = createTableData(allStoreOrders?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStoreOrders]);

  function goToSinglePage(params) {
    let id = params?.show?.orderData?.id;
    navigate(`/admin/report/restaurant-order/${id}`, {
      state: { food: params?.show?.orderData },
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "Order Status",
            accessor: "show.status",
          },
          {
            Header: "Date",
            accessor: "show.createdAt",
          },
          {
            Header: "Payment Type",
            accessor: "show.payment_type",
          },
          {
            Header: "Order Id",
            accessor: "show.id",
          },
          {
            Header: "Amount",
            accessor: "show.amount",
          },
        ],
      },
    ],
    []
  );
  return (
    <div>
      <div className="border-b border-[#E4E4F3] sm:px-4 flex justify-between items-center">
        <h3 className="text-md py-2 font-medium">Recent Orders</h3>
      </div>
      <div className="bg-white dark:bg-[#121212] rounded-md pb-4">
        {filtering ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <>
            <Table
              columns={columns}
              data={data?.length > 0 ? data : []}
              onClick={goToSinglePage}
              className="w-full"
              rowClass="hover:shadow-md cursor-pointer"
            />
            <PaginationNoRoute
              data={allStoreOrders}
              page={page}
              setPage={setPage}
            />
          </>
        ) : (
          <div className="py-12 text-center">
            <div className="flex justify-center">
              <img src={EmptyState} className="w-auto" alt="no data" />
            </div>
            <h3 className="mt-5 text-xl font-bold">Orders</h3>
            <p className="mt-2 text-sm text-[#9CA3AF]">
              You do not have any orders yet.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

import React from "react";
import { useTable, useRowSelect } from "react-table";
import Button from "../../assets/images/icons/chevron-right.svg";

export default function Table({ columns, data, onClick, rowClass }) {
  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable(
    {
      columns,
      data,
    },
    useRowSelect
  );

  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <div data-test="table-component">
      <table className="w-full hidden sm:inline-table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return column.hideHeader === false ? null : (
                  <th
                    className=" text-sm px-8 py-4 text-left border-b border-[#E4E4F3] dark:border-none"
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);

            return (
              <tr
                key={i}
                className={`border-b border-[#E4E4F3] dark:border-none last:border-0 + ${rowClass}`}
                onClick={() => onClick(row?.original)}
                {...row.getRowProps()}
              >
                {row.cells.map((cell, i) => {
                  return (
                    <td
                      key={i}
                      className="text-[#151515] dark:text-[#BCBCBC] text-sm px-8 py-4"
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div
        className="grid grid-cols-1 gap-4 block sm:hidden"
        {...getTableBodyProps()}
      >
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <div
              key={i}
              className="flex gap-3 items-center justify-center w-full border-b border-[#E4E4F3] dark:border-none last:border-0"
            >
              <div
                className={`py-3 w-full last:border-0 + ${rowClass}`}
                onClick={() => onClick(row?.original)}
                {...row.getRowProps()}
              >
                {row.cells.slice(0, 3).map((cell, index) => {
                  return (
                    <div
                      key={index}
                      className="text-[#151515] dark:text-[#BCBCBC] text-sm px-2 py-1"
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </div>
                  );
                })}
              </div>
              <img
                src={Button}
                alt="left"
                className="w-[20px] h-[20px]"
                onClick={() => onClick(row?.original)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import Table from "../../../../components/tables/tableCols2";
import TableSkeleton from "../../../../components/skeleton/table";
import TableFilter from "../../../../components/tables/tableFilter";
import Empty from "../../../../assets/images/icons/emptyCart.svg";
import COPY from "../../../../assets/images/icons/copy-outline.svg";
import { concatId } from "../../../../components/helpers";
import { Pagination } from "../../../../components/pagination/pagination";
import { AllFiltersModal } from "../../../../components/modals/allFiltersModal";
import FilterCartDropDown from "../../../../components/dropDown/filter/CartFilter";
import { copyTextFromTable } from "../../../../components/helpers";
import { getAllCarts, searchCarts } from "./../../../../redux/cart/cartAction";
import cartType from "./../../../../redux/cart/cartTypes";

export default function Carts() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { page } = useParams();

  const { allCarts, loading, searching } = useSelector((state) => state.cart);

  useEffect(() => {
    dispatch(getAllCarts(page));
  }, [dispatch, page]);

  const [isFilterOpen, setFilterState] = useState(false);
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });
  // const copyTextToClipboard = async (text) => {
  //   if ("clipboard" in navigator) {
  //     await navigator.clipboard.writeText(text);
  //   } else {
  //     document.execCommand("copy", true, text);
  //   }
  //   toast.success("Copied", {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });
  // };

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap?.map((cart, index) => {
        let single = {
          id: (
            <span className="flex">
              {concatId(cart?.user_id)}
              <img
                className="ml-3 cursor-pointer"
                src={COPY}
                alt="copy icon"
                onClick={(e) => {
                  copyTextFromTable(e, cart?.user_id);
                }}
              />
            </span>
          ),
          products: cart?.products ? cart?.products?.length : 0,
          packages: cart?.packages ? cart?.packages?.length : 0,
          date: moment(cart?.updated_at).format("MMM DD, YYYY/HH:mma"),
          cart: cart,
        };
        outputArray.push({ single });
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allCarts?.data);
    setData(dataSet);
    // eslint-disable-next-line
  }, [allCarts]);

  const goToSinglePage = (params) => {
    let id = params?.single?.cart?.user_id;
    navigate(`/admin/carts/details/${id}`);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allCarts?.data);
      setData(dataSet);
      dispatch({ type: cartType["SEARCH_CARTS"], payLoad: [] });
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchCarts(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "Cart Id",
            accessor: "single.id",
          },
          {
            Header: "No Of Products",
            accessor: "single.products",
          },
          {
            Header: "No Of Packages",
            accessor: "single.packages",
          },
          {
            Header: "Date Updated",
            accessor: "single.date",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="cart-page">
      <TableFilter
        title={"Carts"}
        searchPlaceholder={"Amount & Cart Id"}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterCartDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
            />
          </AllFiltersModal>
        }
      />
      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white dark:bg-[#121212] rounded-md py-4">
        {loading || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        ) : (
          <div className="text-center">
            <div className="flex justify-center my-12 ">
              <img src={Empty} alt="orders" />
            </div>
            <p className="font-bold text-xl my-3">Cart</p>
            <p className="text-sm text-gray-500">
              You do not have any Cart yet
            </p>
          </div>
        )}

        <Pagination data={allCarts} route={`admin/carts`} />
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Components
import Referrals from "./component/referrals";
import AllUsers from "./component/allUsers";

const tabs = [
  { name: "All Users", href: "all" },
  { name: "Referrals", href: "referral" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Users() {
  const navigate = useNavigate();
  const { active_tab } = useParams();
  const DEFAULT_ACTIVE_TAB = "all";

  useEffect(() => {
    if (!active_tab) {
      navigate(`/admin/users/${DEFAULT_ACTIVE_TAB}/1`);
    }
  }, [active_tab, navigate]);

  const toggle = (tab) => {
    if (active_tab !== tab) {
      navigate(`/admin/users/${tab.href}/1`);
    }
  };

  return (
    <div data-test="users-page">
      {/* <div className="mb-10">
        <h1 className="text-3xl font-semibold text-gray-900">Users</h1>
      </div> */}

      <div className="sm:block">
        <div className="border-b border-gray-200 dark:border-none">
          <nav
            className="-mb-px flex space-x-8 overflow-x-auto"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => {
                  toggle(tab);
                }}
                className={classNames(
                  tab.href === active_tab
                    ? "border-primary text-primary font-bold"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab.href === active_tab ? "page" : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>

      {active_tab === "referral" && <Referrals />}
      {active_tab === "all" && <AllUsers />}
    </div>
  );
}

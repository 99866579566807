import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../components/inputs/inputs";
import Button from "../../../../components/buttons/buttons";
import { Beat } from "../../../../plugins/spinners.plugin";
import { history } from "../../../../helpers/history";
import { XIcon } from "@heroicons/react/outline";
import { ReactComponent as Upload } from "../../../../assets/images/icons/upload.svg";
import SearchableDropdown from "../../../../helpers/searchableDropDown";
import SelectableInput from "../../../../components/inputs/SelectableInput";
import { filterProductCategory } from "../../../../redux/productCatergory/productCategoryAction";
import { filterProductTag } from "../../../../redux/productTags/productTagActions";
import { getAllRestaurantsAction } from "../../../../redux/restaurant/restaurantActions";
import { createSubscriptionFoodAction } from "../../../../redux/subscriptionFood/subscriptionFoodActions";

export default function CreatePackageItem() {
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const { allRestaurants } = useSelector((state) => state.restaurant);
  const { allTags } = useSelector((state) => state.productTag);
  const { allCategories } = useSelector((state) => state.productCatergory);
  const { isLoading } = useSelector((state) => state.subscriptionFood);
  const [images, setImages] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [image64, setImage64] = useState([]);
  const [restaurantCategories, setRestaurantCategories] = useState([]);
  const [restaurantTags, setRestaurantTags] = useState([]);

  const [input, setInput] = useState({
    restaurant_id: "",
    food_name: "",
    food_description: "",
    food_amount: "",
    food_preparation_time: "",
    food_categories: [],
    food_tags: [],
  });

  useEffect(() => {
    dispatch(filterProductCategory({ type: "restaurant", pagination: 0 }, 1));
    dispatch(filterProductTag({ type: "restaurant" }, 1));
    dispatch(getAllRestaurantsAction());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const categories = Array.isArray(allCategories)
      ? allCategories?.filter((item) => {
          return item?.type === "restaurant";
        })
      : [];
    setRestaurantCategories(categories);
    // eslint-disable-next-line
  }, [allCategories]);

  useEffect(() => {
    let items = allTags?.data?.filter((item) => item.type === "restaurant");
    setRestaurantTags(items);
  }, [allTags]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  //handling selectable input tags and categories
  const handleToggleTags = (value) => {
    let former_tags = [];
    if (input?.food_tags) {
      former_tags = [...input?.food_tags];
      if (former_tags.includes(value)) {
        let arr = [...former_tags];
        const filtered = arr.filter((item) => item !== value);
        setInput({ ...input, food_tags: filtered });
      } else {
        let new_tags = [...former_tags, value.name];
        setInput({ ...input, food_tags: new_tags });
      }
    } else {
      let new_tags = [...former_tags, value.name];
      setInput({ ...input, food_tags: new_tags });
    }
  };

  const handleToggleCategories = (value) => {
    let former_categories = [];
    if (input?.food_categories) {
      former_categories = [...input?.food_categories];
      if (former_categories.includes(value)) {
        let arr = [...former_categories];
        const filtered = arr.filter((item) => item !== value);
        setInput({ ...input, food_categories: filtered });
      } else {
        let new_categories = [...former_categories, value.name];
        setInput({ ...input, food_categories: new_categories });
      }
    } else {
      let new_categories = [...former_categories, value.name];
      setInput({ ...input, food_categories: new_categories });
    }
  };

  //handling adding images from your browser to images array
  function onImageChange(e) {
    setImages([...e.target.files]);
  }

  const handleFileClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };
  const removeFile = (index) => {
    let newArr = images;
    newArr.splice(index, 1);
    setImages([...newArr]);
  };

  useEffect(() => {
    const newImageUrls = [];
    const newImage64 = [];
    images?.forEach((image) => {
      newImageUrls.push(URL.createObjectURL(image));
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        newImage64.push(reader.result);
      };
    });
    setImageUrls(newImageUrls);
    setImage64(newImage64);
  }, [images]);

  const selectRestaurantOption = (option) => {
    const value = option?.id;
    setInput({ ...input, restaurant_id: value });
  };

  const handleSubmit = async () => {
    try {
      const response = await dispatch(
        createSubscriptionFoodAction({
          ...input,
          base64_photos: image64 ? image64 : [],
        })
      );
      if (response?.status === "success") {
        history("/admin/food-packages/items/1");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div data-test="createItem-page">
      <div className="border border-[#E4E4F3] dark:border-none bg-white dark:bg-[#121212] rounded-md py-8 px-10 max-w-3xl mx-auto mt-24">
        <div className="border-b border-[#E4E4F3] py-3 text-center">
          <h3 className="text-lg font-medium">Food Package Items</h3>
        </div>

        <div className="mt-6">
          <label
            htmlFor="restaurant_id"
            className="text-sm font-medium text-[#151515] dark:text-white"
          >
            Restuarant
          </label>
          <SearchableDropdown
            options={allRestaurants?.data}
            label="restaurant_name"
            name={"restaurant_id"}
            placeholder={"Select a restaurant"}
            selectOption={selectRestaurantOption}
          />
          <Input.Label
            title={"Item Name"}
            type="text"
            name="food_name"
            id="food_name"
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            placeholder="Enter Item name"
            onChange={handleChange}
          />
          <Input.Select
            title={"Format"}
            name="format"
            id="format"
            htmlFor={"format"}
            className={"border border-[#C2C9D1] w-full"}
            onChange={handleChange}
          >
            <option>Select format</option>
            <option value={"physical"}>Physical</option>
            <option value={"digital"}>Digital</option>
          </Input.Select>
          <Input.TextArea
            title={"Item Description"}
            type="text"
            name="food_description"
            id="food_description"
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            placeholder="Enter Item description"
            onChange={handleChange}
          />
          <Input.Number
            title={"Amount"}
            type="number"
            name="food_amount"
            id="food_amount"
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            placeholder="Price including shipping markup"
            onChange={handleChange}
          />

          <div className="mt-4">
            <label
              htmlFor="Food Category*"
              className="text-sm font-medium text-[#151515] dark:text-white mb-2 block"
            >
              Food Categories
            </label>
            <SelectableInput
              options={restaurantCategories}
              label="food_categories"
              name={"+ Add Category"}
              type="category"
              selectOption={handleToggleCategories}
            />
          </div>

          <div className="my-4">
            <label
              htmlFor="food_tags"
              className="text-sm font-medium text-[#151515] dark:text-white mb-2 block"
            >
              Food Tags
            </label>
            <SelectableInput
              options={restaurantTags}
              label="food_tags"
              name={"+ Add Tag"}
              type="tag"
              selectOption={handleToggleTags}
            />
          </div>
          <Input.TimePicker
            title={"Food Preparation Time"}
            htmlFor={"food_preparation_time"}
            name="food_preparation_time"
            id="food_preparation_time"
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            onChange={handleChange}
          />
          <div className="mt-2">
            <label
              htmlFor="base64_photos"
              className="block text-sm mt-4 font-medium text-gray-700 dark:text-white"
            >
              Food Image
            </label>
            {imageUrls?.length === 0 ? (
              <div className="bg-[#FFFBFA] dark:bg-[#121212] text-center border border-dashed border-[#E4E4F3] rounded-[10px] mt-4 p-5 h-[130px]">
                <button
                  className="text-primary mx-auto"
                  onClick={handleFileClick}
                >
                  <Upload className="h-[56px] w-[56px]" />
                </button>
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  multiple
                  onChange={onImageChange}
                  ref={hiddenFileInput}
                />
                <p className="text-[#676565] text-lg text-center mt-1">
                  Click here to select a photo
                </p>
              </div>
            ) : (
              <div className="grid grid-cols-1  gap-8 mt-8">
                {imageUrls?.map((image, index) => (
                  <figure className="w-full h-[160px] relative" key={index}>
                    <img
                      src={image}
                      className="object-cover w-full h-full"
                      alt="food"
                    />
                    <button
                      type="button"
                      className="h-8 w-8 bg-[#FAE9E6] rounded-full absolute right-2 top-2"
                      onClick={() => removeFile(index)}
                    >
                      <XIcon className="h-5 w-5 text-primary mx-auto" />
                    </button>
                  </figure>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="py-8 px-10 max-w-3xl mx-auto text-center mt-6">
        <Button.Primary
          disabled={isLoading}
          title={
            isLoading ? <Beat color={"#ffffff"} /> : "Create Food Package Item"
          }
          className="px-4"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
